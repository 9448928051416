import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Popover, PopoverBody } from 'reactstrap'
import { lang, permissions } from '../../themeConfig'
import Criteria from '../Criteria'
import KeywordSearchInput from '../KeywordSearchInput'
import Responsive from 'react-responsive'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'

// import SearchCriteriaAccordion from "./SearchCriteriaAccordion";
import DefaultClearButton from '../Criteria/DefaultClearButton'

import { hasBuyNowAccess, hasTradeAccess, isUserLoggedIn } from '../../utilities/Functions'

import { SearchFields } from '../../constants/Search'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'

const override = css`
    display: block;
    margin: 0 auto;
`

// This builds up components for the search bar on the left.
class Search extends Component {
    constructor(props) {
        super(props)
        this.handleOnOpenCriteria = this.handleOnOpenCriteria.bind(this)
        this.state = {
            saveSearchModalOpen: false,
            searchTitle: '',
            accordionOpen: false,
            savedSearches: [],
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.criteria.buyNow !== this.props.criteria.buyNow ||
            nextProps.criteria.eXchangeOnly !== this.props.criteria.eXchangeOnly
        ) {
            this.props.getVehicles(nextProps.criteria, this.props.sorting)
            this.props.getSites(nextProps.criteria)
            this.props.getMakes(nextProps.criteria)
            this.props.getSales(nextProps.criteria)
            this.props.getSaleDays(nextProps.criteria)
            this.props.getEngineSizes(nextProps.criteria)
            this.props.getTransmissions(nextProps.criteria)
            this.props.getDoors(nextProps.criteria)
            this.props.getAges(nextProps.criteria)
            this.props.getMileages(nextProps.criteria)
            this.props.getBodyTypes(nextProps.criteria)
            this.props.getVehicleTypes(nextProps.criteria)
            this.props.getFuelTypes(nextProps.criteria)
            this.props.getSellers(nextProps.criteria)
            this.props.getNama(nextProps.criteria)
            this.props.getWriteOff(nextProps.criteria)
            this.props.getNonRunner(nextProps.criteria)
            this.props.getEuroStatus(nextProps.criteria)
        }
        if (nextProps.user.savedSearches !== this.props.user.savedSearches) {
            this.setState({ savedSearches: nextProps.user.savedSearches })
        }
    }

    handleOnOpenCriteria(popover) {
        if (popover === 'saved-search-popover') {
            this.props.fetchSavedSearches()
        }
        this.setState({
            criteriaOpen: popover,
        })
    }

    handleOnCloseCriteria = () => {
        this.setState({
            criteriaOpen: '',
        })
    }

    resetSearch = () => {
        this.handleOnCloseCriteria()
        this.props.resetSearch(this.props.pagination.resultsPerPage.value)
    }

    render = () => {
        const { onClickBuyNowOnly, onClickExchangeOnly } = this.props
        let classes = false // ! What is going on here?
            ? 'btn btn-block ab-results-filter-with-selection position-relative'
            : 'btn btn-block position-relative'
        return (
            <React.Fragment>
                <div className="row mx-0 my-0">
                    <div className="col p-0 px-1 ab-results-search">
                        <KeywordSearchInput offsetFinish={this.props.pagination.resultsPerPage.value} />
                    </div>
                </div>
                {isUserLoggedIn(this.props.user) &&
                    permissions('CATALOGUE_LOAD_SAVED_SEARCH_ENABLED', this.props.user.theme) && (
                        <Responsive {...this.props} minWidth={768}>
                            <div className="row mx-0 my-0 ab-results-left">
                                <div className="col-12 text-right p-md-1 ab-results-filter-lg">
                                    <Button
                                        id="saved-search-popover"
                                        className={classes}
                                        onClick={() => {
                                            this.state.criteriaOpen === 'saved-search-popover'
                                                ? this.handleOnCloseCriteria()
                                                : this.handleOnOpenCriteria('saved-search-popover')
                                        }}
                                        data-html="true"
                                        disabled={false}
                                    >
                                        <p className="p-0 m-0">
                                            {lang('CATALOGUE_LOAD_SAVED_SEARCH_BUTTON_TEXT', this.props.user.theme)}
                                        </p>
                                        <FaChevronRight className="position-absolute" size="1.5em" />
                                    </Button>
                                </div>
                            </div>
                            <Popover
                                placement="right"
                                offset={[10, 10]}
                                isOpen={this.state.criteriaOpen === 'saved-search-popover'}
                                target="saved-search-popover"
                                toggle={() =>
                                    this.state.criteriaOpen === 'saved-search-popover'
                                        ? this.handleOnCloseCriteria('saved-search-popover')
                                        : this.handleOnOpenCriteria('saved-search-popover')
                                }
                                fade={false}
                            >
                                <PopoverBody className="card ab-filter-results-card">
                                    <div className="card-header">
                                        <div className="clearfix">
                                            <h3 className="float-left">
                                                {lang('CATALOGUE_LOAD_SAVED_SEARCH_BUTTON_TEXT', this.props.user.theme)}
                                            </h3>
                                            <Button
                                                type="button"
                                                className="close float-right"
                                                aria-label={lang('BUTTON_CLOSE', this.props.user.theme)}
                                                onClick={() => this.handleOnCloseCriteria('saved-search-popover')}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {this.props.user.loading ? (
                                            <div className="row mx-0">
                                                <div className="col px-2">
                                                    <div className="loading-wrapper">
                                                        <ScaleLoader
                                                            className={override}
                                                            size={10}
                                                            loading={this.props.user.loading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row">
                                                {this.state.savedSearches.length > 0 ? (
                                                    <React.Fragment>
                                                        {this.state.savedSearches.map((item, i) => {
                                                            return (
                                                                <div className="col-xs-6 col-md-3 pb-3" key={i}>
                                                                    <div className="custom-checkbox">
                                                                        <div className="label">
                                                                            <Link
                                                                                to="/vehicle-search?savedsearch=true"
                                                                                className="no-link"
                                                                                onClick={() => {
                                                                                    this.handleOnCloseCriteria(
                                                                                        'saved-search-popover',
                                                                                    )
                                                                                    this.props.handleOnLoadSearch(
                                                                                        item.search,
                                                                                        this.props.pagination
                                                                                            .resultsPerPage.value,
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {item.description}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="col-12 text-center">
                                                        {lang('NO_SAVED_SEARCHES', this.props.user.theme)}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </PopoverBody>
                            </Popover>
                        </Responsive>
                    )}
                <div className="row mx-0 my-0">
                    <div className="col-12 text-right p-md-1">
                        <DefaultClearButton
                            className="ab-reset-filters-lg link"
                            onClick={this.resetSearch}
                            theme={this.props.user.theme}
                        />
                    </div>
                </div>
                <Responsive {...this.props} minWidth={768}>
                    {/* <!-- AB DESKTOP FILTERS --> */}
                    <div className="row mx-0 mx-md-1 d-none d-md-block">
                        <div className="col m-0 p-0 ab-results-filter-lg">
                            {hasBuyNowAccess(this.props.user) &&
                                permissions('CATALOGUE_BUY_NOW_CHECKBOX_ENABLED', this.props.user.theme) && (
                                    <div className="row mx-0 my-14 mx-md-1 e-hub-orange">
                                        <div className="buy-now-checkbox custom-control">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="buyNowOnlyCheckbox"
                                                checked={this.props.criteria.buyNow}
                                                onChange={() => {
                                                    onClickExchangeOnly(
                                                        false,
                                                        this.props.pagination.resultsPerPage.value,
                                                    )
                                                    onClickBuyNowOnly(
                                                        !this.props.criteria.buyNow,
                                                        this.props.pagination.resultsPerPage.value,
                                                    )
                                                }}
                                                defaultValue={this.props.criteria.buyNow}
                                            />
                                            <label className="custom-control-label" htmlFor="buyNowOnlyCheckbox">
                                                {lang('CATALOGUE_CHECKBOX_BUY_NOW_ONLY', this.props.user.theme)}
                                            </label>
                                        </div>
                                        {this.props.user.theme === 'default' && (
                                            <img
                                                src="/img/buy-it-now-icon.png"
                                                alt="Buy it now"
                                                className="filter-icon"
                                            />
                                        )}
                                    </div>
                                )}
                            {permissions('CATALOGUE_EXCHANGE_CHECKBOX_ENABLED', this.props.user.theme) && (
                                <div className="row mx-0 my-3 mx-md-1 e-hub-orange">
                                    <div className="e-xchange-checkbox custom-control">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="eXchangeOnlyCheckbox"
                                            checked={this.props.criteria.eXchangeOnly}
                                            onChange={() => {
                                                onClickBuyNowOnly(false, this.props.pagination.resultsPerPage.value)
                                                onClickExchangeOnly(
                                                    !this.props.criteria.eXchangeOnly,
                                                    this.props.pagination.resultsPerPage.value,
                                                )
                                            }}
                                            defaultValue={this.props.criteria.eXchangeOnly}
                                        />
                                        <label className="custom-control-label" htmlFor="eXchangeOnlyCheckbox">
                                            {lang('CATALOGUE_CHECKBOX_EXCHANGE_ONLY', this.props.user.theme)}
                                        </label>
                                    </div>
                                    {this.props.user.theme === 'default' && (
                                        <img src="/img/e-xchange-icon.png" alt="e-Xchange" className="filter-icon" />
                                    )}
                                </div>
                            )}
                            {this.getCriteria(this.props.user.theme)}
                        </div>
                    </div>

                    {isUserLoggedIn(this.props.user) && permissions('CATALOGUE_IS_SAVE_SEARCH_ENABLED') && (
                        <div className="row mx-0 my-3 mx-md-1">
                            <div className="col px-0 ab-save-filters">
                                <button
                                    className="btn btn-primary btn-block ab-btn-padding"
                                    type="button"
                                    onClick={() => this.props.onOpenSaveSearchModal()}
                                >
                                    {lang('CATALOGUE_SAVE_SEARCH_BUTTON_TEXT', this.props.user.theme)}
                                </button>
                            </div>
                        </div>
                    )}
                </Responsive>
                <Responsive {...this.props} maxWidth={769}>
                    {/* <!-- AB MOBILE FILTERS --> */}
                    <div className="row mx-0 my-3 d-flex d-md-none">
                        <div className="col-12 px-0 mb-1">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding-sm"
                                type="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={() => this.setState({ accordionOpen: !this.state.accordionOpen })}
                            >
                                {lang('CATALOGUE_FILTER_RESULTS_BUTTON_TEXT', this.props.user.theme)}
                            </button>
                        </div>
                        <div className="w-100 d-block" />
                        <div
                            className={`col-12 mx-0 px-0 ${this.state.accordionOpen ? '' : 'collapse'}`}
                            id="collapseFilters"
                        >
                            <div className="accordion" id="accordionExample">
                                {hasBuyNowAccess(this.props.user) &&
                                    permissions('CATALOGUE_BUY_NOW_CHECKBOX_ENABLED', this.props.user.theme) && (
                                        <div className="py-2 px-0 e-hub-orange position-relative">
                                            <div className="buy-now-checkbox custom-control">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="buyNowOnlyCheckbox"
                                                    checked={this.props.criteria.buyNow}
                                                    onChange={() => {
                                                        onClickExchangeOnly(
                                                            false,
                                                            this.props.pagination.resultsPerPage.value,
                                                        )
                                                        onClickBuyNowOnly(
                                                            !this.props.criteria.buyNow,
                                                            this.props.pagination.resultsPerPage.value,
                                                        )
                                                    }}
                                                    defaultValue={this.props.criteria.buyNow}
                                                />
                                                <label className="custom-control-label" htmlFor="buyNowOnlyCheckbox">
                                                    {lang('CATALOGUE_CHECKBOX_BUY_NOW_ONLY', this.props.user.theme)}
                                                </label>
                                            </div>
                                            {this.props.user.theme === 'default' && (
                                                <img
                                                    src="/img/buy-it-now-icon.png"
                                                    alt="Buy it now"
                                                    className="filter-icon"
                                                />
                                            )}
                                        </div>
                                    )}
                                {permissions('CATALOGUE_EXCHANGE_CHECKBOX_ENABLED', this.props.user.theme) && (
                                    <div className="py-2 px-0 e-hub-orange position-relative">
                                        <div className="e-xchange-checkbox custom-control">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="eXchangeOnlyCheckbox"
                                                checked={this.props.criteria.eXchangeOnly}
                                                onChange={() => {
                                                    onClickBuyNowOnly(false, this.props.pagination.resultsPerPage.value)
                                                    onClickExchangeOnly(
                                                        !this.props.criteria.eXchangeOnly,
                                                        this.props.pagination.resultsPerPage.value,
                                                    )
                                                }}
                                                defaultValue={this.props.criteria.eXchangeOnly}
                                            />
                                            <label className="custom-control-label" htmlFor="eXchangeOnlyCheckbox">
                                                {lang('CATALOGUE_CHECKBOX_EXCHANGE_ONLY', this.props.user.theme)}
                                            </label>
                                        </div>

                                        {this.props.user.theme === 'default' && (
                                            <img
                                                src="/img/e-xchange-icon.png"
                                                alt="e-Xchange"
                                                className="filter-icon"
                                            />
                                        )}
                                    </div>
                                )}

                                {this.getCriteria(this.props.user.theme, 'accordion')}
                                {isUserLoggedIn(this.props.user) &&
                                    permissions('CATALOGUE_IS_SAVE_SEARCH_ENABLED', this.props.user.theme) && (
                                        <div className="col px-0 ab-save-filters">
                                            <button
                                                className="btn btn-primary btn-block ab-btn-padding"
                                                type="button"
                                                onClick={() => this.props.onOpenSaveSearchModal()}
                                            >
                                                {lang('CATALOGUE_SAVE_SEARCH_BUTTON_TEXT', this.props.user.theme)}
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </Responsive>
            </React.Fragment>
        )
    }

    getCriteria(theme, criteriaType = null) {
        return (
            <>
                {SearchFields(theme).map((item, i) => {
                    if (
                        item.auth &&
                        permissions(`CATALOGUE_FILTER_${item.dataType.toUpperCase()}`, this.props.user.theme)
                    ) {
                        if (hasTradeAccess(this.props.user)) {
                            return (
                                <Criteria
                                    key={i}
                                    dataType={item.dataType}
                                    defaultText={item.defaultText}
                                    type={item.type}
                                    hasTabs={item.hasTabs}
                                    dependantDataType={item.dependantDataType}
                                    criteriaOpen={this.state.criteriaOpen}
                                    onOpenCriteria={this.handleOnOpenCriteria}
                                    onCloseCriteria={this.handleOnCloseCriteria}
                                    criteriaType={criteriaType}
                                />
                            )
                        } else {
                            return null
                        }
                    }

                    return (
                        <Criteria
                            key={i}
                            dataType={item.dataType}
                            jsonType={item.jsonType}
                            defaultText={item.defaultText}
                            type={item.type}
                            hasTabs={item.hasTabs}
                            dependantDataType={item.dependantDataType}
                            criteriaOpen={this.state.criteriaOpen}
                            onOpenCriteria={this.handleOnOpenCriteria}
                            onCloseCriteria={this.handleOnCloseCriteria}
                            criteriaType={criteriaType}
                            OnlyVisibleWhenCriteriaSet={item.OnlyVisibleWhenCriteriaSet}
                        />
                    )
                })}
            </>
        )
    }
}

export default Search
