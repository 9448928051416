import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import AuctionCountdown from '../../UiKit/AuctionCountdown'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { eXchangeBuyNow, updateBiddingDetails } from '../../actions/vehicles'
import {
    getBiddingStatusColour,
    getDivisorText,
    getExchangeBtnText,
    getPriceText,
    getReserveStatus,
    showRemaining,
} from '../../utilities/AuctionFunctions'

import { CURRENCY } from '../../constants'

import { onClickBiddingModal, onCloseBiddingModal } from '../../actions/modals'

import confirm from '../../utilities/Confirm'
import AuctionExtend from '../../utilities/AuctionExtend'

import { lang, permissions } from '../../themeConfig'

const override = css`
    display: block;
    margin: 0 auto;
`

const MAX_BID_LIMIT = 999999
const MAX_BID_THRESHOLD = MAX_BID_LIMIT - 24

function contactMessage(vehicle) {
    let message =
        'Please contact us to arrange payment and to book delivery within 48 hours of the sale ending if you have won.'
    if (vehicle.listOnly) {
        message = `Please contact ${vehicle.seller || 'the seller'} directly to complete your purchase within 48 hours of the sale ending.`
    } else if (vehicle.listAndTransact) {
        message = (
            <>
                Please contact the <strong>Aston Barclay Digital team</strong> on <strong>07384 467110</strong> to book
                transport and arrange payment within 48 hours of the sale ending.
            </>
        )
    }

    return (
        <>
            <p>{message}</p>
        </>
    )
}

class BiddingModal extends Component {
    constructor(props) {
        super(props)
        this.onChangeBuyNow = this.onChangeBuyNow.bind(this)
        this.isInValid = this.isInValid.bind(this)
        this.placeBid = this.placeBid.bind(this)
        this.getAuctionEndedSection = this.getAuctionEndedSection.bind(this)
        this.state = {
            vehicle: {},
            valid: true,
            defaultBidValue: '',
        }
    }

    async UNSAFE_componentWillMount() {}

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.biddingModal.isOpen) {
            const vehicleArray = nextProps.eXchangeList ? nextProps.eXchangeVehicles : nextProps.vehicles
            const vehicle = vehicleArray.filter((vehicle) => vehicle.id === nextProps.biddingModal.id)[0]
            await this.setState({ vehicle, defaultBidValue: '', valid: false })
        }
    }

    commitToBuyNow(vehicle) {
        confirm(lang('ARE_YOU_SURE'), {
            buttonConfirm: lang('YES', this.props.theme),
            buttonDismiss: lang('NO', this.props.theme),
            description: `${lang('BUY_CONFIRM_DESCRIPTION', this.props.theme)} ${vehicle.biddingDetails.buyNowPrice ? `${CURRENCY}${vehicle.biddingDetails.buyNowPrice.toLocaleString()}` : ''}`,
            cardType: vehicle.cardType,
        }).then(
            () => {
                window.dataLayer && window.dataLayer.push({
                    'event': 'Buy now - e-Xchange Vehicle',
                })
                this.props.eXchangeBuyNow({ id: vehicle.id })
                return true
            },
            () => {
                return false
            },
        )
    }

    keyAllowed(key) {
        const keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
        return !(key && keys.indexOf(key) === -1)
    }

    handleNegative(e) {
        var key = !isNaN(e.charCode) ? e.charCode : e.keyCode
        if (!this.keyAllowed(key)) e.preventDefault()
    }

    onChangeBuyNow(value) {
        const amount = value
        const amountAsNumber = Number.parseInt(amount)
        const isValid =
            amountAsNumber > this.state.vehicle.biddingDetails.currentBid &&
            amountAsNumber % this.state.vehicle.divisor === 0
        this.setState({ defaultBidValue: amount, valid: isValid })
    }

    getPrice(vehicle) {
        if (vehicle.leaseplanVehicle) {
            return !vehicle.biddingDetails.userMaxBid && !vehicle.biddingDetails.currentBid
                ? vehicle.biddingDetails.startingPrice
                : vehicle.biddingDetails.userMaxBid > vehicle.biddingDetails.currentBid
                  ? vehicle.biddingDetails.userMaxBid + this.state.vehicle.divisor
                  : vehicle.biddingDetails.currentBid + this.state.vehicle.divisor
        }

        return vehicle.biddingDetails.noOfBids === 0
            ? vehicle.biddingDetails.startingPrice
            : vehicle.biddingDetails.userMaxBid > vehicle.biddingDetails.currentBid
              ? vehicle.biddingDetails.userMaxBid + this.state.vehicle.divisor
              : vehicle.biddingDetails.currentBid + this.state.vehicle.divisor
    }

    isInValid(vehicle) {
        const invalid =
            !this.props.websocketConnected ||
            vehicle.biddingDetails.ended ||
            this.state.defaultBidValue === '' ||
            this.state.defaultBidValue < this.getPrice(vehicle) ||
            this.state.defaultBidValue >= MAX_BID_LIMIT ||
            (vehicle.leaseplanVehicle === true ? this.state.defaultBidValue % vehicle.divisor > 0 : false)

        return invalid
    }

    placeBid(id) {
        window.dataLayer && window.dataLayer.push({
            'event': 'Place e-Xchange Bid',
        })
        this.setState({ defaultBidValue: '' })
        this.props.updateBiddingDetails({
            id: id,
            userMaxBid: this.state.defaultBidValue,
        })
    }

    getAuctionEndedSection(vehicle) {
        let firstMessage
        let smallPrint
        let transportText = <></>
        if (vehicle.biddingDetails.userWinning && vehicle.biddingDetails.reserveMet) {
            firstMessage = "You've won this vehicle"
            smallPrint = contactMessage(vehicle)
            if (permissions('CATALOGUE_BIDDING_VF_TRANSPORT_TEXT')) {
                transportText = (
                    <p>
                        For a delivery quote, or to arrange delivery, please contact{' '}
                        <a href="mailto:wakefield.transport@astonbarclay.net">wakefield.transport@astonbarclay.net</a>
                        <br />
                        or call 01924 927628 – and ask for Transport
                    </p>
                )
            }
        } else if (vehicle.biddingDetails.userWinning) {
            firstMessage = 'You were the highest bidder, but reserve was not met'
            smallPrint = 'Please await final outcome to be confirmed'
        } else {
            firstMessage = 'Sorry, you lost this vehicle'
        }

        let winningBid = null
        if (vehicle.biddingDetails.reserveMet) {
            winningBid = <p>Winning bid: £{vehicle.biddingDetails.currentBid.toLocaleString()}</p>
        }

        let bidStatus = null
        if (vehicle.biddingDetails.userMaxBid) {
            bidStatus = <p>Your bid status: {getBiddingStatusColour(vehicle, this.props.theme) || '-'}</p>
        }

        let maxBid = null
        if (vehicle.biddingDetails.userWinning && vehicle.biddingDetails.userMaxBid) {
            maxBid = <p>Your max bid: £{vehicle.biddingDetails.userMaxBid.toLocaleString()}</p>
        }

        return (
            <div className="row m-0 p-0">
                <div className="col-12 p-0 d-flex flex-column">
                    <h3>Auction ended</h3>
                    <p>{firstMessage}</p>
                    {winningBid}
                    {bidStatus}
                    {maxBid}
                    {smallPrint}
                    {transportText}
                </div>
            </div>
        )
    }

    render() {
        const { vehicle } = this.state
        const modalTitle =
            vehicle && vehicle.leaseplanVehicle === true
                ? lang('EXCHANGE_PLACE_BID', this.props.theme)
                : lang('EXCHANGE_BUY_NOW_PLACE_BID', this.props.theme)

        return (
            <Modal
                isOpen={this.props.biddingModal.isOpen}
                toggle={() => {
                    if (this.props.biddingModal.isOpen) {
                        return this.props.onCloseBiddingModal(!this.props.biddingModal.isOpen)
                    }
                }}
                fade={false}
                className="modal-dialog-centered modal-lg ab-proxy-bid-modal e-xchange"
            >
                <ModalHeader
                    className="includes-timer"
                    toggle={() => {
                        if (this.props.biddingModal.isOpen) {
                            return this.props.onCloseBiddingModal(!this.props.biddingModal.isOpen)
                        }
                    }}
                >
                    {modalTitle} - {vehicle.registration}{' '}
                    {Object.keys(vehicle).length > 0 && this.props.websocketConnected && (
                        <small>
                            {!vehicle.biddingDetails.ended ? (
                                <AuctionCountdown
                                    date={
                                        Date.now() + showRemaining(vehicle.biddingDetails.endDate.replace('+0000', 'Z'))
                                    }
                                    vehicleId={vehicle.id}
                                />
                            ) : (
                                lang('AUCTION_ENDED_TEXT', this.props.theme)
                            )}
                        </small>
                    )}
                </ModalHeader>
                {Object.keys(vehicle).length > 0 ? (
                    <ModalBody>
                        {vehicle.leaseplanVehicle && (
                            <AuctionExtend type={'bidding'} endDate={vehicle.biddingDetails.endDate} id={vehicle.id} />
                        )}
                        {!vehicle.biddingDetails.ended && showRemaining(vehicle.biddingDetails.endDate) < 3600000 && (
                            <div className="col-6  m-0 p-0 p-md-2 ml-auto">
                                <div style={{ width: '95px' }} className="ab-ends-soon float-right">
                                    {lang('ENDS_SOON', this.props.theme)}
                                </div>
                            </div>
                        )}

                        {!vehicle.biddingDetails.ended ? (
                            <React.Fragment>
                                <div className="row m-0 p-0">
                                    <div
                                        className={`col-12 m-0 p-0 p-md-2  ${vehicle.leaseplanVehicle ? 'lp-charge-highlight' : ''}`}
                                    >
                                        {!vehicle.leaseplanVehicle ? (
                                            <p>
                                                {lang('EXCHANGE_BUY_NOW_PLACE_BID_SHORT_DESCRIPTION', this.props.theme)}
                                            </p>
                                        ) : (
                                            <span>
                                                <strong>Sold by CarNext</strong> - subject to additional £100 admin fee
                                                (inc VAT)
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    {vehicle.biddingDetails.buyNowPrice && !vehicle.biddingDetails.reserveMet ? (
                                        <div className="col-12 col-md-6 py-3 d-flex flex-column b-r-d">
                                            <h3>{lang('BUY_NOW_TITLE', this.props.theme)}</h3>
                                            <p>
                                                {lang('LABEL_BUY_NOW_PRICE', this.props.theme)} {CURRENCY}
                                                {vehicle.biddingDetails.buyNowPrice.toLocaleString()}{' '}
                                                {lang('EXCLUDING_BUYERS_FEE_TEXT', this.props.theme)}
                                            </p>
                                            {contactMessage(vehicle)}
                                            <p style={{ flexGrow: 1 }}>
                                                <strong>
                                                    {lang('LABEL_BUY_NOW_PRICE', this.props.theme)} {CURRENCY}
                                                    {vehicle.biddingDetails.buyNowPrice.toLocaleString()}
                                                </strong>
                                            </p>
                                            <button
                                                className="btn btn-block ab-btn-padding btn-primary"
                                                onClick={() => this.commitToBuyNow(vehicle)}
                                            >
                                                {lang('BUTTON_COMMIT_TO_BUY_NOW', this.props.theme)}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-md-6 py-3 d-flex flex-column b-r-d">
                                            <h3>{lang('NO_BUY_NOW_PRICE', this.props.theme)}</h3>
                                            {contactMessage(vehicle)}
                                        </div>
                                    )}
                                    <div className={`col-12 col-md-6 py-3 d-flex flex-column`}>
                                        {!vehicle.leaseplanVehicle && (
                                            <React.Fragment>
                                                <h3>{lang('PLACE_BID_TEXT', this.props.theme)}</h3>
                                                <p>
                                                    {lang('ENTER_MAX_BID_DESCRIPTION', this.props.theme)}{' '}
                                                    <a
                                                        href={`${this.props.global.frontMediaHost}/bidding-rules`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {lang('BIDDING_RULES', this.props.theme)}
                                                    </a>
                                                </p>
                                                <p className={'m-0'}>
                                                    {getPriceText(vehicle, this.props.theme)}:{' '}
                                                    {`${CURRENCY}${vehicle.biddingDetails.noOfBids === 0 ? vehicle.biddingDetails.startingPrice.toLocaleString() : vehicle.biddingDetails.currentBid.toLocaleString()}`}
                                                    {` `}
                                                    <small>{getReserveStatus(vehicle)}</small>
                                                </p>
                                                {vehicle.biddingDetails.userMaxBid && !vehicle.biddingDetails.ended && (
                                                    <React.Fragment>
                                                        <p className="m-0">
                                                            {lang('YOUR_BID_STATUS', this.props.theme)}:{' '}
                                                            {getBiddingStatusColour(vehicle, this.props.theme) || '-'}
                                                        </p>
                                                        <p>{`${lang('YOUR_CURRENT_MAX_BID', this.props.theme)}: ${CURRENCY}${vehicle.biddingDetails.userMaxBid.toLocaleString()}`}</p>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <form>
                                            <div className="py-3">
                                                <label className="m-0" htmlFor="abMakeOfferInput">
                                                    {lang('ENTER_YOUR_MAX_BID', this.props.theme)}
                                                </label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            {CURRENCY}
                                                        </span>
                                                    </div>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="abProxyBidInput"
                                                        step={25}
                                                        min={
                                                            vehicle.biddingDetails.noOfBids === 0
                                                                ? vehicle.biddingDetails.startingPrice
                                                                : vehicle.biddingDetails.userMaxBid
                                                                  ? vehicle.biddingDetails.userMaxBid
                                                                  : vehicle.biddingDetails.currentBid
                                                        }
                                                        placeholder={
                                                            vehicle.biddingDetails.currentBid >= MAX_BID_THRESHOLD ||
                                                            vehicle.biddingDetails.userMaxBid >= MAX_BID_THRESHOLD
                                                                ? lang('MAX_BID_REACHED', this.props.theme)
                                                                : lang('ENTER_YOUR_OFFER', this.props.theme)
                                                        }
                                                        value={this.state.defaultBidValue}
                                                        onChange={(evt) => this.onChangeBuyNow(evt.target.value)}
                                                        onKeyPress={(e) => {
                                                            this.handleNegative(e)
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                {vehicle.biddingDetails.currentBid >= MAX_BID_THRESHOLD ||
                                                vehicle.biddingDetails.userMaxBid >= MAX_BID_THRESHOLD ? null : (
                                                    <small>
                                                        {lang('LABEL_MINIMUM', this.props.theme)} {CURRENCY}
                                                        {vehicle.biddingDetails.noOfBids === 0
                                                            ? vehicle.biddingDetails.startingPrice.toLocaleString()
                                                            : vehicle.biddingDetails.userMaxBid >
                                                                vehicle.biddingDetails.currentBid
                                                              ? (
                                                                    vehicle.biddingDetails.userMaxBid + 25
                                                                ).toLocaleString()
                                                              : (
                                                                    vehicle.biddingDetails.currentBid + 25
                                                                ).toLocaleString()}
                                                        . {lang('LABEL_MAXIMUM')} {CURRENCY}999,999{' '}
                                                        {getDivisorText(vehicle)}
                                                    </small>
                                                )}
                                            </div>
                                        </form>
                                        <button
                                            className={`btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid ${this.isInValid(vehicle) ? 'disabled' : ''}`}
                                            type="button"
                                            onClick={() => this.placeBid(vehicle.id)}
                                        >
                                            {getExchangeBtnText(vehicle, this.props.theme, true)}
                                        </button>
                                        {!this.props.websocketConnected && (
                                            <div className="websocket-overlay">
                                                <div>
                                                    <ScaleLoader
                                                        className={override}
                                                        size={10}
                                                        color={'#008747'}
                                                        loading={true}
                                                    />
                                                    Connection lost - Attempting to reconnect
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            this.getAuctionEndedSection(vehicle)
                        )}

                        <div className="row">
                            <div className="col-12">
                                {vehicle.listAndCollect ? (
                                    <small>
                                        By submitting an offer you agree to be bound by our{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${this.props.global.frontMediaHost}/media/aston-barclay-files/conditions-of-sale/general-conditions-of-auction.pdf`}
                                        >
                                            General Conditions of Auction
                                        </a>{' '}
                                        and our{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${this.props.global.frontMediaHost}/media/aston-barclay-files/conditions-of-sale/online_terms_and_conditions.pdf`}
                                        >
                                            Online User Terms and Conditions
                                        </a>
                                    </small>
                                ) : (
                                    <small>
                                        By submitting an offer you agree to be bound by the sellers terms and conditions
                                    </small>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                ) : null}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    biddingModal: state.modals.bidding,
    eXchangeVehicles: state.user.exchangeVehicles,
    vehicles: state.vehicles.data,
    websocketConnected: state.websocket.connected,
    global: state.profileConfig && state.profileConfig.data,
    theme: state.user.theme,
})

const mapDispatchToProps = {
    onClickBiddingModal,
    onCloseBiddingModal,
    updateBiddingDetails,
    eXchangeBuyNow,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BiddingModal))
