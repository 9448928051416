import React, { Component } from 'react'
import { CustomInput } from 'reactstrap'
class InputTwo extends Component {
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.state = {
            isChecked: false,
        }
    }

    componentDidMount() {
        this.setState({ isChecked: this.props.checked })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ isChecked: nextProps.checked })
    }

    handleOnChange(_evt, _id) {
        const isChecked = !this.state.isChecked
        this.setState({ isChecked })

        if (this.props.dataType === 'sales') {
            this.props.handleOnSetCriteria(
                this.props.value,
                this.props.dataType,
                isChecked,
                this.props.inputType,
                this.props.offsetFinish,
            )
        } else {
            this.props.handleOnSetCriteria(
                this.props.title,
                this.props.dataType,
                isChecked,
                this.props.inputType,
                this.props.offsetFinish,
            )
        }
    }

    render() {
        let customClass = 'col-sm-6 col-md-4 pb-3 px-0'

        // For the visual style, we wish to leave it coloured in for "Auction Centres" (sites) and "Sale" (sales) criteria.
        // For everything else, we wish to make it $gray-900 as specified in SCSS.
        //
        // I'm using a style over a class because when I use a class, the style is ignored. Likely reactstrap is overriding
        // the className and putting its own one in.
        //
        // I haven't found an elegant way of importing in the $gray-900 value from SCSS and import it into this file without
        // extra imports or weird hacks etc. I figured the best compromise that would effectively get this released and
        // prevent any future incidents is to hardcode the value in here. Not the most elegant, but I don't think we would
        // risk tripping up over weird imports in the future, and I don't think $gray-900 is going to change any time soon.
        let styleForInput = {}

        switch (this.props.dataType) {
            case 'sales':
                customClass = 'col-12 pb-3'
                break
            case 'sites':
                customClass = 'col-6 pb-3'
                break
            default:
                styleForInput = { color: '#212529' }
                break
        }

        return (
            <div className={customClass}>
                <CustomInput
                    className={`${this.props.inputType === 'radio' ? 'custom-radio' : ''} ${
                        this.props.parentItem && this.props.parentItem.indexOf('Digital') >= 0 ? 'e-hub-orange' : ''
                    } text-capitalize`}
                    checked={this.state.isChecked}
                    type={this.props.inputType}
                    value={this.props.value}
                    id={this.props.title}
                    name={this.props.dataType}
                    data-datatype={this.props.dataType}
                    label={
                        <div style={styleForInput}>
                            {this.props.title}
                            <span>({this.props.description})</span>
                        </div>
                    }
                    onChange={this.handleOnChange}
                />
            </div>
        )
    }
}

export default InputTwo
