import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input, Modal, ModalHeader, ModalBody } from 'reactstrap'
// import { formatCurrency } from "../../utilities/Functions";
import { onClickSetProxyBid } from '../../actions/vehicles'
import { CURRENCY } from '../../constants'
import { onOpenProxyBidModal, onCloseProxyBidModal } from '../../actions/modals'

import { lang } from '../../themeConfig'

class ProxyBidwModal extends Component {
    constructor(props) {
        super(props)
        this.onChangeProxyBid = this.onChangeProxyBid.bind(this)
        this.handleProxyBid = this.handleProxyBid.bind(this)
        this.state = {
            proxyAmount: 25,
            valid: true,
        }
    }

    onChangeProxyBid = (event) => {
        const amount = event.target.value
        const amountAsNumber = Number.parseInt(amount)
        const isValid = amountAsNumber > 24 && amountAsNumber % 25 === 0

        this.setState({
            proxyAmount: amountAsNumber,
            valid: isValid,
        })
    }

    keyAllowed(key) {
        const keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
        return !(key && keys.indexOf(key) === -1)
    }

    handleNegative(e) {
        const key = !isNaN(e.charCode) ? e.charCode : e.keyCode
        if (!this.keyAllowed(key)) e.preventDefault()
    }

    async handleProxyBid(event) {
        event.preventDefault()
        event.stopPropagation()
        this.props.onClickSetProxyBid(
            Object.assign(
                {},
                {
                    saleId: this.props.proxyBidModal.data.saleId,
                    vehicleId: this.props.proxyBidModal.data.vehicleId,
                    account: this.props.proxyBidModal.data.account,
                    amount: this.state.proxyAmount,
                },
            ),
        )
        window.dataLayer && window.dataLayer.push({
            'event': 'Proxy bid',
        })
        this.setState({ proxyAmount: 25 })
        this.props.onCloseProxyBidModal()
    }

    render() {
        let buttonClass = 'btn btn-primary btn-block ab-btn-padding'
        if (this.props.proxyBidModal.data.virtual) {
            buttonClass += ' e-hub'
        }

        return (
            <Modal
                isOpen={this.props.proxyBidModal.isOpen}
                toggle={() => {
                    if (this.props.proxyBidModal.isOpen) {
                        return this.props.onCloseProxyBidModal(!this.props.proxyBidModal.isOpen)
                    }
                    return this.props.onOpenProxyBidModal(!this.props.proxyBidModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-lg ab-proxy-bid-modal"
            >
                <ModalHeader
                    toggle={() => {
                        if (this.props.proxyBidModal.isOpen) {
                            return this.props.onCloseProxyBidModal(!this.props.proxyBidModal.isOpen)
                        }
                        return this.props.onOpenProxyBidModal(!this.props.proxyBidModal.isOpen)
                    }}
                >
                    {lang('LABEL_PROXY_BIDDING', this.props.theme)}
                </ModalHeader>
                <ModalBody>
                    <div className="row m-0 p-0">
                        <div className="col-12 col-md-6 m-0 p-0 p-md-2">
                            <p>{lang('LABEL_PROXY_BID_DESCRIPTION', this.props.theme)}</p>
                            <p>
                                {lang('LABEL_PROXY_BID_DESCRIPTION_2', this.props.theme)} {CURRENCY}25
                            </p>
                        </div>
                        <div className="col-12 col-md-6 m-0 p-0 p-md-2">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="abProxyBidInput">{lang('LABEL_PROXY_BID', this.props.theme)}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                {CURRENCY}
                                            </span>
                                        </div>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="abProxyBidInput"
                                            step="25"
                                            placeholder={lang('PLACEHOLDER_ENTER_MAX_BID', this.props.theme)}
                                            defaultValue={this.state.proxyAmount}
                                            onChange={this.onChangeProxyBid}
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                            <button
                                className={buttonClass}
                                disabled={!this.state.valid}
                                onClick={(event) => {
                                    this.handleProxyBid(event)
                                }}
                            >
                                {lang('ADD_PROXY_BID', this.props.theme)}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    proxyBidModal: state.modals.proxyBid,
    theme: state.user.theme,
})

const mapDispatchToProps = {
    onOpenProxyBidModal,
    onCloseProxyBidModal,
    onClickSetProxyBid,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProxyBidwModal))
