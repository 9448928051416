import React, { Component } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import queryString from 'query-string'

import MyAccount from './components/MyAccount'
import Header from './components/Header'
import Footer from './components/Footer'
import VehicleDetails from './components/CatalogueVehicleDetails'
import Catalogue from './components/Catalogue'
import EHub from './components/EHub'
import NotFound from './components/NotFound'
import Modals from './components/Modals'
import MockGui from './UiKit/MockGui'
import Login from './pages/Login'
import HomePage from './pages/HomePage'
import HomePageAuth from './pages/HomePage/HomePageAuth'
import Notifications from './components/Notifications'

import { setLanguage } from './actions/user'

import { connect } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import Unsubscribe from './components/MyAccount/SavedSearches/Unsubscribe'

const mapStateToProps = (state) => ({
    user: state.user,
    global: state.profileConfig && state.profileConfig.data,
    languageDictionary: state.languageDictionary.data,
    permissions: state.permissions.data,
})

const mapDispatchToProps = {
    setLanguage,
}

class App extends Component {
    constructor(props) {
        super(props)
        this.toggleHeaderTotalBar = this.toggleHeaderTotalBar.bind(this)
        this.updateUrl = this.updateUrl.bind(this)
        this.state = {
            showTotalBar: true,
            url: '',
        }
    }

    componentDidMount() {
        if (window.location.search) {
            const values = queryString.parse(window.location.search)

            if (values.language) {
                this.props.setLanguage(values.language)
            }
        }
    }

    UNSAFE_componentWillMount() {
        document.body.classList.add(this.props.user.theme)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeGoogleAnalytics(nextProps.user)
        document.body.classList.remove(this.props.user.theme)
        document.body.classList.add(nextProps.user.theme)
    }

    initializeGoogleAnalytics(user) {
        window.dataLayer && window.dataLayer.push({ 'userId': user.primaryVendorCode })
    }

    toggleHeaderTotalBar(value) {
        this.setState({ showTotalBar: value })
    }

    updateUrl(url) {
        this.setState({ url })
    }

    render() {
        const { user, languageDictionary, permissions } = this.props
        if (user.redirect) {
            window.location.href = this.props.global.userLoginUrl || '/login'
        }

        const theme = this.props.global && this.props.global.theme

        // Check to see if the theme, language and permissions have been loaded
        if (!theme || Object.keys(languageDictionary).length === 0 || Object.keys(permissions).length === 0)
            return (
                <div className="loading-wrapper">
                    <ScaleLoader
                        style={{
                            display: 'block',
                            margin: '0 auto',
                        }}
                        size={10}
                        loading={true}
                    />
                </div>
            )

        let newSiteClass = 'content__wrapper content__wrapper--totalBar'
        return (
            <Router>
                <>
                    <Header
                        {...this.props}
                        showTotalBar={this.state.showTotalBar}
                        updateUrl={this.updateUrl}
                        theme={theme}
                    />
                    <div className={newSiteClass}>
                        <Switch>
                            <Redirect strict from="/my-account/bid-buy-now/" to="/my-account/bid-buy-now" />
                            <Redirect strict from="/my-account/proxy-bids/" to="/my-account/proxy-bids" />
                            <Redirect strict from="/my-account/saved-searches/" to="/my-account/saved-searches" />
                            <Redirect strict from="/my-account/watchlist/" to="/my-account/watchlist" />
                            <Redirect strict from="/my-account/funding/" to="/my-account/funding" />
                            <Redirect strict from="/my-account/e-xchange/" to="/my-account/e-xchange" />

                            <Route exact path="/" component={Catalogue} />
                            <Route exact path="/vehicle-search" component={Catalogue} />
                            <Route exact path="/details/:cardType/:id" component={VehicleDetails} />
                            <Route path="/login" component={Login} />
                            <Route
                                exact
                                path="/newpages/"
                                render={() => <HomePage toggleHeaderTotalBar={this.toggleHeaderTotalBar} />}
                            />
                            <Route
                                exact
                                path="/newpages/buyer-homepage"
                                render={() => <HomePageAuth toggleHeaderTotalBar={this.toggleHeaderTotalBar} />}
                            />

                            <Route path="/my-account/bid-buy-now" component={MyAccount} />
                            <Route exact path="/my-account/proxy-bids" component={MyAccount} />
                            <Route exact path="/e-hub" component={EHub} />
                            <Route exact path="/e-hub/:vrm" component={EHub} />
                            <Route exact path="/e-hub/:accountCode/:path" component={EHub} />
                            <Route exact path="/e-hub/:accountCode/search/:id" component={EHub} />
                            <Route exact path="/e-hub/:accountCode/archive/:id" component={EHub} />
                            <Route exact path="/e-hub/:accountCode/:path/:subPath" component={EHub} />
                            <Route exact path="/e-hub/:accountCode/:path/:subPath/:id" component={EHub} />

                            <Route
                                exact
                                path="/my-account"
                                component={MyAccount}
                                handleOnLoadSearch={this.handleOnLoadSearch}
                            />
                            <Route exact path="/my-account/saved-searches" component={MyAccount} />
                            <Route exact path="/my-account/unsubscribe/:token" component={Unsubscribe} />
                            <Route exact path="/my-account/watchlist" component={MyAccount} />
                            <Route exact path="/my-account/e-xchange" component={MyAccount} />
                            <Route path="/my-account/funding" component={MyAccount} />
                            <Route path="*" component={NotFound} />
                        </Switch>
                        <Footer {...this.props} theme={theme} />
                        <Notifications />
                        <Modals />
                        <MockGui />
                    </div>
                </>
            </Router>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
