import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { onClickAddToEXchange } from '../../actions/ehub'
import { onCloseAddToEXchangeModal, onOpenAddToEXchangeModal, getClosedGroup } from '../../actions/modals'

import {
    AUCTION_PERIOD_OPTIONS,
    LAUNCH_DATE_MAX,
    LAUNCH_DATE_MIN,
    LIST_AND_COLLECT_AUCTION_PERIOD_OPTIONS,
} from '../../constants'
import { pad } from '../../utilities/Functions'
import { ISODateString } from '../../utilities/DateFunctions'

import { lang } from '../../themeConfig'

const launchDateMin = new Date(LAUNCH_DATE_MIN)
const launchDateMax = new Date(LAUNCH_DATE_MAX)

class AddToEXchange extends Component {
    constructor(props) {
        super(props)
        this.onChangeField = this.onChangeField.bind(this)
        this.compareTime = this.compareTime.bind(this)
        this.getAssignToOptionComponent = this.getAssignToOptionComponent.bind(this)
        this.state = {
            reservePrice: '',
            startingPrice: '',
            buyItNowPrice: '',
            selectedCollection: null,
            duration: null,
            listingStartDate: new Date(),
            listingStartTime: '',
            vehicleId: null,
            startTime: new Date(),
            valid: true,
            closedGroup: null,
            closedGroupName: null,
        }
    }

    componentDidMount() {
        if (this.props.appraisal && this.props.appraisal.vendorCode)
            this.props.getClosedGroup(this.props.appraisal.vendorCode)
        this.setLocalState(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.appraisal &&
            this.props.appraisal.vendorCode &&
            this.props.appraisal.vendorCode !== nextProps.appraisal.vendorCode
        )
            this.props.getClosedGroup(this.props.vendorCode)
        this.setLocalState(nextProps)
    }

    componentWillUnmount() {
        this.setState({
            reservePrice: '',
            startingPrice: '',
            buyItNowPrice: '',
            selectedCollection: null,
            duration: null,
            listingStartDate: new Date(),
            listingStartTime: '',
            vehicleId: null,
            startTime: new Date(),
            valid: true,
            closedGroup: null,
            closedGroupName: null,
        })
    }

    setLocalState = (props) => {
        const isTCBG = props.user.accountName && props.user.accountName.value === 'THE034'
        const listingStartDate = isTCBG
            ? new Date() < launchDateMin
                ? launchDateMin
                : new Date()
            : new Date() < launchDateMax
              ? launchDateMax
              : new Date()
        const cgn =
            props.addToEXchangeModal &&
            props.addToEXchangeModal.closedGroupName &&
            props.addToEXchangeModal.closedGroupName.name
                ? props.addToEXchangeModal.closedGroupName.name
                : null
        this.setState({
            selectedCollection: props.addToEXchangeModal.data.collectionSites
                ? props.addToEXchangeModal.data.collectionSites.selectedOption
                : null,
            collections: props.addToEXchangeModal.data.collectionSites
                ? props.addToEXchangeModal.data.collectionSites.options
                : null,
            duration: !props.addToEXchangeModal.data.listAndCollect ? { value: 5, label: '5 days' } : null,
            listingStartDate,
            listingStartTime: `${pad(new Date().getHours(), 2)}:${pad(new Date().getMinutes(), 2)}`,
            vehicleId: props.addToEXchangeModal.data.vehicleId,
            closedGroup:
                props.user && props.user.closedGroupSeller && props.addToEXchangeModal.data.allowClosed ? null : false,
            closedGroupName: cgn,
        })
    }

    setClosedGroup = (closedGroup) => {
        this.setState({ closedGroup })
    }

    onChangeField = (key, value) => {
        this.setState({
            [key]: value,
        })
    }

    compareTime() {
        const inputDate = new Date(`01/01/2000 ${this.state.listingStartTime}`)
        const defaultMinDate = new Date(`01/01/2000 ${this.getMinTime(this.state.listingStartDate)}`)
        const defaultMaxDate = new Date(`01/01/2000 ${this.getMaxTime(this.state.listingStartDate)}`)

        return inputDate >= defaultMinDate && inputDate <= defaultMaxDate
    }

    keyAllowed(key) {
        const keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
        return !(key && keys.indexOf(key) === -1)
    }

    handleNegative(e) {
        const key = !isNaN(e.charCode) ? e.charCode : e.keyCode
        if (!this.keyAllowed(key)) e.preventDefault()
    }

    async handleAddToEXchange(event) {
        event.preventDefault()
        event.stopPropagation()

        let payload = {
            closedGroup: this.state.closedGroup,
            reservePrice: parseInt(this.state.reservePrice, 10),
            buyItNowPrice: parseInt(this.state.buyItNowPrice, 10),
            startingPrice: parseInt(this.state.startingPrice, 10),
            startDate: ISODateString(this.state.listingStartDate, false, true, true),
            startTime: this.state.listingStartTime,
            duration: this.state.duration ? parseInt(this.state.duration.value, 10) : null,
            vehicleId: this.state.vehicleId,
        }

        if (this.props.addToEXchangeModal.data.eXchangeType !== 'ListAndDeliver') {
            payload = Object.assign(payload, {
                addressId: this.state.selectedCollection ? this.state.selectedCollection.value : null,
            })
        }

        this.props.onClickAddToEXchange(
            this.props.addToEXchangeModal.data.appraisalId,
            payload,
            this.props.addToEXchangeModal.data.accountCode,
        )
        window.dataLayer && window.dataLayer.push({
            'event': 'Add vehicle to e-Xchange',
        })
        this.setState({ sivPrice: null, reservPrice: null })
        this.props.onCloseAddToEXchangeModal()
    }
    IsNumeric(input) {
        return input - 0 === input && ('' + input).trim().length > 0
    }

    getMaxDate = () => {
        const a = new Date() < launchDateMax ? launchDateMax : new Date()
        return new Date(new Date(a).getTime() + 60 * 60 * 24 * 1000)
    }

    getMinTime = (date) => {
        const beginningOfDay = new Date(date).setHours(0, 0, 0, 0)
        const isTCBG = this.props.user.accountName && this.props.user.accountName.value === 'THE034'
        const min = isTCBG
            ? new Date() < launchDateMin
                ? launchDateMin
                : new Date()
            : new Date() < launchDateMax
              ? launchDateMax
              : new Date()
        let newDate
        if (new Date() < min) {
            newDate = moment().hours(0).minutes(0).seconds(0).toDate()
        } else if (beginningOfDay === new Date().setHours(0, 0, 0, 0)) {
            newDate = moment().hours(new Date(date).getHours()).minutes(new Date(date).getMinutes()).seconds(0).toDate()
        } else {
            newDate = moment().add(1, 'd').hours(0).minutes(0).seconds(0).toDate()
        }
        return `${pad(newDate.getHours(), 2)}:${pad(newDate.getMinutes(), 2)}`
    }

    getMaxTime = (date) => {
        const beginningOfDay = new Date(date).setHours(0, 0, 0, 0)
        const isTCBG = this.props.user.accountName && this.props.user.accountName.value === 'THE034'
        const min = isTCBG
            ? new Date() < launchDateMin
                ? launchDateMin
                : new Date()
            : new Date() < launchDateMax
              ? launchDateMax
              : new Date()
        let newDate
        if (beginningOfDay === new Date().setHours(0, 0, 0, 0) || new Date() < min) {
            newDate = moment().hours(23).minutes(59).seconds(0).toDate()
        } else {
            newDate = moment()
                .add(1, 'd')
                .hours(new Date(date).getHours())
                .minutes(new Date(date).getMinutes())
                .seconds(0)
                .toDate()
        }
        return `${pad(newDate.getHours(), 2)}:${pad(newDate.getMinutes(), 2)}`
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault()
    }

    offerClosedGroup(user) {
        return user && user.closedGroupSeller && this.props.addToEXchangeModal.data.allowClosed && true
    }

    getOptions(props) {
        if (
            props.user.accountsAccess &&
            props.user.accountsAccess[props.addToEXchangeModal.data.accountCode] &&
            props.user.accountsAccess[props.addToEXchangeModal.data.accountCode].listAndCollect
        ) {
            return LIST_AND_COLLECT_AUCTION_PERIOD_OPTIONS
        }
        return AUCTION_PERIOD_OPTIONS
    }

    getAssignToOptionComponent(allowedClosed) {
        if (!this.state.closedGroupName || !allowedClosed) return null

        return (
            <div className="col-12 pl-0 pr-0 pb-4">
                <label>
                    Assign to<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '0.5rem' }}>
                    <Button
                        id="assignClosedGroup"
                        outline
                        color="primary"
                        style={{ flex: 1 }}
                        onClick={() => this.setClosedGroup(true)}
                        active={this.state.closedGroup != null && this.state.closedGroup}
                    >
                        Closed Group
                    </Button>
                    <Button
                        id="assignAllBuyers"
                        outline
                        color="primary"
                        style={{ flex: 1 }}
                        onClick={() => this.setClosedGroup(false)}
                        active={this.state.closedGroup != null && !this.state.closedGroup}
                    >
                        All Buyers
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const isInvalid =
            !this.state.duration ||
            Object.keys(this.state.duration).length === 0 ||
            isNaN(this.state.reservePrice) ||
            this.state.reservePrice === '' ||
            this.state.reservePrice === '0' ||
            isNaN(this.state.startingPrice) ||
            this.state.startingPrice === '' ||
            this.state.startingPrice === '0' ||
            this.state.listingStartDate === '' ||
            parseInt(this.state.reservePrice, 10) < parseInt(this.state.startingPrice, 10) + 50 ||
            (this.state.buyItNowPrice !== '' &&
                parseInt(this.state.buyItNowPrice, 10) < parseInt(this.state.reservePrice, 10) + 50) ||
            !this.compareTime() ||
            (this.offerClosedGroup(this.props.user) && this.state.closedGroup == null)

        const options = this.getOptions(this.props)
        const isTCBG = this.props.user.accountName && this.props.user.accountName.value === 'THE034'

        return (
            <Modal
                isOpen={this.props.addToEXchangeModal.isOpen}
                toggle={() => {
                    if (this.props.addToEXchangeModal.isOpen) {
                        return this.props.onCloseAddToEXchangeModal(!this.props.addToEXchangeModal.isOpen)
                    }
                    return this.props.onOpenAddToEXchangeModal(!this.props.addToEXchangeModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-md add-to-e-xchange"
            >
                <ModalHeader
                    id="modal-header"
                    toggle={() => {
                        if (this.props.addToEXchangeModal.isOpen) {
                            return this.props.onCloseAddToEXchangeModal(!this.props.addToEXchangeModal.isOpen)
                        }
                        return this.props.onOpenAddToEXchangeModal(!this.props.addToEXchangeModal.isOpen)
                    }}
                >
                    {lang('BUTTON_ADD_TO_EXCHANGE', this.props.user.theme)}
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="row m-0 p-0">
                            {this.props.addToEXchangeModal.data.listAndCollect && (
                                <div className="col-12  m-0 p-0">
                                    <div className="form-group">
                                        <label htmlFor="auctionsite">Aston Barclay site</label>
                                        <div className="input-group">
                                            {this.state.selectedCollection && (
                                                <Select
                                                    id="site-select"
                                                    isDisabled={true}
                                                    value={this.state.selectedCollection}
                                                    onChange={this.onChangeSite}
                                                    options={this.state.collections}
                                                    isSearchable={false}
                                                    classNamePrefix="ab-select"
                                                    className="custom-select-input prepend"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.getAssignToOptionComponent(this.props.addToEXchangeModal.data.allowClosed)}

                            <div className="col-6 m-0 p-0 pr-2">
                                <div className="form-group">
                                    <label htmlFor="auctionsite">
                                        Start date <small style={{ color: 'red' }}>*</small>
                                    </label>
                                    <div className="input-group">
                                        <DatePicker
                                            id="start-date"
                                            selected={this.state.listingStartDate}
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={
                                                isTCBG
                                                    ? new Date() < launchDateMin
                                                        ? launchDateMin
                                                        : new Date()
                                                    : new Date() < launchDateMax
                                                      ? launchDateMax
                                                      : new Date()
                                            }
                                            maxDate={this.getMaxDate()}
                                            onChange={(date) => this.onChangeField('listingStartDate', date)}
                                            onChangeRaw={this.handleDateChangeRaw}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 m-0 p-0">
                                <div className="form-group">
                                    <label htmlFor="auctionsite">
                                        Start time <small style={{ color: 'red' }}>*</small>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            id="start-time"
                                            type="time"
                                            name="start-time"
                                            className={`form-control ${!this.compareTime() ? 'is-invalid' : ''}`}
                                            value={this.state.listingStartTime}
                                            min={this.getMinTime(this.state.listingStartDate)}
                                            max={this.getMaxTime(this.state.listingStartDate)}
                                            onChange={(evt) => this.onChangeField('listingStartTime', evt.target.value)}
                                        />
                                    </div>
                                    {!this.compareTime() && (
                                        <small className="text-danger">
                                            Must be between {this.getMinTime(this.state.listingStartDate)} and{' '}
                                            {this.getMaxTime(this.state.listingStartDate)}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0 pr-2">
                                <div className="form-group">
                                    <label htmlFor="duration">
                                        Duration <small>(no sunday endings) </small>
                                        <small style={{ color: 'red' }}>*</small>
                                    </label>
                                    <div className="input-group">
                                        <Select
                                            id="duration"
                                            // classNamePrefix="duration-select"
                                            className="input-prepend-select duration-select"
                                            value={this.state.duration}
                                            onChange={(val) => this.onChangeField('duration', val)}
                                            options={options}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0">
                                <div className="form-group">
                                    <label htmlFor="startingPrice">
                                        Starting price{' '}
                                        <small>
                                            <span style={{ color: 'red' }}>*</span>
                                        </small>
                                    </label>
                                    <div className="input-group ab-prepend">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                £
                                            </span>
                                        </div>
                                        <Input
                                            id="startingPrice"
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter starting price"
                                            value={this.state.startingPrice ? this.state.startingPrice : ''}
                                            onChange={(evt) => this.onChangeField('startingPrice', evt.target.value)}
                                            required
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0 pr-2">
                                <div className="form-group">
                                    <label htmlFor="reservePrice">
                                        Reserve price <small style={{ color: 'red' }}>*</small>
                                    </label>
                                    <div className="input-group ab-prepend">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                £
                                            </span>
                                        </div>
                                        <Input
                                            type="number"
                                            id="reservePrice"
                                            className="form-control"
                                            placeholder="Enter reserve price"
                                            value={this.state.reservePrice ? this.state.reservePrice : ''}
                                            onChange={(evt) => this.onChangeField('reservePrice', evt.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                        <small className="mt-1">Must be £50 more than starting price</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0">
                                <div className="form-group">
                                    <label htmlFor="buyItNowPrice">Buy it now price</label>
                                    <div className="input-group ab-prepend">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                £
                                            </span>
                                        </div>
                                        <Input
                                            id="buyItNowPrice"
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter buy it now price"
                                            value={this.state.buyItNowPrice ? this.state.buyItNowPrice : ''}
                                            onChange={(evt) => this.onChangeField('buyItNowPrice', evt.target.value)}
                                            required
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                        <small className="mt-1">Must be £50 more than reserve price</small>
                                    </div>
                                </div>
                            </div>
                            {this.props.user.listAndCollect && (
                                <div className="col-12 m-0 p-0 pb-2">
                                    <small>2 day message for list and collect will go here</small>
                                </div>
                            )}
                            <div className="col-12  m-0 p-0">
                                <button
                                    id="add-to-exchange-btn"
                                    className={`${isInvalid ? 'disabled' : ''} btn btn-primary btn-block ab-btn-padding`}
                                    disabled={isInvalid}
                                    onClick={(event) => {
                                        this.handleAddToEXchange(event)
                                    }}
                                >
                                    {!this.state.vehicleId && lang('BUTTON_ADD_TO_EXCHANGE', this.props.user.theme)}
                                    {this.state.vehicleId && lang('BUTTON_RELIST_E-XCHANGE', this.props.user.theme)}
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    addToEXchangeModal: state.modals.addToEXchange,
    appraisal:
        state.ehub && state.ehub.appraisals && state.ehub.appraisals.length > 0 ? state.ehub.appraisals[0] : null,
    closedGroupName: state.closedGroupName,
})

const mapDispatchToProps = {
    onOpenAddToEXchangeModal,
    onCloseAddToEXchangeModal,
    onClickAddToEXchange,
    getClosedGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToEXchange)

export { AddToEXchange }
