import {
    API_BASE,
    API_GET_HEADERS,
    API_POST_HEADERS,
    BUY_NOW,
    BUY_NOW_ERROR,
    BUY_NOW_EXCHANGE,
    BUY_NOW_EXCHANGE_ERROR,
    BUY_NOW_EXCHANGE_SUCCESS,
    BUY_NOW_SUCCESS,
    FETCH_BID_BUY_NOW_VEHICLES,
    FETCH_BID_BUY_NOW_VEHICLES_ERROR,
    FETCH_BID_BUY_NOW_VEHICLES_SUCCESS,
    SET_PROXY_BID_START,
    SET_PROXY_BID_ERROR,
    SET_PROXY_BID_SUCCESS,
    FETCH_PROXY_BID_VEHICLES,
    FETCH_PROXY_BID_VEHICLES_ERROR,
    FETCH_PROXY_BID_VEHICLES_SUCCESS,
    FETCH_VEHICLE_DETAILS,
    FETCH_VEHICLE_DETAILS_ERROR,
    FETCH_VEHICLE_DETAILS_SUCCESS,
    FETCH_VEHICLES,
    FETCH_VEHICLES_ERROR,
    FETCH_VEHICLES_SUCCESS,
    FETCH_WATCH_VEHICLE,
    FETCH_WATCH_VEHICLE_ERROR,
    FETCH_WATCH_VEHICLE_SUCCESS,
    FETCH_WATCHED_VEHICLES,
    FETCH_WATCHED_VEHICLES_ERROR,
    FETCH_WATCHED_VEHICLES_SUCCESS,
    MAKE_AN_OFFER,
    MAKE_AN_OFFER_FAILURE,
    MAKE_AN_OFFER_SUCCESS,
    SET_PAGINATION_PAGES,
    UPDATE_AUCTION_SUCCESS,
    UPDATE_BIDDING_DETAILS,
    UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    UPDATE_BIDDING_DETAILS_SUCCESS,
    UPDATE_WATCHERS_DETAILS_SUCCESS,
    VEHICLE_AUCTION_ENDED,
    VEHICLE_ENDS_SOON,
} from '../constants'

import { handleError } from '../utilities/Functions'

import { setSortingOrder } from './sorting'

import { addNotification } from './notifications'

import { BuildSearchQueryForItem } from '../utilities/SearchQuery'

import { emit } from './websocket'

export const fetchVehiclesBegin = () => ({
    type: FETCH_VEHICLES,
})

export const fetchVehiclesSuccess = (vehicles) => ({
    type: FETCH_VEHICLES_SUCCESS,
    payload: vehicles,
})

export const fetchVehiclesFailure = (error) => ({
    type: FETCH_VEHICLES_ERROR,
    payload: { error },
})

export const setTotalPages = (length) => ({
    type: SET_PAGINATION_PAGES,
    payload: length,
})

export const fetchVehicleDetails = () => ({
    type: FETCH_VEHICLE_DETAILS,
})

export const fetchVehicleDetailsSuccess = (payload) => ({
    type: FETCH_VEHICLE_DETAILS_SUCCESS,
    payload,
})

export const fetchVehicleDetailsFailure = (error) => ({
    type: FETCH_VEHICLE_DETAILS_ERROR,
    payload: { error },
})

export const fetchWatchVehicle = () => ({
    type: FETCH_WATCH_VEHICLE,
})

export const fetchWatchVehicleSuccess = (id, watching) => ({
    type: FETCH_WATCH_VEHICLE_SUCCESS,
    payload: {
        id,
        watching,
    },
})

export const fetchWatchVehicleFailure = (error) => ({
    type: FETCH_WATCH_VEHICLE_ERROR,
    payload: { error },
})

export const SetProxyBidStart = () => ({
    type: SET_PROXY_BID_START,
})

export const SetProxyBidSuccess = (payload) => ({
    type: SET_PROXY_BID_SUCCESS,
    payload,
})

export const SetProxyBidFailure = (error) => ({
    type: SET_PROXY_BID_ERROR,
    payload: { error },
})

export const buyNow = () => ({
    type: BUY_NOW,
})

export const buyNowSuccess = () => ({
    type: BUY_NOW_SUCCESS,
})

export const buyNowFailure = () => ({
    type: BUY_NOW_ERROR,
})

export const eXchangeBuyNowBegin = () => ({
    type: BUY_NOW_EXCHANGE,
})

export const eXchangeBuyNowSuccess = (payload) => ({
    type: BUY_NOW_EXCHANGE_SUCCESS,
    payload,
})

export const eXchangeBuyNowFailure = (error) => ({
    type: BUY_NOW_EXCHANGE_ERROR,
    error,
})

export const makeAnOffer = () => ({
    type: MAKE_AN_OFFER,
})

export const makeAnOfferSuccess = (payload) => ({
    type: MAKE_AN_OFFER_SUCCESS,
    payload,
})

export const makeAnOfferFailure = (error) => ({
    type: MAKE_AN_OFFER_FAILURE,
    payload: { error },
})

export const fetchWatchedVehiclesBegin = () => ({
    type: FETCH_WATCHED_VEHICLES,
})

export const fetchWatchedVehiclesSuccess = (savedSearches) => ({
    type: FETCH_WATCHED_VEHICLES_SUCCESS,
    payload: savedSearches,
})

export const fetchWatchedVehiclesFailure = (error) => ({
    type: FETCH_WATCHED_VEHICLES_ERROR,
    payload: { error },
})

export const fetchProxyBidVehiclesBegin = () => ({
    type: FETCH_PROXY_BID_VEHICLES,
})

export const fetchProxyBidVehiclesSuccess = (savedSearches) => ({
    type: FETCH_PROXY_BID_VEHICLES_SUCCESS,
    payload: savedSearches,
})

export const auctionEnded = (payload) => ({
    type: VEHICLE_AUCTION_ENDED,
    payload,
})

export const endsSoon = (payload) => ({
    type: VEHICLE_ENDS_SOON,
    payload,
})

export const fetchProxyBidVehiclesFailure = (error) => ({
    type: FETCH_PROXY_BID_VEHICLES_ERROR,
    payload: { error },
})

export const fetchBidBuyNowVehiclesBegin = () => ({
    type: FETCH_BID_BUY_NOW_VEHICLES,
})

export const fetchBidBuyNowVehiclesSuccess = (savedSearches) => ({
    type: FETCH_BID_BUY_NOW_VEHICLES_SUCCESS,
    payload: savedSearches,
})

export const fetchBidBuyNowVehiclesFailure = (error) => ({
    type: FETCH_BID_BUY_NOW_VEHICLES_ERROR,
    payload: { error },
})

export const updateBiddingDetailsBegin = () => ({
    type: UPDATE_BIDDING_DETAILS,
})

export const updateWatchingDetailsSuccess = (data) => ({
    type: UPDATE_WATCHERS_DETAILS_SUCCESS,
    payload: data,
})

export const updateBiddingDetailsSuccess = (data) => ({
    type: UPDATE_BIDDING_DETAILS_SUCCESS,
    payload: data,
})

export const updateBiddingDetailsAllSuccess = (data) => ({
    type: UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    payload: data,
})

export const updateBAuctionSuccess = (data) => ({
    type: UPDATE_AUCTION_SUCCESS,
    payload: data,
})

export function subscribeUpdateBiddingDetails(payload) {
    return async (dispatch) => {
        return dispatch(updateBiddingDetailsSuccess(payload))
    }
}

export function getVehicles(criteria, _sorting) {
    const url = `${API_BASE}api/vehicle/search${BuildSearchQueryForItem('', criteria)}`
    return async (dispatch) => {
        dispatch(fetchVehiclesBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    const sortedIds = data.map((vehicle) => vehicle.id)

                    dispatch(setSortingOrder(sortedIds))

                    dispatch(fetchVehiclesSuccess(data))

                    return data
                } else {
                    handleError(status, dispatch, data, fetchVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchVehiclesFailure(error))
            })
    }
}

export function getExchangeVehicles(_criteria, _sorting) {
    const url = `${API_BASE}api/e-xchange/auth/vehicles`
    return async (dispatch) => {
        dispatch(fetchVehiclesBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    const sortedIds = data.map((vehicle) => vehicle.id)
                    dispatch(setSortingOrder(sortedIds))
                    dispatch(fetchVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchVehiclesFailure(error))
            })
    }
}

export function eXchangeBuyNow(payload) {
    const url = `${API_BASE}api/e-xchange/auth/buy-now`
    const newHeaders = Object.assign(API_POST_HEADERS('1.0'), {
        body: JSON.stringify(payload),
    })
    return async (dispatch) => {
        dispatch(eXchangeBuyNowBegin())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(eXchangeBuyNowSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, eXchangeBuyNowFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(eXchangeBuyNowFailure(error))
            })
    }
}

export function getVehicleDetails(id, view) {
    let url = `${API_BASE}api/vehicle/details/${id}`

    if (view) {
        url = `${API_BASE}api/vehicle/details/${id}?view=${view}`
    }
    return async (dispatch) => {
        dispatch(fetchVehicleDetails())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchVehicleDetailsSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchVehicleDetailsFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchVehicleDetailsFailure(error))
            })
    }
}

export function endAuction(id) {
    return async (dispatch) => {
        dispatch(auctionEnded({ id }))
    }
}

export function triggerEndsSoon(id) {
    return async (dispatch) => {
        dispatch(endsSoon({ id }))
    }
}

export function onClickWatch(watching, id) {
    const watch = watching ? 'watch' : 'unwatch'
    const url = `${API_BASE}api/watchlist/auth/${watch}`
    const newHeaders = Object.assign(API_POST_HEADERS('1.0'), {
        body: JSON.stringify({
            id: id,
        }),
    })
    return async (dispatch) => {
        dispatch(fetchWatchVehicle())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchWatchVehicleSuccess(id, watching))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchWatchVehicleFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchWatchVehicleFailure(error))
            })
    }
}

export function onClickSetProxyBid(payload) {
    const url = `${API_BASE}api/proxybid/auth`
    const newHeaders = Object.assign(API_POST_HEADERS(), {
        body: JSON.stringify(payload),
    })

    return async (dispatch) => {
        dispatch(SetProxyBidStart())

        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(SetProxyBidSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, SetProxyBidFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(SetProxyBidFailure(error))
            })
    }
}

export function onClickMakeAnOffer(payload) {
    const url = `${API_BASE}api/bid-and-buy-now/auth`
    const newHeaders = Object.assign(API_POST_HEADERS(), {
        body: JSON.stringify(payload),
    })
    return async (dispatch) => {
        dispatch(makeAnOffer())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(makeAnOfferSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, makeAnOfferFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(makeAnOfferFailure(error))
            })
    }
}

export function fetchWatchedVehicles() {
    const url = `${API_BASE}api/watchlist/auth/vehicles`
    return async (dispatch) => {
        dispatch(fetchWatchedVehiclesBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(setTotalPages(data.length))
                    dispatch(fetchWatchedVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, makeAnOfferFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(makeAnOfferFailure(error))
            })
    }
}

export function fetchProxyBidVehicles() {
    const url = `${API_BASE}api/proxybid/auth/vehicles`
    return async (dispatch) => {
        dispatch(fetchProxyBidVehiclesBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(setTotalPages(data.length))
                    dispatch(fetchProxyBidVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchProxyBidVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchProxyBidVehiclesFailure(error))
            })
    }
}

export function fetchBidBuyNowVehicles() {
    const url = `${API_BASE}api/bid-and-buy-now/auth/vehicles`
    return async (dispatch) => {
        dispatch(fetchBidBuyNowVehiclesBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(setTotalPages(data.length))
                    dispatch(fetchBidBuyNowVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchBidBuyNowVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchBidBuyNowVehiclesFailure(error))
            })
    }
}

export function onClickSetBuyNow(payload) {
    const url = `${API_BASE}api/bid-and-buy-now/auth`
    const newHeaders = Object.assign(API_POST_HEADERS(), {
        body: JSON.stringify(payload),
    })
    return async (dispatch) => {
        dispatch(buyNow())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(buyNowSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, buyNowFailure)
                }
            })
            .catch((error) => {
                dispatch(buyNowFailure(error))
            })
    }
}

export function updateBiddingDetails(data) {
    return async () => {
        emit('/auction/bid', data)
    }
}
