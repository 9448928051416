export function searchQuerySeparator(a) {
    return a.length ? '&' : '?'
}

export function BuildSearchQuery(criteria) {
    return BuildSearchQueryForItem('', criteria)
}

export function BuildSearchQueryForItem(item, criteria) {
    let filter = ''

    if (item !== 'sites' && criteria.sites && criteria.sites.length > 0) {
        criteria.sites.forEach(function (sites) {
            filter += searchQuerySeparator(filter) + 'siteid=' + encodeURIComponent(sites)
        })
    }

    if (item !== 'sales' && criteria.sales && criteria.sales.length > 0) {
        if (criteria.sales.length === 1) {
            if (criteria.sales[0] === 99) {
                filter += searchQuerySeparator(filter) + 'exchangeonly=true'
            } else {
                filter += searchQuerySeparator(filter) + 'saleid=' + encodeURIComponent(criteria.sales[0])
            }
        } else {
            criteria.sales.forEach(function (sale) {
                if (sale === 99) {
                    filter += searchQuerySeparator(filter) + 'includeexchange=true'
                } else {
                    filter += searchQuerySeparator(filter) + 'saleid=' + encodeURIComponent(sale)
                }
            })
        }
    }

    if (item !== 'saleDays' && criteria.saleDays && criteria.saleDays.length > 0) {
        criteria.saleDays.forEach((saleDay) => {
            filter += searchQuerySeparator(filter) + 'saleday=' + encodeURIComponent(saleDay)
        })
    }

    if (item !== 'makes' && criteria.makes && criteria.makes.length > 0) {
        criteria.makes.forEach(function (make) {
            filter += searchQuerySeparator(filter) + 'make=' + encodeURIComponent(make)
        })
    }

    if (item !== 'models' && criteria.models && criteria.models.length > 0) {
        criteria.models.forEach(function (model) {
            filter += searchQuerySeparator(filter) + 'model=' + encodeURIComponent(model)
        })
    }

    if (item !== 'modelVariants' && criteria.modelVariants && criteria.modelVariants.length > 0) {
        criteria.modelVariants.forEach(function (derivative) {
            filter += searchQuerySeparator(filter) + 'derivative=' + encodeURIComponent(derivative)
        })
    }

    if (item !== 'transmission' && criteria.transmission && criteria.transmission.length > 0) {
        filter += searchQuerySeparator(filter) + 'transmission=' + criteria.transmission
    }

    if (item !== 'doors' && criteria.doors && criteria.doors.length > 0) {
        criteria.doors.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'doors=' + encodeURIComponent(value)
        })
    }

    if (item !== 'buyNow' && criteria.buyNow) {
        filter += searchQuerySeparator(filter) + 'bidandbuy=true'
    }

    if (item !== 'eXchangeOnly' && criteria.eXchangeOnly) {
        filter += searchQuerySeparator(filter) + 'exchangeonly=true'
    }

    if (item !== 'nonrunner' && criteria.nonRunner && criteria.nonRunner.length > 0) {
        filter += searchQuerySeparator(filter) + 'nonrunner=' + criteria.nonRunner
    }

    if (item !== 'bodyTypes' && criteria.bodyTypes && criteria.bodyTypes.length > 0) {
        criteria.bodyTypes.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'bodytype=' + encodeURIComponent(value)
        })
    }

    if (item !== 'keywords' && criteria.keywords && criteria.keywords) {
        filter += searchQuerySeparator(filter) + 'keywords=' + encodeURIComponent(criteria.keywords)
    }

    if (item !== 'vehicleTypes' && criteria.vehicleTypes && criteria.vehicleTypes.length > 0) {
        criteria.vehicleTypes.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'vehicletype=' + encodeURIComponent(value)
        })
    }

    // if (criteria.age) {
    //   if (criteria.age && criteria.age.min && criteria.age.min.label !== "any") {
    //     filter +=
    //       searchQuerySeparator(filter) + "agemin=" + criteria.age.min.value;
    //   }
    //   if (criteria.age && criteria.age.max && criteria.age.max.label !== "any") {
    //     filter +=
    //       searchQuerySeparator(filter) + "agemax=" + criteria.age.max.value;
    //   }
    // }

    if (criteria.age) {
        filter += rangeCriteria(filter, criteria.age.min, item, 'age', 'agemin', '-1')
        filter += rangeCriteria(filter, criteria.age.max, item, 'age', 'agemax', '-1')
    }

    if (criteria.nama) {
        if (criteria.nama && criteria.nama.min && criteria.nama.min.label !== 'any') {
            filter += searchQuerySeparator(filter) + 'namamin=' + criteria.nama.min.value
        }
        if (criteria.nama && criteria.nama.max && criteria.nama.max.label !== 'any') {
            filter += searchQuerySeparator(filter) + 'namamax=' + criteria.nama.max.value
        }
    }

    if (item !== 'eurostatus' && criteria.euroStatus && criteria.euroStatus.length > 0) {
        criteria.euroStatus.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'eurostatus=' + encodeURIComponent(value)
        })
    }

    if (item !== 'fuelTypes' && criteria.fuelTypes && criteria.fuelTypes.length > 0) {
        criteria.fuelTypes.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'fuel=' + encodeURIComponent(value)
        })
    }

    if (criteria.mileage) {
        filter += rangeCriteria(filter, criteria.mileage.min, item, 'mileage', 'mileagemin', '-1')
        filter += rangeCriteria(filter, criteria.mileage.max, item, 'mileage', 'mileagemax', '-1')
    }

    if (criteria.engineSize) {
        filter += rangeCriteria(filter, criteria.engineSize.min, item, 'engineSize', 'enginemin', '-1')
        filter += rangeCriteria(filter, criteria.engineSize.max, item, 'engineSize', 'enginemax', '-1')
    }

    if (item !== 'sellers' && criteria.sellers && criteria.sellers.length > 0) {
        criteria.sellers.forEach(function (value) {
            filter += searchQuerySeparator(filter) + 'seller=' + encodeURIComponent(value)
        })
    }

    if (item !== 'writeoff' && criteria.writeOff && criteria.writeOff.length > 0) {
        filter += searchQuerySeparator(filter) + 'writeoffs=' + criteria.writeOff
    }

    return filter
}

const rangeCriteria = (filter, criteria, item, itemName, urlName, anyValue) => {
    if (criteria && (item === itemName || criteria.value !== anyValue)) {
        return searchQuerySeparator(filter) + urlName + '=' + criteria.value
    } else {
        return ''
    }
}
