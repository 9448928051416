import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import Banner from './Banner'
import CatalogueHeader from './Headers/CatalogueHeader'
import VehicleImage from './VehicleImage'
import BidActionButtons from '../ActionButtons/BidActionButtons'
import SecureBankTrustActionButton from '../ActionButtons/SecureBankTrustButton'
import { IoIosLock } from '@react-icons/all-files/io/IoIosLock'

import { cardConfig } from './config'
import { getLocation } from '../../components/CatalogueVehicleDetails/VehicleHelper'
import {
    checkFormEligibility,
    gotoVehicleDetails,
    hasTradeAccess,
    Hover,
    isSecureBankTrust,
    openAddToExchangeModal,
} from '../../utilities/Functions'
import Prices from './Prices'
import VehicleRemarks from './VehicleRemarks'
import { getBiddingStatusColour, getPriceText, getReserveStatus } from '../../utilities/AuctionFunctions'
import { lang } from '../../themeConfig'
import {
    APP_EHUB,
    CT_CATALOGUE,
    CT_COLLECTIONS,
    CT_EHUB,
    CT_EHUB_EXCHANGE,
    CT_ARCHIVED,
    CT_EVALUATE,
    CT_EXCHANGE,
    CT_SECURE_BANK_TRUST_FUNDING,
    CURRENCY,
} from '../../constants'
import confirm from '../../utilities/Confirm'

const getPrices = (vehicle, user) => {
    if (vehicle.cardType === CT_EHUB_EXCHANGE) {
        return {
            priceStart: {
                title: lang('LABEL_STARTING_PRICE', user.theme),
                value: vehicle.biddingDetails.startingPrice
                    ? `${CURRENCY}${vehicle.biddingDetails.startingPrice.toLocaleString()}`
                    : '-',
            },
            priceMiddle: {
                title: lang('LABEL_RESERVE_PRICE', user.theme),
                value: vehicle.reservePrice ? `${CURRENCY}${vehicle.reservePrice.toLocaleString()}` : '-',
            },
            priceEnd: {
                title: lang('LABEL_BUY_NOW_PRICE', user.theme),
                value: vehicle.biddingDetails.buyNowPrice
                    ? `${CURRENCY}${vehicle.biddingDetails.buyNowPrice.toLocaleString()}`
                    : '-',
            },
        }
    }
    if (vehicle.cardType === CT_EVALUATE && user.accountsAccess[vehicle.vendorCode].cap) {
        return {
            priceStart: {
                title: lang('LABEL_CAP_CLEAN', user.theme),
                value: vehicle.capCleanValuation ? `${CURRENCY}${vehicle.capCleanValuation.toLocaleString()}` : '-',
            },
            priceMiddle: {
                title: lang('LABEL_CAP_AVERAGE', user.theme),
                value: vehicle.capAverageValuation ? `${CURRENCY}${vehicle.capAverageValuation.toLocaleString()}` : '-',
            },
            priceEnd: {
                title: lang('LABEL_CAP_BELOW', user.theme),
                value: vehicle.capBelowValuation ? `${CURRENCY}${vehicle.capBelowValuation.toLocaleString()}` : '-',
            },
        }
    }
    if (
        (vehicle.cardType === CT_EHUB || vehicle.cardType === CT_EVALUATE || vehicle.cardType === CT_COLLECTIONS) &&
        user.accountsAccess[vehicle.vendorCode].cap
    ) {
        return {
            priceStart: {
                title: lang('LABEL_CAP_CLEAN', user.theme),
                value: vehicle.capCleanValuation ? `${CURRENCY}${vehicle.capCleanValuation.toLocaleString()}` : '-',
            },
            priceMiddle: {
                title: lang('LABEL_CAP_AVERAGE', user.theme),
                value: vehicle.capAverageValuation ? `${CURRENCY}${vehicle.capAverageValuation.toLocaleString()}` : '-',
            },
            priceEnd: {
                title: lang('LABEL_CAP_BELOW', user.theme),
                value: vehicle.capBelowValuation ? `${CURRENCY}${vehicle.capBelowValuation.toLocaleString()}` : '-',
            },
        }
    }
    if (vehicle.cardType === CT_SECURE_BANK_TRUST_FUNDING) {
        return {
            priceStart: {
                title: lang('LABEL_HAMMER_PRICE', user.theme),
                value: vehicle.hammerPrice ? `${CURRENCY}${vehicle.hammerPrice.toLocaleString()}` : '-',
            },
        }
    }

    if (vehicle.cardType === CT_CATALOGUE) {
        return {
            priceStart: {
                title: 'CAP (clean)',
                value: vehicle.capCleanPrice ? `${CURRENCY}${vehicle.capCleanPrice.toLocaleString()}` : '-',
            },
            priceMiddle: {
                title: 'CAP (average)',
                value: vehicle.capAveragePrice ? `${CURRENCY}${vehicle.capAveragePrice.toLocaleString()}` : '-',
            },
            priceEnd: {
                title: 'A/T Retail',
                value: vehicle.deltapointRetailPrice
                    ? `${CURRENCY}${vehicle.deltapointRetailPrice.toLocaleString()}`
                    : '-',
            },
        }
    }
    if (user.eXchangeBuyer && vehicle.cardType === CT_EXCHANGE) {
        if (vehicle.biddingDetails && (vehicle.biddingDetails.reserveMet || vehicle.biddingDetails.ended)) {
            return {
                priceStart: {
                    title: `${getPriceText(vehicle, user.theme)} ${getReserveStatus(vehicle)}`,
                    value:
                        vehicle.biddingDetails.noOfBids > 0 ? (
                            <React.Fragment>
                                {`${CURRENCY}${vehicle.biddingDetails.currentBid.toLocaleString()}`}{' '}
                                {getBiddingStatusColour(vehicle, user.theme)}
                            </React.Fragment>
                        ) : (
                            `${CURRENCY}${vehicle.biddingDetails.startingPrice.toLocaleString()}`
                        ),
                },
            }
        }
        return {
            priceStart: {
                title: `${getPriceText(vehicle, user.theme)} ${getReserveStatus(vehicle)}`,
                value:
                    vehicle.biddingDetails.noOfBids > 0 ? (
                        <React.Fragment>
                            {`${CURRENCY}${vehicle.biddingDetails.currentBid.toLocaleString()}`}{' '}
                            {getBiddingStatusColour(vehicle, user.theme)}
                        </React.Fragment>
                    ) : (
                        `${CURRENCY}${vehicle.biddingDetails.startingPrice.toLocaleString()}`
                    ),
            },
            priceEnd: {
                title: lang('LABEL_BUY_NOW_PRICE', user.theme),
                value: vehicle.biddingDetails.buyNowPrice
                    ? `${CURRENCY}${vehicle.biddingDetails.buyNowPrice.toLocaleString()}`
                    : '-',
            },
        }
    }
    return {}
}

const vehicleCardRender = (props) => {
    const {
        user,
        vehicle,
        onClickOpenProxyBidModal,
        onClickSetProxyBid,
        onClickOpenBuyNowModal,
        onClickBiddingModal,
        onClickWatch,
        onOpenAddToEXchangeModal,
        type,
        viewType,
        websocketConnected,
        onClickFundVehicle,
        accountCode,
        getClosedGroup,
    } = props

    const rowData = cardConfig(vehicle, user)
    return (
        <div
            className={`col-12 m-0 p-0 card ab-card-search-results ${
                vehicle.virtual && vehicle.cardType !== CT_CATALOGUE ? 'ab-virtual' : ''
            } ${vehicle.cardType}`}
        >
            <div className="card-header py-1 pl-2 pr-1">
                <div className="row m-0 p-0">
                    <CatalogueHeader vehicle={vehicle} theme={user.theme} />
                </div>
            </div>
            <div className="card-body m-0 p-0">
                <div className="row m-0 p-0">
                    <Banner vehicle={vehicle} user={user} websocketConnected={websocketConnected} type={type} />
                    <VehicleImage
                        user={user}
                        vehicle={vehicle}
                        onClickWatch={onClickWatch}
                        cardType={vehicle.cardType}
                        viewType={viewType}
                        websocketConnected={websocketConnected}
                    />
                    {hasTradeAccess(user) && (
                        <div className="px-2 ab-virtual-location">
                            <Prices
                                vehicle={vehicle}
                                websocketConnected={websocketConnected}
                                prices={getPrices(vehicle, user)}
                            />
                        </div>
                    )}
                    {!user.username && (
                        <div className="not-signed-in not-signed-in-label col-12 m-0 p-1">
                            <IoIosLock size="1.25em" className="icon" /> You are not signed in
                        </div>
                    )}
                    <div className="col-12 m-0 p-1">
                        <div className={`ab-card-details ${vehicle.cardType}`}>
                            <Hover
                                onHover={
                                    <VehicleRemarks
                                        vehicle={vehicle}
                                        isSecureBank={vehicle.cardType === CT_SECURE_BANK_TRUST_FUNDING}
                                        theme={user.theme}
                                    />
                                }
                            >
                                <div className="ab-results-stats">
                                    <div className="card-grid">
                                        {rowData[vehicle.cardType].map((item, i) => {
                                            const Module = item.component
                                            if (item.enabled) {
                                                return (
                                                    <Module
                                                        key={i}
                                                        label={item.label}
                                                        value={item.value}
                                                        vehicle={item.vehicle}
                                                        icon={item.icon}
                                                        class={item.class}
                                                        subPath={props.match.params.subPath}
                                                        disableValidation={item.disableValidation}
                                                        user={item.user}
                                                    />
                                                )
                                            }
                                            return null
                                        })}
                                    </div>
                                </div>
                            </Hover>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                {vehicle.cardType !== CT_SECURE_BANK_TRUST_FUNDING && (
                    <BidActionButtons
                        user={user}
                        vehicle={vehicle}
                        onClickOpenProxyBidModal={onClickOpenProxyBidModal}
                        onClickSetProxyBid={onClickSetProxyBid}
                        onClickOpenBuyNowModal={onClickOpenBuyNowModal}
                        onClickBiddingModal={onClickBiddingModal}
                        type={type}
                        websocketConnected={websocketConnected}
                        onOpenSendToAuctionModal={props.onOpenSendToAuctionModal}
                        toggleStatus={props.toggleStatus}
                        accountCode={vehicle.vendorCode}
                    />
                )}

                {vehicle.cardType === CT_SECURE_BANK_TRUST_FUNDING && (
                    <SecureBankTrustActionButton
                        user={user}
                        vehicle={vehicle}
                        type={type}
                        onClickFundVehicle={onClickFundVehicle}
                    />
                )}
                {user.accountsAccess &&
                    user.accountsAccess[vehicle.vendorCode] &&
                    user.accountsAccess[vehicle.vendorCode].eXchangeSeller &&
                    vehicle.cardType === CT_EVALUATE &&
                    vehicle.status &&
                    vehicle.status === 'In stock' &&
                    (vehicle.actions.addToExchange || vehicle.actions.relistOnExchange) && (
                        <div className="col-6 m-0 p-2">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                                type="button"
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    if (checkFormEligibility(vehicle)) {
                                        getClosedGroup(vehicle.vendorCode)
                                        openAddToExchangeModal(onOpenAddToEXchangeModal, user, vehicle, accountCode)
                                    }
                                }}
                            >
                                {vehicle.actions.addToExchange && lang('BUTTON_ADD_TO_EXCHANGE')}
                                {vehicle.actions.relistOnExchange && lang('BUTTON_RELIST_E-XCHANGE')}
                            </button>
                        </div>
                    )}
                {user.accountsAccess &&
                    user.accountsAccess[accountCode] &&
                    vehicle.cardType === CT_ARCHIVED &&
                    vehicle.actions.undoArchive && (
                        <div className="col-12 m-0 p-2">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                                type="button"
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    confirm(lang('ARE_YOU_SURE', user.theme), {
                                        buttonConfirm: lang('YES', user.theme),
                                        buttonDismiss: lang('NO', user.theme),
                                        description: lang('PROCEED_UNDO_ARCHIVE', user.theme),
                                    }).then(
                                        () => {
                                            return props.toggleStatus('Restored', vehicle.id, accountCode)
                                        },
                                        () => {
                                            return false
                                        },
                                    )
                                }}
                            >
                                {lang('UNDO_ARCHIVE')}
                            </button>
                        </div>
                    )}
            </div>
        </div>
    )
}
class VehicleCard extends Component {
    render = () => {
        const { vehicle, path, subPath, user } = this.props
        const isNotSecureBank = !isSecureBankTrust(vehicle.cardType)
        if (isNotSecureBank) {
            return (
                <Link
                    to={gotoVehicleDetails(vehicle, this.props, path, subPath)}
                    className={`col-12 col-sm-12 col-md-6 col-lg-4 mx-0 my-3 my-md-1 px-3 px-md-1 py-0 vehicle-card-link ${
                        getLocation(vehicle) === 'e-Live' ? APP_EHUB : ''
                    } ${vehicle.cardType} ${user.loggedIn ? '' : 'not-logged-in'}`}
                    data-vehicle-id={vehicle.id}
                >
                    {vehicleCardRender(this.props)}
                </Link>
            )
        } else {
            return (
                <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 mx-0 my-3 my-md-1 px-3 px-md-1 py-0 vehicle-card-link"
                    data-vehicle-id={vehicle.id}
                >
                    {vehicleCardRender(this.props)}
                </div>
            )
        }
    }
}

export default withRouter(VehicleCard)
