import React, { Component } from 'react'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import queryString from 'query-string'

import { lang, setTitle } from '../../themeConfig/'
import Pagination from '../Pagination'
import Search from '../Search'
import SearchResults from '../SearchResults'

import Sorting from '../../UiKit/Sorting'
import PageSize from '../../UiKit/PageSize'
import Download from '../Download'

import { CT_EXCHANGE, SALE_DAYS } from '../../constants'

const override = css`
    display: block;
    margin: 0 auto;
`

class Catalogue extends Component {
    constructor(props) {
        super(props)
        this.toggleView = this.toggleView.bind(this)
        this.state = {
            viewType: 'grid',
            totalVehicles: 0,
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0)

        setTitle('Vehicle Search', this.props.user.theme)

        const validKeys = [
            'savedsearch',
            'theme',
            'language',
            'searchId',
            'sales',
            'sites',
            'exchange',
            'e-xchange',
            'buynow',
            'saleDay',
        ]

        // Make sure we filter out any values that are not valid. This makes it harder to break the search by entering literally anything as a query string
        const queryValues = Object.fromEntries(
            Object.entries(queryString.parse(this.props.location.search)).filter(([key]) => validKeys.includes(key)),
        )

        if (Object.entries(queryValues).length) {
            const searchCriteria = {}
            if (queryValues.sites) {
                searchCriteria['sites'] = Array.isArray(queryValues.sites) ? queryValues.sites : [queryValues.sites]
            }

            if (queryValues.sales) {
                searchCriteria['sales'] = (
                    Array.isArray(queryValues.sales) ? queryValues.sales : [queryValues.sales]
                ).map((value) => parseInt(value))
            }

            if (queryValues.saleDay) {
                // Use a set to avoid duplicates
                searchCriteria['saleDays'] = Array.from(
                    new Set(
                        (Array.isArray(queryValues.saleDay) ? queryValues.saleDay : [queryValues.saleDay])
                            .map((value) => value.toLowerCase())
                            .filter((value) => SALE_DAYS.includes(value)),
                    ),
                )
            }

            if (Object.entries(searchCriteria).length) {
                await this.props.setSearchCriteria(searchCriteria)
                this.load(searchCriteria)
            }

            if (queryValues.savedsearch) {
                return this.load(this.props.criteria)
            }

            if (queryValues.theme || queryValues.language) {
                return this.load(this.props.criteria)
            }

            if (queryValues.searchId) {
                await this.props.loadMarketingSearch(queryValues.searchId)
            }

            if (queryValues.exchange || queryValues['e-xchange']) {
                await this.props.onClickExchangeOnly(true, this.props.pagination.resultsPerPage.value)
            }

            if (queryValues.buynow) {
                await this.props.onClickBuyNowOnly(true, this.props.pagination.resultsPerPage.value)
            }

            return
        }

        this.load(this.props.criteria)
    }

    load(criteria) {
        this.props.getVehicles(criteria, this.props.sorting)
        this.props.getSites(criteria)
        this.props.getSales(criteria)
        this.props.getSaleDays(criteria)
        this.props.getMakes(criteria)
        this.props.getEngineSizes(criteria)
        this.props.getTransmissions(criteria)
        this.props.getDoors(criteria)
        this.props.getAges(criteria)
        this.props.getMileages(criteria)
        this.props.getBodyTypes(criteria)
        this.props.getVehicleTypes(criteria)
        this.props.getFuelTypes(criteria)
        this.props.getSellers(criteria)
        this.props.getWriteOff(criteria)
        this.props.getNama(criteria)
        this.props.getNonRunner(criteria)
        this.props.getEuroStatus(criteria)
    }

    toggleView(viewType) {
        return this.setState({ viewType })
    }

    updateCount = (count) => {
        this.setState({ totalVehicles: count })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container p-0">
                    <div className="row mx-0">
                        {/* <!-- AB LEFT COLUMN --> */}
                        <div className="col-lg-2 col-md-3 p-3 pl-md-0 pt-md-2 pr-md-0 pb-md-3 ab-results-left">
                            <div className="row mx-0 my-2 d-block d-md-none">
                                <div className="col p-sm-0 p-md-1 ab-results-matches">
                                    <p className="p-0 m-0 mb-2 ab-results-number">
                                        {lang('CATALOGUE_VEHICLE_SEARCH_TITLE', this.props.user.theme)}
                                    </p>
                                    {!this.props.vehicles.loading ? (
                                        <p className="p-0 m-0 ab-results-number">{`${this.state.totalVehicles} ${
                                            this.state.totalVehicles > 1
                                                ? lang('CATALOGUE_VEHICLE_SEARCH_MATCHES_TEXT', this.props.user.theme)
                                                : lang('CATALOGUE_VEHICLE_SEARCH_MATCH_TEXT', this.props.user.theme)
                                        }`}</p>
                                    ) : (
                                        <p className="p-0 m-0 ab-results-number">&nbsp;</p>
                                    )}
                                </div>
                            </div>
                            <Search />
                        </div>

                        {/* <!-- AB RIGHT COLUMN --> */}
                        <div className="col-lg-10 col-md-9 m-0 p-0 p-md-2 ab-results-right d-flex flex-column footer-margin">
                            <div className="row m-0 p-3 pb-0 pt-4 d-none d-md-flex">
                                <div className="col x-0 p-0">
                                    <h2 className="p-0 m-0">
                                        {lang('CATALOGUE_VEHICLE_SEARCH_TITLE', this.props.user.theme)}
                                        {!this.props.vehicles.loading && (
                                            <span>
                                                {` - ${this.state.totalVehicles} ${
                                                    this.state.totalVehicles > 1
                                                        ? lang(
                                                              'CATALOGUE_VEHICLE_SEARCH_MATCHES_TEXT',
                                                              this.props.user.theme,
                                                          )
                                                        : lang(
                                                              'CATALOGUE_VEHICLE_SEARCH_MATCH_TEXT',
                                                              this.props.user.theme,
                                                          )
                                                }`}
                                            </span>
                                        )}
                                    </h2>
                                </div>
                                <Download />
                                <div className="col ab-h4 ml-2 px-0 text-right ab-results-per-page d-none" />
                            </div>

                            {!this.props.vehicles.loading && (
                                <div className="row mx-0 mt-3 mb-0 my-md-3 px-3 px-md-1 py-0">
                                    <div className="col-md-3 col-lg-2 mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0">
                                        <Sorting
                                            sorting={this.props.sorting}
                                            options="catalogue"
                                            onChangeSortOrder={this.props.onChangeSortOrder}
                                            offsetFinish={this.props.pagination.resultsPerPage.value}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-8 mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                                        <Pagination
                                            vehicles={
                                                this.props.criteria.eXchangeOnly
                                                    ? this.props.vehicles.data.filter(
                                                          (item) => item.cardType === CT_EXCHANGE,
                                                      )
                                                    : this.props.vehicles.data
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3 col-lg-2 mb-3 mb-md-0 px-0 pr-md-0 text-center text-md-right ab-results-per-page order-1 order-md-2">
                                        <PageSize
                                            vehicles={
                                                this.props.criteria.eXchangeOnly
                                                    ? this.props.vehicles.data.filter(
                                                          (item) => item.cardType === CT_EXCHANGE,
                                                      )
                                                    : this.props.vehicles.data
                                            }
                                            onToggleView={this.toggleView}
                                            viewType={this.state.viewType}
                                            pagination={this.props.pagination}
                                            setPageSize={this.props.setPageSize}
                                            theme={this.props.user.theme}
                                        />
                                    </div>
                                </div>
                            )}
                            {/* <!-- AB CARD RESULTS GRID --> */}
                            <div className="row mx-0 my-0">
                                {this.props.vehicles.loading ? (
                                    <div className="col-12 px-2">
                                        <div className="loading-wrapper">
                                            <ScaleLoader className={override} size={10} loading={true} />
                                        </div>
                                    </div>
                                ) : (
                                    <SearchResults viewType={'grid'} updateCount={this.updateCount} />
                                )}
                            </div>
                            {!this.props.vehicles.loading && (
                                <div className="row mx-0 mb-0 my-md-3 px-3 px-md-1 py-0">
                                    <div className="col-md-3 mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0" />
                                    <div className="col-md-6 mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                                        <Pagination
                                            vehicles={
                                                this.props.criteria.eXchangeOnly
                                                    ? this.props.vehicles.data.filter(
                                                          (item) => item.cardType === CT_EXCHANGE,
                                                      )
                                                    : this.props.vehicles.data
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Catalogue
