import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input, Modal, ModalBody, ModalHeader, Nav, NavLink, TabContent, NavItem, TabPane } from 'reactstrap'
import { SearchFields } from '../../constants/Search'
import { renderButtonContent } from '../../utilities/Functions'
import { onCloseSaveSearchModal, onOpenSaveSearchModal } from '../../actions/modals'
import { VehicleStatsRow } from '../CatalogueVehicleDetails/VehicleHelper'
import { onSaveSearchCriteria } from '../../actions/user'
import { lang } from '../../themeConfig'
import UserSearch from '../MyAccount/SavedSearches/UserSearch'
import classNames from 'classnames'

class SaveSearchModal extends Component {
    constructor(props) {
        super(props)
        this.handleOnSaveSearch = this.handleOnSaveSearch.bind(this)
        this.onChangeSaveSearch = this.onChangeSaveSearch.bind(this)
        this.state = {
            searchTitle: '',
            data: [],
            criteria: {},
            activeTab: '1',
            selectedUsername: '',
            selectedEmail: '',
        }
    }

    componentDidMount() {
        const data = this.props.criteriaData.data
        const criteria = this.props.criteria
        this.setState({
            data: data,
            user: this.props.user,
            criteria,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            user: nextProps.user,
            data: nextProps.criteriaData,
            criteria: nextProps.criteria,
        })
    }

    handleOnSaveSearch(description, isMarketing, selectedUsername, selectedEmail) {
        this.setState({ searchTitle: '' })
        const saveSearchObject = Object.assign({}, { description, search: this.props.criteria })
        this.props.onSaveSearchCriteria(saveSearchObject, isMarketing, selectedUsername, selectedEmail)
        window.dataLayer && window.dataLayer.push({
            'event': 'Save search',
        })
        this.props.onCloseSaveSearchModal()
    }

    onChangeSaveSearch(evt) {
        const searchTitle = evt.target.value
        this.setState({ searchTitle })
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    render() {
        const { criteria, data, user } = this.state
        const vehicles = []
        if (criteria && criteria['eXchangeOnly']) {
            vehicles.push(lang('CATALOGUE_CHECKBOX_EXCHANGE_ONLY', this.props.user.theme))
        }
        if (criteria && criteria['buyNow']) {
            vehicles.push(lang('CATALOGUE_CHECKBOX_BUY_NOW_ONLY', this.props.user.theme))
        }
        return (
            <Modal
                isOpen={this.props.saveSearchModal.isOpen}
                toggle={() => {
                    if (this.props.saveSearchModal.isOpen) {
                        return this.props.onCloseSaveSearchModal(!this.props.saveSearchModal.isOpen)
                    }
                    return this.props.onOpenSaveSearchModal(!this.props.saveSearchModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-lg ab-proxy-bid-modal"
            >
                <ModalHeader>
                    {lang('CATALOGUE_SAVE_SEARCH_BUTTON_TEXT', this.props.user.theme)}
                    <button
                        type="button"
                        className="close float-right"
                        aria-label={lang('BUTTON_CLOSE', this.props.user.theme)}
                        onClick={() => {
                            if (this.props.saveSearchModal.isOpen) {
                                return this.props.onCloseSaveSearchModal(!this.props.saveSearchModal.isOpen)
                            }
                            return this.props.onOpenSaveSearchModal(!this.props.saveSearchModal.isOpen)
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="row m-0 p-0">
                            <div className="col-12 col-md-6 pl-0 b-r-d">
                                <div className="form-group">
                                    <label htmlFor="abSaveSearch" style={{ marginBottom: '1px' }}>
                                        {lang('LABEL_SEARCH_TITLE', this.props.user.theme)}
                                    </label>
                                    <div className="input-control">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="abSaveSearch"
                                            placeholder="Enter your search title"
                                            value={this.state.searchTitle}
                                            onChange={(evt) => this.onChangeSaveSearch(evt)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                    this.handleOnSaveSearch(
                                                        this.state.searchTitle,
                                                        user.marketing,
                                                        this.state.selectedUsername,
                                                        this.state.selectedEmail,
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary btn-block ab-btn-padding"
                                    type="button"
                                    disabled={this.state.searchTitle === ''}
                                    onClick={() =>
                                        this.handleOnSaveSearch(
                                            this.state.searchTitle,
                                            user.marketing,
                                            this.state.selectedUsername,
                                            this.state.selectedEmail,
                                        )
                                    }
                                >
                                    {lang('BUTTON_SAVE_SEARCH_TEXT', this.props.user.theme)}
                                </button>
                                {this.state.selectedUsername.length > 0 && (
                                    <p className="save-user-note">
                                        Saving to {this.state.selectedUsername}.{' '}
                                        <a
                                            className="save-user-clear"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    selectedUsername: '',
                                                    selectedEmail: '',
                                                })
                                            }}
                                        >
                                            Clear
                                        </a>
                                        .
                                    </p>
                                )}
                            </div>
                            {criteria && data && this.props.user && this.props.user.theme && (
                                <div className="col-12 col-md-6  pr-0 ab-catelogue-vehicle-details-tabs-container">
                                    <table className="table m-0 p-0">
                                        <tbody>
                                            {SearchFields(this.props.user.theme).map((item, _i) => {
                                                const criteriaData = data[item.dataType] ? data[item.dataType].data : []
                                                const criteriaSelections = criteria[item.dataType]
                                                    ? criteria[item.dataType]
                                                    : []
                                                const props = {
                                                    dataType: item.dataType,
                                                    defaultText: item.defaultText,
                                                    jsonType: item.jsonType,
                                                    data: criteriaData,
                                                    criteria: criteriaSelections,
                                                    inputType: item.type,
                                                }
                                                if (
                                                    criteriaSelections.length > 0 ||
                                                    Object.keys(criteriaSelections).length > 0
                                                ) {
                                                    vehicles.push(item.defaultText)
                                                    return (
                                                        <VehicleStatsRow
                                                            key={item.defaultText}
                                                            label={item.defaultText}
                                                            value={renderButtonContent(props)}
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                            {criteria && criteria['eXchangeOnly'] && (
                                                <VehicleStatsRow
                                                    key={lang(
                                                        'CATALOGUE_CHECKBOX_EXCHANGE_ONLY',
                                                        this.props.user.theme,
                                                    )}
                                                    label={lang(
                                                        'CATALOGUE_CHECKBOX_EXCHANGE_ONLY',
                                                        this.props.user.theme,
                                                    )}
                                                    value={' '}
                                                />
                                            )}
                                            {criteria && criteria['buyNow'] && (
                                                <VehicleStatsRow
                                                    key={lang('CATALOGUE_CHECKBOX_BUY_NOW_ONLY', this.props.user.theme)}
                                                    label={lang(
                                                        'CATALOGUE_CHECKBOX_BUY_NOW_ONLY',
                                                        this.props.user.theme,
                                                    )}
                                                    value={' '}
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                    {vehicles.length === 0 && (
                                        <p>{lang('NO_FILTERS_APPLIED', this.props.user.theme)}</p>
                                    )}
                                </div>
                            )}
                        </div>
                        {this.props.user.marketing && (
                            <>
                                <h3 className="search-spacer">Save To Account</h3>
                                <Nav tabs className="search-tabs">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({ active: this.state.activeTab === '1' })}
                                            onClick={() => {
                                                this.toggleTab('1')
                                            }}
                                        >
                                            Search
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({ active: this.state.activeTab === '2' })}
                                            onClick={() => {
                                                this.toggleTab('2')
                                            }}
                                        >
                                            Manual Entry
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <UserSearch
                                            onSelection={(username, email) => {
                                                this.setState({
                                                    selectedUsername: username,
                                                    selectedEmail: email,
                                                })
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="saved-search-input-group">
                                            <Input
                                                type="text"
                                                className="search-user-input"
                                                value={this.state.selectedUsername}
                                                aria-label={'Enter account username'}
                                                placeholder={'Enter account username'}
                                                onChange={(e) => this.setState({ selectedUsername: e.target.value })}
                                            />
                                            <Input
                                                type="text"
                                                className="search-user-input-right"
                                                value={this.state.selectedEmail}
                                                aria-label={'Enter account email'}
                                                placeholder={'Enter account email'}
                                                onChange={(e) => this.setState({ selectedEmail: e.target.value })}
                                            />
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </>
                        )}
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    saveSearchModal: state.modals.saveSearch,
    criteria: state.criteria,
    criteriaData: state.criteriaData,
})

const mapDispatchToProps = {
    onOpenSaveSearchModal,
    onCloseSaveSearchModal,
    onSaveSearchCriteria,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveSearchModal))
