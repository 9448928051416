import 'isomorphic-fetch' // A polyfill for 'fetch'.
import React from 'react'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'

import App from './App'

import './styles/sass/styles.scss'

import { fetchProfileConfig } from './actions/profileconfig'

import { ErrorBoundary } from './bugsnag'

const disableReactDevTools = () => {
    const noop = () => undefined
    const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__

    if (typeof DEV_TOOLS === 'object') {
        for (const [key, value] of Object.entries(DEV_TOOLS)) {
            DEV_TOOLS[key] = typeof value === 'function' ? noop : null
        }
    }
}
if (import.meta.env.VITE_NODE_ENV !== 'development') {
    disableReactDevTools()
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    </React.StrictMode>,
)

store.dispatch(fetchProfileConfig())
