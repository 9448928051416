import { lang, permissions } from '../themeConfig'

export function SearchFields(theme) {
    return [
        {
            dataType: 'sites',
            defaultText: 'Auction Centres',
            type: 'checkbox',
            jsonType: 'option-list',
            enabled: permissions('CATALOGUE_FILTER_SITES', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'saleDays',
            defaultText: 'Sale Day',
            type: 'checkbox',
            jsonType: 'option-list',
            enabled: permissions('CATALOGUE_FILTER_SITES', theme), // Not strictly the correct permission.
            hasTabs: false,
            auth: false,
            OnlyVisibleWhenCriteriaSet: true, // Allows us to display the filter if the value is set in the url
        },
        {
            dataType: 'sales',
            defaultText: 'Sale',
            type: 'checkbox',
            hasTabs: true,
            enabled: permissions('CATALOGUE_FILTER_SALES', theme),
            auth: false,
        },
        {
            dataType: 'makes',
            defaultText: 'Make',
            type: 'checkbox',
            hasTabs: false,
            enabled: permissions('CATALOGUE_FILTER_MAKES', theme),
            auth: false,
        },
        {
            dataType: 'models',
            defaultText: 'Model',
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_MODELS', theme),
            hasTabs: true,
            dependantDataType: 'makes',
            auth: false,
        },
        {
            dataType: 'modelVariants',
            defaultText: 'Model Variant',
            type: 'checkbox',
            hasTabs: true,
            enabled: permissions('CATALOGUE_FILTER_MODELVARIANTS', theme),
            dependantDataType: 'models',
            auth: false,
        },
        {
            dataType: 'engineSize',
            defaultText: 'Engine size',
            type: 'rangeSelect',
            enabled: permissions('CATALOGUE_FILTER_ENGINESIZE', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'transmission',
            defaultText: 'Transmission',
            type: 'radio',
            enabled: permissions('CATALOGUE_FILTER_TRANSMISSION', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'doors',
            defaultText: 'Doors',
            type: 'checkbox',
            hasTabs: false,
            enabled: permissions('CATALOGUE_FILTER_DOORS', theme),
            auth: false,
        },
        {
            dataType: 'age',
            defaultText: 'Age',
            type: 'rangeSelect',
            hasTabs: false,
            enabled: permissions('CATALOGUE_FILTER_AGE', theme),
            auth: false,
        },
        {
            dataType: 'mileage',
            defaultText: 'Mileage',
            type: 'rangeSelect',
            enabled: permissions('CATALOGUE_FILTER_MILEAGE', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'bodyTypes',
            defaultText: 'Body type',
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_BODYTYPES', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'vehicleTypes',
            defaultText: 'Vehicle type',
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_VEHICLETYPES', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'fuelTypes',
            defaultText: 'Fuel type',
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_FUELTYPES', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'sellers',
            defaultText: 'Sellers',
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_SELLERS', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'nama',
            defaultText: lang('LABEL_NAMA_GRADE', theme),
            type: 'rangeSelect',
            enabled: permissions('CATALOGUE_FILTER_NAMA', theme),
            hasTabs: false,
            auth: true,
        },
        {
            dataType: 'writeOff',
            defaultText: lang('LABEL_INSURANCE_WRITE_OFF', theme),
            type: 'radio',
            jsonType: 'option-list',
            enabled: permissions('CATALOGUE_FILTER_WRITEOFF', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'nonRunner',
            defaultText: lang('LABEL_NONRUNNER', theme),
            type: 'radio',
            jsonType: 'option-list',
            enabled: permissions('CATALOGUE_FILTER_NONRUNNER', theme),
            hasTabs: false,
            auth: false,
        },
        {
            dataType: 'euroStatus',
            defaultText: lang('LABEL_EURO_STATUS', theme),
            type: 'checkbox',
            enabled: permissions('CATALOGUE_FILTER_EURO_STATUS', theme),
            hasTabs: false,
            auth: false,
        },
    ].filter((i) => i.enabled)
}
