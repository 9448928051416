import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Accordion from './Criteria'
import { handleOnSetCriteria, handleOnSetRangeCriteria, resetCriteriaFilter } from '../../actions/criteria'
import { getVehicles } from '../../actions/vehicles'
import {
    getModels,
    getSites,
    getSales,
    getSaleDays,
    getMakes,
    getModelVariants,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
} from '../../actions/criteriaData'

const mapStateToProps = (state) => ({
    criteriaData: state.criteriaData,
    criteria: state.criteria,
    sorting: state.sorting,
    pagination: state.pagination,
    user: state.user,
})

const mapDispatchToProps = {
    handleOnSetCriteria,
    handleOnSetRangeCriteria,
    getModels,
    getVehicles,
    getSites,
    getSales,
    getSaleDays,
    getMakes,
    getModelVariants,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
    resetCriteriaFilter,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accordion))
