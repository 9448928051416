import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { onClickSendToAuction } from '../../actions/ehub'
import { onCloseSendToAuctionModal, onOpenSendToAuctionModal } from '../../actions/modals'
import { CURRENCY } from '../../constants'
import { lang } from '../../themeConfig'

class SendToAuctionModal extends Component {
    constructor(props) {
        super(props)
        this.onChangeReservePrice = this.onChangeReservePrice.bind(this)
        this.onChangeSite = this.onChangeSite.bind(this)
        this.state = {
            reservePrice: '',
            sivPrice: null,
            selectedCollection: null,
            collections: null,
            valid: true,
        }
    }

    componentDidMount() {
        this.setLocalState(this.props.sendToAuctionModal.data)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setLocalState(nextProps.sendToAuctionModal.data)
    }

    setLocalState = (data) => {
        this.setState({
            sivPrice: data.sivPrice ? data.sivPrice : '',
            selectedCollection: data.collectionSites ? data.collectionSites.selectedOption : null,
            collections: data.collectionSites ? data.collectionSites.options : null,
        })
    }

    onChangeReservePrice = (event) => {
        const amount = event.target.value
        const amountAsNumber = Number.parseInt(amount)

        this.setState({
            reservePrice: amountAsNumber,
        })
    }

    onChangeSivPrice = (event) => {
        const amount = event.target.value
        this.setState({
            sivPrice: amount,
        })
    }

    onChangeSite(selectedOption) {
        this.setState({
            collectionId: selectedOption.value,
            selectedCollection: selectedOption,
        })
    }

    keyAllowed(key) {
        var keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
        return !(key && keys.indexOf(key) === -1)
    }

    handleNegative(e) {
        var key = !isNaN(e.charCode) ? e.charCode : e.keyCode
        if (!this.keyAllowed(key)) e.preventDefault()
    }

    async handleSendToAuction(event) {
        event.preventDefault()
        event.stopPropagation()

        let payload = {
            vehicleId: this.props.sendToAuctionModal.data.vehicleId
                ? this.props.sendToAuctionModal.data.vehicleId
                : null,
            reserve: this.state.reservePrice,
            siv: parseInt(this.state.sivPrice, 10),
        }

        if (this.props.sendToAuctionModal.data.eXchangeType !== 'ListAndDeliver') {
            payload = Object.assign(payload, {
                addressId: this.state.selectedCollection ? this.state.selectedCollection.value : null,
            })
        }

        this.props.onClickSendToAuction(
            this.props.sendToAuctionModal.data.appraisalId,
            payload,
            this.props.ehub.appraisals.filter((a) => a.id === this.props.sendToAuctionModal.data.appraisalId)[0]
                .vendorCode,
            this.props.sendToAuctionModal.data.eXchangeType,
        )

        window.dataLayer && window.dataLayer.push({
            'event': 'Send to Auction',
        })
        this.setState({ sivPrice: null, reservPrice: null })
        this.props.onCloseSendToAuctionModal()
    }

    render() {
        const isInvalid =
            this.state.sivPrice === '0' ||
            isNaN(this.state.reservePrice) ||
            this.state.reservePrice === '' ||
            this.state.reservePrice === 0
        return (
            <Modal
                isOpen={this.props.sendToAuctionModal.isOpen}
                toggle={() => {
                    if (this.props.sendToAuctionModal.isOpen) {
                        return this.props.onCloseSendToAuctionModal(!this.props.sendToAuctionModal.isOpen)
                    }
                    return this.props.onOpenSendToAuctionModal(!this.props.sendToAuctionModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-md "
            >
                <ModalHeader
                    toggle={() => {
                        if (this.props.sendToAuctionModal.isOpen) {
                            return this.props.onCloseSendToAuctionModal(!this.props.sendToAuctionModal.isOpen)
                        }
                        return this.props.onOpenSendToAuctionModal(!this.props.sendToAuctionModal.isOpen)
                    }}
                >
                    {lang('SEND_TO_AUCTION_BUTTON_TEXT', this.props.user.theme)}
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="row m-0 p-0">
                            <div className="col-12  m-0 p-0">
                                <div className="form-group">
                                    {this.props.sendToAuctionModal.data.eXchangeType === 'ListAndDeliver' && (
                                        <>
                                            <label htmlFor="auctionsite">Aston barclay Site</label>
                                            <div className="input-group">
                                                {this.state.selectedCollection && (
                                                    <Select
                                                        isDisabled={false}
                                                        value={this.state.selectedCollection}
                                                        onChange={this.onChangeSite}
                                                        options={this.state.collections}
                                                        isSearchable={false}
                                                        classNamePrefix="ab-select"
                                                        className="custom-select-input prepend"
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0 pr-2">
                                <div className="form-group">
                                    <label htmlFor="sivPrice">{lang('LABEL_SIV', this.props.user.theme)}</label>
                                    <div className="input-group ab-prepend">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                {CURRENCY}
                                            </span>
                                        </div>
                                        <Input
                                            type="number"
                                            id="sivPrice"
                                            className="form-control"
                                            placeholder={lang('ENTER_SIV_PRICE', this.props.user.theme)}
                                            value={this.state.sivPrice ? this.state.sivPrice : ''}
                                            onChange={this.onChangeSivPrice}
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  m-0 p-0">
                                <div className="form-group">
                                    <label htmlFor="reservePrice">
                                        {lang('RESERVE_PRICE', this.props.user.theme)}{' '}
                                        <small style={{ color: 'red' }}>*</small>
                                    </label>
                                    <div className="input-group ab-prepend">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                {CURRENCY}
                                            </span>
                                        </div>
                                        <Input
                                            id="reservePrice"
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter reserve price"
                                            value={this.state.reservePrice ? this.state.reservePrice : ''}
                                            onChange={this.onChangeReservePrice}
                                            required
                                            onKeyPress={(event) => {
                                                if (event.target.value.length >= 9) event.preventDefault()
                                                this.handleNegative(event)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 m-0 p-0 pb-2">
                                <small>Site will confirm collection details within 2 working days</small>
                            </div>
                            <div className="col-12  m-0 p-0">
                                <button
                                    className={`${isInvalid ? 'disabled' : ''} btn btn-primary btn-block ab-btn-padding`}
                                    disabled={isInvalid}
                                    onClick={(event) => {
                                        this.handleSendToAuction(event)
                                    }}
                                >
                                    {lang('SEND_TO_AUCTION_BUTTON_TEXT', this.props.user.theme)}
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    ehub: state.ehub,
    user: state.user,
    sendToAuctionModal: state.modals.sendToAuction,
})

const mapDispatchToProps = {
    onOpenSendToAuctionModal,
    onCloseSendToAuctionModal,
    onClickSendToAuction,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendToAuctionModal))
