import {
    API_BASE,
    API_GET_HEADERS,
    FETCH_SITES,
    FETCH_SITES_SUCCESS,
    FETCH_SITES_ERROR,
    FETCH_SALES,
    FETCH_SALES_SUCCESS,
    FETCH_SALES_ERROR,
    FETCH_SALE_DAYS,
    FETCH_SALE_DAYS_SUCCESS,
    FETCH_SALE_DAYS_ERROR,
    FETCH_MAKES,
    FETCH_MAKES_SUCCESS,
    FETCH_MAKES_ERROR,
    FETCH_MODELS,
    FETCH_MODELS_SUCCESS,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_VARIANTS,
    FETCH_MODEL_VARIANTS_SUCCESS,
    FETCH_MODEL_VARIANTS_ERROR,
    FETCH_ENGINE_SIZES,
    FETCH_ENGINE_SIZES_SUCCESS,
    FETCH_ENGINE_SIZES_ERROR,
    FETCH_TRANSMISSIONS,
    FETCH_TRANSMISSIONS_SUCCESS,
    FETCH_TRANSMISSIONS_ERROR,
    FETCH_DOORS,
    FETCH_DOORS_SUCCESS,
    FETCH_DOORS_ERROR,
    FETCH_AGES,
    FETCH_AGES_SUCCESS,
    FETCH_AGES_ERROR,
    FETCH_MILEAGES,
    FETCH_MILEAGES_SUCCESS,
    FETCH_MILEAGES_ERROR,
    FETCH_BODY_TYPES,
    FETCH_BODY_TYPES_SUCCESS,
    FETCH_BODY_TYPES_ERROR,
    FETCH_VEHICLE_TYPES,
    FETCH_VEHICLE_TYPES_SUCCESS,
    FETCH_VEHICLE_TYPES_ERROR,
    FETCH_FUEL_TYPES,
    FETCH_FUEL_TYPES_SUCCESS,
    FETCH_FUEL_TYPES_ERROR,
    FETCH_NAMA,
    FETCH_NAMA_SUCCESS,
    FETCH_NAMA_ERROR,
    FETCH_SELLERS,
    FETCH_SELLERS_SUCCESS,
    FETCH_SELLERS_ERROR,
    FETCH_WRITE_OFF,
    FETCH_WRITE_OFF_SUCCESS,
    FETCH_WRITE_OFF_ERROR,
    FETCH_NON_RUNNER,
    FETCH_NON_RUNNER_SUCCESS,
    FETCH_NON_RUNNER_ERROR,
    FETCH_EURO_STATUS,
    FETCH_EURO_STATUS_SUCCESS,
    FETCH_EURO_STATUS_ERROR,
} from '../constants'

import { BuildSearchQueryForItem } from '../utilities/SearchQuery'

import { dispatchCall } from '../utilities/Functions'

export const fetchSitesBegin = () => ({
    type: FETCH_SITES,
})

export const fetchSitesSuccess = (sites) => ({
    type: FETCH_SITES_SUCCESS,
    payload: sites,
})

export const fetchSitesFailure = (error) => ({
    type: FETCH_SITES_ERROR,
    payload: { error },
})

export const fetchSalesBegin = () => ({
    type: FETCH_SALES,
})

export const fetchSalesSuccess = (sales) => ({
    type: FETCH_SALES_SUCCESS,
    payload: sales,
})

export const fetchSalesFailure = (error) => ({
    type: FETCH_SALES_ERROR,
    payload: { error },
})

// Sale days
export const fetchSaleDaysBegin = () => ({
    type: FETCH_SALE_DAYS,
})

export const fetchSaleDaysSuccess = (saleDays) => ({
    type: FETCH_SALE_DAYS_SUCCESS,
    payload: saleDays,
})

export const fetchSaleDaysFailure = (error) => ({
    type: FETCH_SALE_DAYS_ERROR,
    payload: { error },
})

export const fetchMakesBegin = () => ({
    type: FETCH_MAKES,
})

export const fetchMakesSuccess = (makes) => ({
    type: FETCH_MAKES_SUCCESS,
    payload: makes,
})

export const fetchMakesFailure = (error) => ({
    type: FETCH_MAKES_ERROR,
    payload: { error },
})

export const fetchModelsBegin = () => ({
    type: FETCH_MODELS,
})

export const fetchModelsSuccess = (models) => ({
    type: FETCH_MODELS_SUCCESS,
    payload: models,
})

export const fetchModelsFailure = (error) => ({
    type: FETCH_MODELS_ERROR,
    payload: { error },
})

export const fetchModelVariantsBegin = () => ({
    type: FETCH_MODEL_VARIANTS,
})

export const fetchModelVariantsSuccess = (modelVariants) => ({
    type: FETCH_MODEL_VARIANTS_SUCCESS,
    payload: modelVariants,
})

export const fetchModelVariantsFailure = (error) => ({
    type: FETCH_MODEL_VARIANTS_ERROR,
    payload: { error },
})

export const fetchEngineSizesBegin = () => ({
    type: FETCH_ENGINE_SIZES,
})

export const fetchEngineSizesSuccess = (enginesizes) => ({
    type: FETCH_ENGINE_SIZES_SUCCESS,
    payload: enginesizes,
})

export const fetchEngineSizesFailure = (error) => ({
    type: FETCH_ENGINE_SIZES_ERROR,
    payload: { error },
})

export const fetchTransmissionsBegin = () => ({
    type: FETCH_TRANSMISSIONS,
})

export const fetchTransmissionsSuccess = (transmissions) => ({
    type: FETCH_TRANSMISSIONS_SUCCESS,
    payload: transmissions,
})

export const fetchTransmissionsFailure = (error) => ({
    type: FETCH_TRANSMISSIONS_ERROR,
    payload: { error },
})

export const fetchDoorsBegin = () => ({
    type: FETCH_DOORS,
})

export const fetchDoorsSuccess = (doors) => ({
    type: FETCH_DOORS_SUCCESS,
    payload: doors,
})

export const fetchDoorsFailure = (error) => ({
    type: FETCH_DOORS_ERROR,
    payload: { error },
})

export const fetchAgesBegin = () => ({
    type: FETCH_AGES,
})

export const fetchAgesSuccess = (ages) => ({
    type: FETCH_AGES_SUCCESS,
    payload: ages,
})

export const fetchAgesFailure = (error) => ({
    type: FETCH_AGES_ERROR,
    payload: { error },
})

export const fetchMileagesBegin = () => ({
    type: FETCH_MILEAGES,
})

export const fetchMileagesSuccess = (mileages) => ({
    type: FETCH_MILEAGES_SUCCESS,
    payload: mileages,
})

export const fetchMileagesFailure = (error) => ({
    type: FETCH_MILEAGES_ERROR,
    payload: { error },
})

export const fetchBodyTypesBegin = () => ({
    type: FETCH_BODY_TYPES,
})

export const fetchBodyTypesSuccess = (bodytypes) => ({
    type: FETCH_BODY_TYPES_SUCCESS,
    payload: bodytypes,
})

export const fetchBodyTypesFailure = (error) => ({
    type: FETCH_BODY_TYPES_ERROR,
    payload: { error },
})

export const fetchVehicleTypesBegin = () => ({
    type: FETCH_VEHICLE_TYPES,
})

export const fetchVehicleTypesSuccess = (vehicletypes) => ({
    type: FETCH_VEHICLE_TYPES_SUCCESS,
    payload: vehicletypes,
})

export const fetchVehicleTypesFailure = (error) => ({
    type: FETCH_VEHICLE_TYPES_ERROR,
    payload: { error },
})

export const fetchFuelTypesBegin = () => ({
    type: FETCH_FUEL_TYPES,
})

export const fetchFuelTypesSuccess = (fuel) => ({
    type: FETCH_FUEL_TYPES_SUCCESS,
    payload: fuel,
})

export const fetchFuelTypesFailure = (error) => ({
    type: FETCH_FUEL_TYPES_ERROR,
    payload: { error },
})

export const fetchNamaBegin = () => ({
    type: FETCH_NAMA,
})

export const fetchNamaSuccess = (nama) => ({
    type: FETCH_NAMA_SUCCESS,
    payload: nama,
})

export const fetchNamaFailure = (error) => ({
    type: FETCH_NAMA_ERROR,
    payload: { error },
})

export const fetchSellersBegin = () => ({
    type: FETCH_SELLERS,
})

export const fetchSellersSuccess = (sellers) => ({
    type: FETCH_SELLERS_SUCCESS,
    payload: sellers,
})

export const fetchSellersFailure = (error) => ({
    type: FETCH_SELLERS_ERROR,
    payload: { error },
})

export const fetchWriteOffBegin = () => ({
    type: FETCH_WRITE_OFF,
})

export const fetchWriteOffSuccess = (sellers) => ({
    type: FETCH_WRITE_OFF_SUCCESS,
    payload: sellers,
})

export const fetchWriteOffFailure = (error) => ({
    type: FETCH_WRITE_OFF_ERROR,
    payload: { error },
})

export const fetchNonRunnerBegin = () => ({
    type: FETCH_NON_RUNNER,
})

export const fetchNonRunnerSuccess = (nonrunners) => ({
    type: FETCH_NON_RUNNER_SUCCESS,
    payload: nonrunners,
})

export const fetchNonRunnerFailure = (error) => ({
    type: FETCH_NON_RUNNER_ERROR,
    payload: { error },
})

export const fetchEuroStatusBegin = () => ({
    type: FETCH_EURO_STATUS,
})

export const fetchEuroStatusSuccess = (euroStatus) => ({
    type: FETCH_EURO_STATUS_SUCCESS,
    payload: euroStatus,
})

export const fetchEuroStatusFailure = (error) => ({
    type: FETCH_EURO_STATUS_ERROR,
    payload: { error },
})

export function getSites(criteria) {
    const url = `${API_BASE}api/criteria/sites${BuildSearchQueryForItem('sites', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchSitesBegin, fetchSitesSuccess, fetchSitesFailure)
    }
}

export function getSales(criteria) {
    const url = `${API_BASE}api/criteria/sales${BuildSearchQueryForItem('sales', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchSalesBegin, fetchSalesSuccess, fetchSalesFailure)
    }
}

export function getSaleDays(criteria) {
    const url = `${API_BASE}api/criteria/saledays${BuildSearchQueryForItem('saleDays', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchSaleDaysBegin, fetchSaleDaysSuccess, fetchSaleDaysFailure)
    }
}

export function getMakes(criteria) {
    const url = `${API_BASE}api/criteria/makes${BuildSearchQueryForItem('makes', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchMakesBegin, fetchMakesSuccess, fetchMakesFailure)
    }
}

export function getModels(criteria) {
    const url = `${API_BASE}api/criteria/models${BuildSearchQueryForItem('models', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchModelsBegin, fetchModelsSuccess, fetchModelsFailure)
    }
}

export function getModelVariants(criteria) {
    const url = `${API_BASE}api/criteria/derivatives${BuildSearchQueryForItem('modelVariants', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(
            dispatch,
            fetchDetails,
            fetchModelVariantsBegin,
            fetchModelVariantsSuccess,
            fetchModelVariantsFailure,
        )
    }
}

export function getEngineSizes(criteria) {
    const url = `${API_BASE}api/criteria/engines${BuildSearchQueryForItem('engineSize', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.2'),
        }
        dispatchCall(dispatch, fetchDetails, fetchEngineSizesBegin, fetchEngineSizesSuccess, fetchEngineSizesFailure)
    }
}

export function getTransmissions(criteria) {
    const url = `${API_BASE}api/criteria/transmissions${BuildSearchQueryForItem('transmission', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(
            dispatch,
            fetchDetails,
            fetchTransmissionsBegin,
            fetchTransmissionsSuccess,
            fetchTransmissionsFailure,
        )
    }
}

export function getDoors(criteria) {
    const url = `${API_BASE}api/criteria/doors${BuildSearchQueryForItem('doors', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchDoorsBegin, fetchDoorsSuccess, fetchDoorsFailure)
    }
}

export function getAges(criteria) {
    const url = `${API_BASE}api/criteria/ages${BuildSearchQueryForItem('age', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchAgesBegin, fetchAgesSuccess, fetchAgesFailure)
    }
}

export function getMileages(criteria) {
    const url = `${API_BASE}api/criteria/mileages${BuildSearchQueryForItem('mileage', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchMileagesBegin, fetchMileagesSuccess, fetchMileagesFailure)
    }
}

export function getBodyTypes(criteria) {
    const url = `${API_BASE}api/criteria/body-types${BuildSearchQueryForItem('bodyType', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchBodyTypesBegin, fetchBodyTypesSuccess, fetchBodyTypesFailure)
    }
}

export function getVehicleTypes(criteria) {
    const url = `${API_BASE}api/criteria/vehicle-types${BuildSearchQueryForItem('vehicleType', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchVehicleTypesBegin, fetchVehicleTypesSuccess, fetchVehicleTypesFailure)
    }
}

export function getFuelTypes(criteria) {
    const url = `${API_BASE}api/criteria/fuel-types${BuildSearchQueryForItem('fuelTypes', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchFuelTypesBegin, fetchFuelTypesSuccess, fetchFuelTypesFailure)
    }
}

export function getNama(criteria) {
    const url = `${API_BASE}api/criteria/nama${BuildSearchQueryForItem('nama', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchNamaBegin, fetchNamaSuccess, fetchNamaFailure)
    }
}

export function getSellers(criteria) {
    const url = `${API_BASE}api/criteria/sellers${BuildSearchQueryForItem('sellers', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchSellersBegin, fetchSellersSuccess, fetchSellersFailure)
    }
}

export function getWriteOff(criteria) {
    const url = `${API_BASE}api/criteria/writeoff${BuildSearchQueryForItem('writeoff', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchWriteOffBegin, fetchWriteOffSuccess, fetchWriteOffFailure)
    }
}

export function getNonRunner(criteria) {
    const url = `${API_BASE}api/criteria/nonrunner${BuildSearchQueryForItem('nonrunner', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, fetchNonRunnerBegin, fetchNonRunnerSuccess, fetchNonRunnerFailure)
    }
}

export function getEuroStatus(criteria) {
    const url = `${API_BASE}api/criteria/eurostatus${BuildSearchQueryForItem('eurostatus', criteria)}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, fetchEuroStatusBegin, fetchEuroStatusSuccess, fetchEuroStatusFailure)
    }
}
