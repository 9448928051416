export const API_BASE = (import.meta.env.VITE_CATALOGUE_URL && `${import.meta.env.VITE_CATALOGUE_URL}/`) || '/'

export const LAUNCH_DATE_MIN = '05/20/2019'
export const LAUNCH_DATE_MAX = '05/28/2019'

// move to navigation backend call, or include in index.html (global properties)

export const FORGOTTEN_PASSWORD_PATH = '/forgotten-password'

export const FALLBACK_CASCADE_LOGIN = 'https://astonbarclay.fmstage.co.uk/login'

export const MY_ACCOUNT_PATH = '/my-account'
export const MY_ACCOUNT_PROFILE_PATH = MY_ACCOUNT_PATH + '/profile'
export const MY_ACCOUNT_CHANGE_PASSWORD_PATH = MY_ACCOUNT_PATH + '/change-password'

// just because it's hardcoded in the JS, so I figured I might as well stick it here and then in the future we can pull
// it from the properties config file
export const CASCADE_MAIN_URL = 'https://cascadecars.astonbarclay.net/'

export const BUGSNAG_KEY = import.meta.env.VITE_BUGSNAG_KEY

export function API_GET_HEADERS(version) {
    return {
        ...REQUEST,
        method: 'GET',
        headers: getHeaders(version),
    }
}

export function getPdfHeaders(version) {
    return {
        'content-type': 'application/pdf',
        Accept: getAcceptValue(version),
        'Accept-Encoding': 'gzip',
        'Content-Encoding': 'gzip',
    }
}

export function API_POST_HEADERS(version) {
    return {
        ...REQUEST,
        method: 'POST',
        headers: getHeaders(version),
    }
}

export function API_PUT_HEADERS(version) {
    return {
        ...REQUEST,
        method: 'PUT',
        headers: getHeaders(version),
    }
}

export function API_PATCH_HEADERS(version) {
    return {
        ...REQUEST,
        method: 'PATCH',
        headers: getHeaders(version),
    }
}

export function API_DELETE_HEADERS(version) {
    return {
        ...REQUEST,
        method: 'DELETE',
        headers: getHeaders(version),
    }
}

function getAcceptValue(version) {
    return `application/${version ? `vnd.catalogue.app-v${version}+` : ``}json`
}

const REQUEST = {
    credentials: 'include',
}

function getHeaders(version) {
    return {
        'content-type': 'application/json; charset=utf-8',
        Accept: getAcceptValue(version),
        'Accept-Encoding': 'gzip',
        'Content-Encoding': 'gzip',
    }
}

export const ON_CHANGE_USER_ACCOUNT = 'ON_CHANGE_USER_ACCOUNT'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const LOGON = 'LOGON'
export const LOGON_SUCCESS = 'LOGON_SUCCESS'
export const LOGON_ERROR = 'LOGON_ERROR'

export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER'
export const CHANGE_SORT_BY = 'CHANGE_SORT_BY'
export const CHANGE_PAGINATION = 'CHANGE_PAGINATION'
export const SET_PAGINATION_PAGES = 'SET_PAGINATION_PAGES'
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE'
export const SORT_VEHICLES = 'SORT_VEHICLES'
export const FETCH_NAVIGATION = 'FETCH_NAVIGATION'
export const FETCH_NAVIGATION_SUCCESS = 'FETCH_NAVIGATION_SUCCESS'
export const FETCH_NAVIGATION_ERROR = 'FETCH_NAVIGATION_ERROR'

export const FETCH_PROXY_BID_VEHICLES = 'FETCH_PROXY_BID_VEHICLES'
export const FETCH_PROXY_BID_VEHICLES_SUCCESS = 'FETCH_PROXY_BID_VEHICLES_SUCCESS'
export const FETCH_PROXY_BID_VEHICLES_ERROR = 'FETCH_PROXY_BID_VEHICLES_ERROR'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const SAVE_SEARCH_BEGIN = 'SAVE_SEARCH_BEGIN'
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS'
export const SAVE_SEARCH_ERROR = 'SAVE_SEARCH_ERROR'

export const FETCH_WATCHED_VEHICLES = 'FETCH_WATCHED_VEHICLES'
export const FETCH_WATCHED_VEHICLES_SUCCESS = 'FETCH_WATCHED_VEHICLES_SUCCESS'
export const FETCH_WATCHED_VEHICLES_ERROR = 'FETCH_WATCHED_VEHICLES_ERROR'

export const FETCH_BID_BUY_NOW_VEHICLES = 'FETCH_BID_BUY_NOW_VEHICLES'
export const FETCH_BID_BUY_NOW_VEHICLES_SUCCESS = 'FETCH_BID_BUY_NOW_VEHICLES_SUCCESS'
export const FETCH_BID_BUY_NOW_VEHICLES_ERROR = 'FETCH_BID_BUY_NOW_VEHICLES_ERROR'

export const CLEAR_VEHICLE_STATE = 'CLEAR_VEHICLE_STATE'

export const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS'
export const FETCH_VEHICLE_DETAILS_SUCCESS = 'FETCH_VEHICLE_DETAILS_SUCCESS'
export const FETCH_VEHICLE_DETAILS_ERROR = 'FETCH_VEHICLE_DETAILS_ERROR'
export const FETCH_WATCH_VEHICLE = 'FETCH_WATCH_VEHICLE'
export const FETCH_WATCH_VEHICLE_SUCCESS = 'FETCH_WATCH_VEHICLE_SUCCESS'
export const FETCH_WATCH_VEHICLE_ERROR = 'FETCH_WATCH_VEHICLE_ERROR'
export const FETCH_UNWATCH_VEHICLE = 'FETCH_UNWATCH_VEHICLE'
export const FETCH_UNWATCH_VEHICLE_SUCCESS = 'FETCH_UNWATCH_VEHICLE_SUCCESS'
export const FETCH_UNWATCH_VEHICLE_ERROR = 'FETCH_UNWATCH_VEHICLE_ERROR'

export const RESET_SEARCH = 'RESET_SEARCH'
export const SET_CRITERIA = 'SET_CRITERIA'
export const CLEAR_CRITERIA = 'CLEAR_CRITERIA'
export const UNSET_CRITERIA = 'UNSET_CRITERIA'

export const FETCH_SAVED_SEARCHES = 'FETCH_SAVED_SEARCHES'
export const FETCH_SAVED_SEARCHES_SUCCESS = 'FETCH_SAVED_SEARCHES_SUCCESS'
export const FETCH_SAVED_SEARCHES_ERROR = 'FETCH_SAVED_SEARCHES_ERROR'

export const UPDATE_SEARCH = 'UPDATE_SEARCH'
export const UPDATE_SEARCH_SUCCESS = 'UPDATE_SEARCH_SUCCESS'
export const UPDATE_SEARCH_FAILURE = 'UPDATE_SEARCH_FAILURE'

export const ON_UNSUBSCRIBE = 'ON_UNSUBSCRIBE'
export const ON_UNSUBSCRIBE_SUCCESS = 'ON_UNSUBSCRIBE_SUCCESS'
export const ON_UNSUBSCRIBE_FAILURE = 'ON_UNSUBSCRIBE_FAILURE'

export const FETCH_DELETE_SAVED_SEARCHES = 'FETCH_DELETE_SAVED_SEARCHES'
export const FETCH_DELETE_SAVED_SEARCHES_SUCCESS = 'FETCH_DELETE_SAVED_SEARCHES_SUCCESS'
export const FETCH_DELETE_SAVED_SEARCHES_ERROR = 'FETCH_DELETE_SAVED_SEARCHES_ERROR'

export const FETCH_VEHICLES = 'FETCH_VEHICLES'
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const FETCH_VEHICLES_ERROR = 'FETCH_VEHICLES_ERROR'

export const FETCH_SITES = 'FETCH_SITES'
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS'
export const FETCH_SITES_ERROR = 'FETCH_SITES_ERROR'

export const FETCH_SALES = 'FETCH_SALES'
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS'
export const FETCH_SALES_ERROR = 'FETCH_SALES_ERROR'

export const FETCH_SALE_DAYS = 'FETCH_SALE_DAYS'
export const FETCH_SALE_DAYS_SUCCESS = 'FETCH_SALE_DAYS_SUCCESS'
export const FETCH_SALE_DAYS_ERROR = 'FETCH_SALE_DAYS_ERROR'

export const FETCH_MAKES = 'FETCH_MAKES'
export const FETCH_MAKES_SUCCESS = 'FETCH_MAKES_SUCCESS'
export const FETCH_MAKES_ERROR = 'FETCH_MAKES_ERROR'

export const FETCH_MODELS = 'FETCH_MODELS'
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS'
export const FETCH_MODELS_ERROR = 'FETCH_MODELS_ERROR'

export const FETCH_MODEL_VARIANTS = 'FETCH_MODEL_VARIANTS'
export const FETCH_MODEL_VARIANTS_SUCCESS = 'FETCH_MODEL_VARIANTS_SUCCESS'
export const FETCH_MODEL_VARIANTS_ERROR = 'FETCH_MODEL_VARIANTS_ERROR'

export const FETCH_ENGINE_SIZES = 'FETCH_ENGINE_SIZES'
export const FETCH_ENGINE_SIZES_SUCCESS = 'FETCH_ENGINE_SIZES_SUCCESS'
export const FETCH_ENGINE_SIZES_ERROR = 'FETCH_ENGINE_SIZES_ERROR'

export const FETCH_TRANSMISSIONS = 'FETCH_TRANSMISSIONS'
export const FETCH_TRANSMISSIONS_SUCCESS = 'FETCH_TRANSMISSIONS_SUCCESS'
export const FETCH_TRANSMISSIONS_ERROR = 'FETCH_TRANSMISSIONS_ERROR'

export const FETCH_DOORS = 'FETCH_DOORS'
export const FETCH_DOORS_SUCCESS = 'FETCH_DOORS_SUCCESS'
export const FETCH_DOORS_ERROR = 'FETCH_DOORS_ERROR'

export const FETCH_AGES = 'FETCH_AGES'
export const FETCH_AGES_SUCCESS = 'FETCH_AGES_SUCCESS'
export const FETCH_AGES_ERROR = 'FETCH_AGES_ERROR'

export const FETCH_MILEAGES = 'FETCH_MILEAGES'
export const FETCH_MILEAGES_SUCCESS = 'FETCH_MILEAGES_SUCCESS'
export const FETCH_MILEAGES_ERROR = 'FETCH_MILEAGES_ERROR'

export const FETCH_BODY_TYPES = 'FETCH_BODY_TYPES'
export const FETCH_BODY_TYPES_SUCCESS = 'FETCH_BODY_TYPES_SUCCESS'
export const FETCH_BODY_TYPES_ERROR = 'FETCH_BODY_TYPES_ERROR'

export const FETCH_VEHICLE_TYPES = 'FETCH_VEHICLE_TYPES'
export const FETCH_VEHICLE_TYPES_SUCCESS = 'FETCH_VEHICLE_TYPES_SUCCESS'
export const FETCH_VEHICLE_TYPES_ERROR = 'FETCH_VEHICLE_TYPES_ERROR'

export const FETCH_FUEL_TYPES = 'FETCH_FUEL_TYPES'
export const FETCH_FUEL_TYPES_SUCCESS = 'FETCH_FUEL_TYPES_SUCCESS'
export const FETCH_FUEL_TYPES_ERROR = 'FETCH_FUEL_TYPES_ERROR'

export const FETCH_NAMA = 'FETCH_NAMA'
export const FETCH_NAMA_SUCCESS = 'FETCH_NAMA_SUCCESS'
export const FETCH_NAMA_ERROR = 'FETCH_NAMA_ERROR'

export const FETCH_SELLERS = 'FETCH_SELLERS'
export const FETCH_SELLERS_SUCCESS = 'FETCH_SELLERS_SUCCESS'
export const FETCH_SELLERS_ERROR = 'FETCH_SELLERS_ERROR'

export const FETCH_WRITE_OFF = 'FETCH_WRITE_OFF'
export const FETCH_WRITE_OFF_SUCCESS = 'FETCH_WRITE_OFF_SUCCESS'
export const FETCH_WRITE_OFF_ERROR = 'FETCH_WRITE_OFF_ERROR'

export const FETCH_NON_RUNNER = 'FETCH_NON_RUNNER'
export const FETCH_NON_RUNNER_SUCCESS = 'FETCH_NON_RUNNER_SUCCESS'
export const FETCH_NON_RUNNER_ERROR = 'FETCH_NON_RUNNER_ERROR'

export const FETCH_EURO_STATUS = 'FETCH_EURO_STATUS'
export const FETCH_EURO_STATUS_SUCCESS = 'FETCH_EURO_STATUS_SUCCESS'
export const FETCH_EURO_STATUS_ERROR = 'FETCH_EURO_STATUS_ERROR'

export const OPEN_PROXY_BID_MODAL = 'OPEN_PROXY_BID_MODAL'
export const CLOSE_PROXY_BID_MODAL = 'CLOSE_PROXY_BID_MODAL'

export const OPEN_SEND_TO_AUCTION_MODAL_BEGIN = 'OPEN_SEND_TO_AUCTION_MODAL_BEGIN'
export const OPEN_SEND_TO_AUCTION_MODAL_SUCCESS = 'OPEN_SEND_TO_AUCTION_MODAL_SUCCESS'
export const OPEN_SEND_TO_AUCTION_MODAL_ERROR = 'OPEN_SEND_TO_AUCTION_MODAL_ERROR'
export const CLOSE_SEND_TO_AUCTION_MODAL = 'CLOSE_SEND_TO_AUCTION_MODAL'

export const OPEN_BUY_NOW_MODAL = 'OPEN_BUY_NOW_MODAL'
export const CLOSE_BUY_NOW_MODAL = 'CLOSE_BUY_NOW_MODAL'

export const OPEN_SAVE_SEARCH_MODAL = 'OPEN_SAVE_SEARCH_MODAL'
export const CLOSE_SAVE_SEARCH_MODAL = 'CLOSE_SAVE_SEARCH_MODAL'

export const OPEN_VEHICLE_PHOTO_MODAL = 'OPEN_VEHICLE_PHOTO_MODAL'
export const CLOSE_VEHICLE_PHOTO_MODAL = 'CLOSE_VEHICLE_PHOTO_MODAL'

export const OPEN_EDIT_SEARCH_MODAL = 'OPEN_EDIT_SEARCH_MODAL'
export const CLOSE_EDIT_SEARCH_MODAL = 'CLOSE_EDIT_SEARCH_MODAL'

export const SET_RANGE_CRITERIA = 'SET_RANGE_CRITERIA'
export const UNSET_RANGE_CRITERIA = 'UNSET_RANGE_CRITERIA'

export const MAKE_AN_OFFER = 'MAKE_AN_OFFER'
export const MAKE_AN_OFFER_SUCCESS = 'MAKE_AN_OFFER_SUCCESS'
export const MAKE_AN_OFFER_FAILURE = 'MAKE_AN_OFFER_FAILURE'

export const SEARCH_BY_KEYWORD = 'SEARCH_BY_KEYWORD'

export const RESET_CRITERIA_BY_KEY = 'RESET_CRITERIA_BY_KEY'

export const SET_PROXY_BID_START = 'SET_PROXY_BID_START'
export const SET_PROXY_BID_SUCCESS = 'SET_PROXY_BID_SUCCESS'
export const SET_PROXY_BID_ERROR = 'SET_PROXY_BID_ERROR'

export const SET_BUY_NOW_CRITERIA = 'SET_BUY_NOW_CRITERIA'

export const BUY_NOW = 'BUY_NOW'
export const BUY_NOW_SUCCESS = 'BUY_NOW_SUCCESS'
export const BUY_NOW_ERROR = 'BUY_NOW_ERROR'

export const SET_ALL_CRITERIA = 'SET_ALL_CRITERIA'

export const USER_REDIRECT = 'USER_REDIRECT'

export const FETCH_STATS = 'FETCH_STATS'
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS'
export const FETCH_STATS_ERROR = 'FETCH_STATS_ERROR'

export const FETCH_EHUB_APPRAISALS = 'FETCH_EHUB_APPRAISALS'
export const FETCH_EHUB_APPRAISALS_SUCCESS = 'FETCH_EHUB_APPRAISALS_SUCCESS'
export const FETCH_EHUB_APPRAISALS_ERROR = 'FETCH_EHUB_APPRAISALS_ERROR'

export const CHANGE_APPRAISAL_STATUS = 'CHANGE_APPRAISAL_STATUS'
export const CHANGE_APPRAISAL_STATUS_SUCCESS = 'CHANGE_APPRAISAL_STATUS_SUCCESS'
export const CHANGE_APPRAISAL_STATUS_ERROR = 'CHANGE_APPRAISAL_STATUS_ERROR'

export const SORT_APPRAISALS = 'SORT_APPRAISALS'

export const SAVE_APPRAISAL_DETAILS = 'SAVE_APPRAISAL_DETAILS'
export const SAVE_APPRAISAL_DETAILS_SUCCESS = 'SAVE_APPRAISAL_DETAILS_SUCCESS'
export const SAVE_APPRAISAL_DETAILS_ERROR = 'SAVE_APPRAISAL_DETAILS_ERROR'
export const REFRESH_CAP_PRICE = 'REFRESH_CAP_PRICE'
export const REFRESH_CAP_PRICE_SUCCESS = 'REFRESH_CAP_PRICE_SUCCESS'
export const REFRESH_CAP_PRICE_ERROR = 'REFRESH_CAP_PRICE_ERROR'

export const FETCH_VEHICLE_APPRAISAL = 'FETCH_VEHICLE_APPRAISAL'
export const FETCH_VEHICLE_APPRAISAL_SUCCESS = 'FETCH_VEHICLE_APPRAISAL_SUCCESS'
export const FETCH_VEHICLE_APPRAISAL_ERROR = 'FETCH_VEHICLE_APPRAISAL_ERROR'

export const SEND_TO_AUCTION = 'SEND_TO_AUCTION'
export const SEND_TO_AUCTION_SUCCESS = 'SEND_TO_AUCTION_SUCCESS'
export const SEND_TO_AUCTION_ERROR = 'SEND_TO_AUCTION_ERROR'

export const FETCH_VRM = 'FETCH_VRM'
export const FETCH_VRM_SUCCESS = 'FETCH_VRM_SUCCESS'
export const FETCH_VRM_ERROR = 'FETCH_VRM_ERROR'

export const FETCH_ALL_VRM = 'FETCH_ALL_VRM'
export const FETCH_ALL_VRM_ERROR = 'FETCH_ALL_VRM_ERROR'
export const FETCH_ALL_VRM_SUCCESS = 'FETCH_ALL_VRM_SUCCESS'

export const SET_APPRAISAL_LOCATION_SEARCH = 'SET_APPRAISAL_LOCATION_SEARCH'
export const TOGGLE_APPRAISAL_ALL_GROUPS_FILTER = 'TOGGLE_APPRAISAL_ALL_GROUPS_FILTER'
export const TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER = 'TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER'
export const RESET_APPRAISAL_GROUPS_FILTER = 'RESET_APPRAISAL_GROUPS_FILTER'

export const UPDATE_STAGE_TITLE = 'UPDATE_STAGE_TITLE'

export const OPEN_BIDDING_MODAL = 'OPEN_BIDDING_MODAL'
export const CLOSE_BIDDING_MODAL = 'CLOSE_BIDDING_MODAL'

export const UPDATE_BIDDING_DETAILS = 'UPDATE_BIDDING_DETAILS'
export const UPDATE_BIDDING_DETAILS_SUCCESS = 'UPDATE_BIDDING_DETAILS_SUCCESS'

export const UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS = 'UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS'
export const UPDATE_BIDDING_DETAILS_END_DATE = 'UPDATE_BIDDING_DETAILS_END_DATE'
export const UPDATE_EXCHANGE_START_SUCCESS = 'UPDATE_EXCHANGE_START_SUCCESS'

export const SET_SORTING_ORDER = 'SET_SORTING_ORDER'

export const ADD_TO_EXCHANGE = 'ADD_TO_EXCHANGE'
export const ADD_TO_EXCHANGE_SUCCESS = 'ADD_TO_EXCHANGE_SUCCESS'
export const ADD_TO_EXCHANGE_ERROR = 'ADD_TO_EXCHANGE_ERROR'

export const FETCH_FUNDING_VEHICLES = 'FETCH_FUNDING_VEHICLES'
export const FETCH_FUNDING_VEHICLES_SUCCESS = 'FETCH_FUNDING_VEHICLES_SUCCESS'
export const FETCH_FUNDING_VEHICLES_ERROR = 'FETCH_FUNDING_VEHICLES_ERROR'
export const FUND_VEHICLE = 'FUND_VEHICLE'
export const FUND_VEHICLE_SUCCESS = 'FUND_VEHICLE_SUCCESS'
export const FUND_VEHICLE_ERROR = 'FUND_VEHICLE_ERROR'
export const FETCH_FUNDING_CREDIT_LIMIT = 'FETCH_FUNDING_CREDIT_LIMIT'
export const FETCH_FUNDING_CREDIT_LIMIT_ERROR = 'FETCH_FUNDING_CREDIT_LIMIT_ERROR'
export const FETCH_FUNDING_CREDIT_LIMIT_SUCCESS = 'FETCH_FUNDING_CREDIT_LIMIT_SUCCESS'

export const OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN = 'OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN'
export const OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS = 'OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS'
export const OPEN_ADD_TO_EXCHANGE_MODAL_ERROR = 'OPEN_ADD_TO_EXCHANGE_MODAL_ERROR'
export const CLOSE_ADD_TO_EXCHANGE_MODAL = 'CLOSE_ADD_TO_EXCHANGE_MODAL'

export const GET_CLOSED_GROUP_SUCCESS = 'GET_CLOSED_GROUP_SUCCESS'
export const GET_CLOSED_GROUP_FAILURE = 'GET_CLOSED_GROUP_FAILURE'

export const UPDATE_WATCHERS_DETAILS_SUCCESS = 'UPDATE_WATCHERS_DETAILS_SUCCESS'
export const UPDATE_AUCTION_SUCCESS = 'UPDATE_AUCTION_SUCCESS'
export const UPDATE_BIDDING_DETAILS_ALL_SUCCESS = 'UPDATE_BIDDING_DETAILS_ALL_SUCCESS'

export const FETCH_EXCHANGE_VEHICLES = 'FETCH_EXCHANGE_VEHICLES'
export const FETCH_EXCHANGE_VEHICLES_SUCCESS = 'FETCH_EXCHANGE_VEHICLES_SUCCESS'
export const FETCH_EXCHANGE_VEHICLES_ERROR = 'FETCH_EXCHANGE_VEHICLES_ERROR'

export const FETCH_PROFILE_CONFIG = 'FETCH_PROFILE_CONFIG'
export const FETCH_PROFILE_CONFIG_SUCCESS = 'FETCH_PROFILE_CONFIG_SUCCESS'
export const FETCH_PROFILE_CONFIG_ERROR = 'FETCH_PROFILE_CONFIG_ERROR'

export const FETCH_PROFILE_LANGUAGE_DICTIONARY = 'FETCH_PROFILE_LANGUAGE_DICTIONARY'
export const FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS = 'FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS'
export const FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR = 'FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR'

export const FETCH_PROFILE_PERMISSIONS = 'FETCH_PROFILE_PERMISSIONS'
export const FETCH_PROFILE_PERMISSIONS_SUCCESS = 'FETCH_PROFILE_PERMISSIONS_SUCCESS'
export const FETCH_PROFILE_PERMISSIONS_ERROR = 'FETCH_PROFILE_PERMISSIONS_ERROR'

export const APP_EHUB = 'e-hub'
export const APP_CATALOGUE = 'catalogue'

export const CT_EHUB = 'e-hub'
export const CT_EXCHANGE = 'e-xchange'
export const CT_ABSITES = 'ab-sites'
export const CT_CATALOGUE = 'catalogue'
export const CT_EVALUATE = 'e-valuate'
export const CT_ARCHIVED = 'archived'
export const CT_COLLECTIONS = 'collections'
export const CT_EHUB_EXCHANGE = 'e-xchange-ehub'
export const CT_SECURE_BANK_TRUST_FUNDING = 'secureBankTrust'

export const SET_EXCHANGE_CRITERIA = 'SET_EXCHANGE_CRITERIA'

export const VEHICLE_AUCTION_ENDED = 'VEHICLE_AUCTION_ENDED'
export const VEHICLE_ENDS_SOON = 'VEHICLE_ENDS_SOON'

export const BUY_NOW_EXCHANGE = 'BUY_NOW_EXCHANGE'
export const BUY_NOW_EXCHANGE_SUCCESS = 'BUY_NOW_EXCHANGE_SUCCESS'
export const BUY_NOW_EXCHANGE_ERROR = 'BUY_NOW_EXCHANGE_ERROR'

export const SET_PREV_NEXT_ORDER = 'SET_PREV_NEXT_ORDER'

export const CHANGE_ACCOUNTS = 'CHANGE_ACCOUNTS'

export const FETCH_SEARCH_USERS = 'FETCH_SEARCH_USERS'
export const FETCH_SEARCH_USERS_SUCCESS = 'FETCH_SEARCH_USERS_SUCCESS'
export const FETCH_SEARCH_USERS_ERROR = 'FETCH_SEARCH_USERS_ERROR'

export const FETCH_SEARCHES_FOR_USER = 'FETCH_SEARCHES_FOR_USER'
export const FETCH_SEARCHES_FOR_USER_SUCCESS = 'FETCH_SEARCHES_FOR_USER_SUCCESS'
export const FETCH_SEARCHES_FOR_USER_ERROR = 'FETCH_SEARCHES_FOR_USER_ERROR'

// These CSS colours are here because if we import SCSS directly into JS, Jest freaks out and therefore we can't create
// a build that's releasable (as of writing, we use CI and we rely on tests for it to build successfully)
export const AB_GREEN = '#008747'
export const TRIUMPH_PRIMARY = '#cd192d'

export const AUCTION_PERIOD_OPTIONS = [
    { value: 3, label: '3 days' },
    { value: 5, label: '5 days' },
    { value: 7, label: '7 days' },
    { value: 9, label: '9 days' },
    { value: 11, label: '11 days' },
    { value: 14, label: '14 days' },
]

export const LIST_AND_COLLECT_AUCTION_PERIOD_OPTIONS = [
    { value: 3, label: '3 days' },
    { value: 5, label: '5 days' },
]

export const SET_THEME = 'SET_THEME'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const CURRENCY = '£'

export const SALE_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
