import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getVehicles, setTotalPages } from '../../actions/vehicles'
import { setPageSize } from '../../actions/pagination'
import { onChangeSortOrder } from '../../actions/sorting'
import {
    getAges,
    getBodyTypes,
    getDoors,
    getEngineSizes,
    getFuelTypes,
    getMakes,
    getMileages,
    getNama,
    getSales,
    getSaleDays,
    getSellers,
    getSites,
    getTransmissions,
    getVehicleTypes,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
} from '../../actions/criteriaData'

import {
    handleOnSetCriteria,
    onClickExchangeOnly,
    handleOnLoadSearch,
    loadMarketingSearch,
    onClickBuyNowOnly,
    setSearchCriteria,
} from '../../actions/criteria'

import Catalogue from './Catalogue'

const mapStateToProps = (state) => ({
    user: state.user,
    criteria: state.criteria,
    vehicles: state.vehicles,
    sorting: state.sorting,
    pagination: state.pagination,
})

const mapDispatchToProps = {
    getVehicles,
    getSites,
    getSales,
    getSaleDays,
    getMakes,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
    setTotalPages,
    handleOnSetCriteria,
    setPageSize,
    onChangeSortOrder,
    onClickExchangeOnly,
    onClickBuyNowOnly,
    handleOnLoadSearch,
    loadMarketingSearch,
    setSearchCriteria,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalogue))
