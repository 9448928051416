import React from 'react'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import { Button } from 'reactstrap'

import { renderButtonContent } from '../../utilities/Functions'
import { lang } from '../../themeConfig'

class DesktopButton extends React.PureComponent {
    render() {
        const { dataType, disabled, defaultText } = this.props

        let classes = this.props.criteria.length
            ? 'btn btn-block ab-results-filter-with-selection position-relative'
            : 'btn btn-block position-relative'
        return (
            <Button
                id={`${dataType}-popover`}
                className={classes}
                onClick={() => {
                    this.props.criteriaOpen === this.props.dataType
                        ? this.props.onCloseCriteria()
                        : this.props.onOpenCriteria(this.props.dataType)
                }}
                data-html="true"
                disabled={disabled}
            >
                <p className="p-0 m-0">
                    {lang(`CATALOGUE_${this.props.dataType.toUpperCase()}_TEXT`, this.props.theme) || defaultText}
                </p>
                <FaChevronRight className="position-absolute" size="1.5em" />
                {renderButtonContent(this.props)}
            </Button>
        )
    }
}

export default DesktopButton
