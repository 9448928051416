import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import { Input, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { formatCurrency } from '../../utilities/Functions'
import { onClickMakeAnOffer, onClickSetBuyNow } from '../../actions/vehicles'
import { CURRENCY } from '../../constants'
import { onOpenBuyNowModal, onCloseBuyNowModal } from '../../actions/modals'

import { lang } from '../../themeConfig'

const override = css`
    display: block;
    margin: 0 auto;
`
class BuyNowModal extends Component {
    constructor(props) {
        super(props)
        this.handleSubmitOffer = this.handleSubmitOffer.bind(this)
        this.handleCommitToBuyNow = this.handleCommitToBuyNow.bind(this)
        this.getMinimumOffer = this.getMinimumOffer.bind(this)
        this.getBidIncrements = this.getBidIncrements.bind(this)

        const rejected =
            this.props.buyNowModal.data.currentBidAndBuyNow &&
            this.props.buyNowModal.data.currentBidAndBuyNow.status === 'rejected'
        this.state = {
            amount: rejected
                ? this.props.buyNowModal.data.currentBidAndBuyNow.amount +
                  this.props.buyNowModal.data.makeAnOfferPrice.increments
                : this.props.buyNowModal.data.makeAnOfferPrice && this.props.buyNowModal.data.makeAnOfferPrice.minimum
                  ? this.props.buyNowModal.data.makeAnOfferPrice.minimum
                  : 100,
            valid: true,
            loading: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const rejected =
            nextProps.buyNowModal.data.currentBidAndBuyNow &&
            nextProps.buyNowModal.data.currentBidAndBuyNow.status === 'rejected'
        this.setState({
            amount: rejected
                ? nextProps.buyNowModal.data.currentBidAndBuyNow.amount +
                  nextProps.buyNowModal.data.makeAnOfferPrice.increments
                : nextProps.buyNowModal.data.makeAnOfferPrice && nextProps.buyNowModal.data.makeAnOfferPrice.minimum
                  ? nextProps.buyNowModal.data.makeAnOfferPrice.minimum
                  : 100,
        })
    }

    onChangeBuyNow = (event) => {
        const rejected =
            this.props.buyNowModal.data.currentBidAndBuyNow &&
            this.props.buyNowModal.data.currentBidAndBuyNow.status === 'rejected'
        const amount = event.target.value
        const amountAsNumber = Number.parseInt(amount)
        const minimum = rejected
            ? this.props.buyNowModal.data.currentBidAndBuyNow.amount +
              this.props.buyNowModal.data.makeAnOfferPrice.increments
            : this.props.buyNowModal.data.makeAnOfferPrice.minimum
        const difference = amountAsNumber - minimum
        const isValid =
            amountAsNumber > 24 &&
            difference % this.props.buyNowModal.data.makeAnOfferPrice.increments === 0 &&
            amountAsNumber >= minimum

        this.setState({
            amount: amountAsNumber,
            valid: isValid,
        })
    }

    async handleCommitToBuyNow(event) {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ loading: true })
        await this.props.onClickSetBuyNow(
            Object.assign(
                {},
                {
                    vehicleId: this.props.buyNowModal.data.id,
                    account: this.props.buyNowModal.data.account,
                    amount: this.props.buyNowModal.data.bidBuyNowPrice,
                },
            ),
        )
        this.setState({ loading: false })
        window.dataLayer && window.dataLayer.push({
            'event': 'Commit to buy now - catalogue Vehicle',
        })
        this.setState({ amount: 25 })
        this.props.onCloseBuyNowModal()
        this.props.history.push('/my-account/bid-buy-now')
    }

    async handleSubmitOffer(event) {
        event.preventDefault()
        event.stopPropagation()

        await this.props.onClickMakeAnOffer(
            Object.assign(
                {},
                {
                    vehicleId: this.props.buyNowModal.data.id,
                    account: this.props.buyNowModal.data.account,
                    amount: this.state.amount,
                },
            ),
        )
        this.setState({ amount: 25 })
        this.props.onCloseBuyNowModal()
        this.props.history.push('/my-account/bid-buy-now')
    }

    getMinimumOffer(rejected) {
        if (rejected) {
            return (
                this.props.buyNowModal.data.currentBidAndBuyNow.amount +
                this.props.buyNowModal.data.makeAnOfferPrice.increments
            )
        } else if (
            this.props.buyNowModal.data.makeAnOfferPrice &&
            this.props.buyNowModal.data.makeAnOfferPrice.minimum
        ) {
            return this.props.buyNowModal.data.makeAnOfferPrice.minimum
        }
        return 100
    }

    getBidIncrements() {
        if (this.props.buyNowModal.data.makeAnOfferPrice && this.props.buyNowModal.data.makeAnOfferPrice.increments) {
            return this.props.buyNowModal.data.makeAnOfferPrice.increments
        }
        return 100
    }

    render() {
        const rejected =
            this.props.buyNowModal.data.currentBidAndBuyNow &&
            this.props.buyNowModal.data.currentBidAndBuyNow.status === 'rejected'
        const minimum = this.getMinimumOffer(rejected)
        const bidIncrement = this.getBidIncrements()
        return (
            <Modal
                isOpen={this.props.buyNowModal.isOpen}
                toggle={() => {
                    if (this.props.buyNowModal.isOpen) {
                        return this.props.onCloseBuyNowModal(!this.props.buyNowModal.isOpen)
                    }
                    return this.props.onOpenBuyNowModal(!this.props.buyNowModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-lg ab-proxy-bid-modal ab-proxy-bid-modal"
            >
                <ModalHeader
                    toggle={() => {
                        if (this.props.buyNowModal.isOpen) {
                            return this.props.onCloseBuyNowModal(!this.props.buyNowModal.isOpen)
                        }
                        return this.props.onOpenBuyNowModal(!this.props.buyNowModal.isOpen)
                    }}
                >
                    {lang('BUY_NOW_OR_MAKE_OFFER_TITLE', this.props.theme)}
                </ModalHeader>
                {this.state.loading ? (
                    <ModalBody>
                        <div
                            className="loading-wrapper"
                            style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <ScaleLoader className={override} size={10} color={'#008747'} loading={true} />
                                {lang('SUBMITTING', this.props.theme)}
                            </div>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="row m-0 p-0">
                            <div className="col-12 m-0 p-0 p-md-2">
                                <p>{lang('INSTANTLY_BUY_NOW', this.props.theme)}</p>
                            </div>
                        </div>
                        <div className="row m-0 p-0">
                            <div className="col-12 col-md-6 m-0 p-0 p-md-2 d-flex flex-column">
                                <h3>{lang('BUY_NOW_TITLE', this.props.theme)}</h3>
                                <p style={{ flexGrow: 1 }}>
                                    {`${lang('LABEL_BUY_NOW_PRICE', this.props.theme)} ${formatCurrency(
                                        this.props.buyNowModal.data.bidBuyNowPrice,
                                    )} ${lang('BUY_NOW_DESCRIPTION', this.props.theme)}`}
                                </p>
                                <button
                                    className="btn btn-block ab-btn-padding btn-primary"
                                    onClick={(event) => this.handleCommitToBuyNow(event)}
                                >
                                    {lang('BUTTON_COMMIT_TO_BUY_NOW', this.props.theme)}
                                </button>
                            </div>
                            <div className="col-12 col-md-6 m-0 p-0 p-md-2 mb-3 mb-md-0 mt-3 mt-md-0 d-flex flex-column">
                                <h3>{lang('MAKE_AN_OFFER', this.props.theme)}</h3>
                                <p>
                                    {rejected
                                        ? `${lang('YOUR_OFFER_REJECTED', this.props.theme)} ${formatCurrency(
                                              this.props.buyNowModal.data.currentBidAndBuyNow.amount,
                                          )}. ${lang('ENTER_NEW_PRICE_FOR_SELLER', this.props.theme)}`
                                        : lang('ENTER_BEST_PRICE_FOR_SELLER', this.props.theme)}
                                </p>
                                <form>
                                    <div>
                                        <label htmlFor="abMakeOfferInput">
                                            {lang('LABEL_MAKE_AN_OFFER', this.props.theme)}
                                        </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    {CURRENCY}
                                                </span>
                                            </div>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="abProxyBidInput"
                                                step={
                                                    this.props.buyNowModal.data.makeAnOfferPrice &&
                                                    this.props.buyNowModal.data.makeAnOfferPrice.increments
                                                        ? this.props.buyNowModal.data.makeAnOfferPrice.increments
                                                        : 100
                                                }
                                                min={
                                                    rejected
                                                        ? this.props.buyNowModal.data.currentBidAndBuyNow.amount +
                                                          this.props.buyNowModal.data.makeAnOfferPrice.increments
                                                        : this.props.buyNowModal.data.makeAnOfferPrice &&
                                                            this.props.buyNowModal.data.makeAnOfferPrice.minimum
                                                          ? this.props.buyNowModal.data.makeAnOfferPrice.minimum
                                                          : 100
                                                }
                                                placeholder={lang('ENTER_YOUR_OFFER', this.props.theme)}
                                                defaultValue={
                                                    rejected
                                                        ? this.props.buyNowModal.data.currentBidAndBuyNow.amount +
                                                          this.props.buyNowModal.data.makeAnOfferPrice.increments
                                                        : this.props.buyNowModal.data.makeAnOfferPrice &&
                                                            this.props.buyNowModal.data.makeAnOfferPrice.minimum
                                                          ? this.props.buyNowModal.data.makeAnOfferPrice.minimum
                                                          : 100
                                                }
                                                onChange={this.onChangeBuyNow}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' && this.state.valid) {
                                                        this.handleSubmitOffer(e, e.target.value)
                                                    }
                                                }}
                                            />
                                        </div>

                                        <small>
                                            {lang('LABEL_MINIMUM', this.props.theme)} {CURRENCY}
                                            {minimum}.{lang('BID_INCREMENTS', this.props.theme)} {CURRENCY}
                                            {bidIncrement} {lang('THEREAFTER', this.props.theme)}
                                        </small>
                                    </div>
                                </form>
                                <button
                                    className="btn btn-block ab-btn-padding btn-primary"
                                    disabled={!this.state.valid}
                                    onClick={(event) => this.handleSubmitOffer(event)}
                                >
                                    {rejected
                                        ? lang('SUBMIT_NEW_OFFER', this.props.theme)
                                        : lang('SUBMIT_YOUR_OFFER', this.props.theme)}
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <small
                                    dangerouslySetInnerHTML={{
                                        __html: lang('TERMS_AND_CONDITIONS_TEXT', this.props.theme),
                                    }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    buyNowModal: state.modals.buyNow,
    theme: state.user.theme,
})

const mapDispatchToProps = {
    onOpenBuyNowModal,
    onCloseBuyNowModal,
    onClickMakeAnOffer,
    onClickSetBuyNow,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyNowModal))
