import {
    FETCH_VEHICLES,
    FETCH_VEHICLES_SUCCESS,
    FETCH_VEHICLES_ERROR,
    FETCH_VEHICLE_DETAILS,
    FETCH_VEHICLE_DETAILS_SUCCESS,
    FETCH_VEHICLE_DETAILS_ERROR,
    SORT_VEHICLES,
    FETCH_WATCH_VEHICLE_SUCCESS,
    SET_PROXY_BID_START,
    SET_PROXY_BID_SUCCESS,
    SET_PROXY_BID_ERROR,
    FETCH_WATCHED_VEHICLES,
    FETCH_WATCHED_VEHICLES_SUCCESS,
    FETCH_WATCHED_VEHICLES_ERROR,
    FETCH_PROXY_BID_VEHICLES,
    FETCH_PROXY_BID_VEHICLES_SUCCESS,
    FETCH_PROXY_BID_VEHICLES_ERROR,
    FETCH_BID_BUY_NOW_VEHICLES,
    FETCH_BID_BUY_NOW_VEHICLES_SUCCESS,
    FETCH_BID_BUY_NOW_VEHICLES_ERROR,
    UPDATE_BIDDING_DETAILS,
    UPDATE_WATCHERS_DETAILS_SUCCESS,
    UPDATE_BIDDING_DETAILS_SUCCESS,
    UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS,
    MAKE_AN_OFFER,
    MAKE_AN_OFFER_SUCCESS,
    MAKE_AN_OFFER_FAILURE,
    BUY_NOW,
    BUY_NOW_SUCCESS,
    BUY_NOW_ERROR,
    VEHICLE_AUCTION_ENDED,
    VEHICLE_ENDS_SOON,
    BUY_NOW_EXCHANGE_SUCCESS,
    UPDATE_EXCHANGE_START_SUCCESS,
} from '../constants'

const initialState = {
    loading: false,
    data: [],
    error: null,
}

const VehiclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_VEHICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            }
        case FETCH_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_VEHICLE_DETAILS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_VEHICLE_DETAILS_SUCCESS:
            const index = state.data.findIndex((i) => i.id === action.payload.id)
            const newData = state.data.map((data, i) => {
                return data.id === action.payload.id ? (data = action.payload) : data
            })

            return {
                ...state,
                data: index === -1 ? [...state.data, action.payload] : newData,
                loading: false,
                error: null,
            }
        case FETCH_VEHICLE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        // Proxy bids
        case SET_PROXY_BID_START:
            return {
                ...state,
                loading: true, // Surely should be true?
                error: null,
            }

        case SET_PROXY_BID_SUCCESS:
            const proxyData = state.data.map((data, i) => {
                return data.id === action.payload.vehicleId
                    ? Object.assign({}, data, { currentProxyBid: action.payload })
                    : data
            })

            return {
                ...state,
                data: proxyData,
                loading: false,
                error: null,
            }

        case SET_PROXY_BID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case VEHICLE_AUCTION_ENDED:
            return {
                ...state,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.id
                        ? {
                              ...data,
                              biddingDetails: {
                                  ...data.biddingDetails,
                                  ended: true,
                                  endsSoon: false,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case BUY_NOW_EXCHANGE_SUCCESS:
            return {
                ...state,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.id
                        ? {
                              ...data,
                              biddingDetails: action.payload,
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case MAKE_AN_OFFER:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case MAKE_AN_OFFER_SUCCESS:
            return {
                ...state,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.vehicleid
                        ? {
                              ...data,
                              currentBidAndBuyNow: {
                                  amount: action.payload.amount,
                                  status: 'pending',
                                  vehicleId: action.payload.vehicleid,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case MAKE_AN_OFFER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case BUY_NOW:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case BUY_NOW_SUCCESS:
            return {
                ...state,
                data: state.data.map((data, _i) => {
                    return data.id === action.payload.vehicleid
                        ? {
                              ...data,
                              currentBidAndBuyNow: {
                                  amount: action.payload.amount,
                                  status: 'pending',
                                  vehicleId: action.payload.vehicleid,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case BUY_NOW_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case VEHICLE_ENDS_SOON:
            return {
                ...state,
                data: state.data.map((data, _i) => {
                    return data.id === action.payload.id
                        ? {
                              ...data,
                              biddingDetails: {
                                  ...data.biddingDetails,
                                  endsSoon: true,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case SORT_VEHICLES:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            }
        case FETCH_WATCH_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.id ? { ...data, watching: action.payload.watching } : data
                }),
                error: null,
            }
        case FETCH_WATCHED_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_WATCHED_VEHICLES_SUCCESS:
            const updatedVehicleIds = action.payload.map((o) => o.id)
            const notUpdatedVehicless = state.data.filter((vehicle) => !updatedVehicleIds.includes(vehicle.id))
            const newArray = [...notUpdatedVehicless, ...action.payload]

            return {
                ...state,
                data: newArray,
                loading: false,
                error: null,
            }
        case FETCH_WATCHED_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_PROXY_BID_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_PROXY_BID_VEHICLES_SUCCESS:
            const updatedProxyVehicleIds = action.payload.map((o) => o.id)
            const notUpdatedProxyVehicless = state.data.filter(
                (vehicle) => !updatedProxyVehicleIds.includes(vehicle.id),
            )
            const newProxyArray = [...notUpdatedProxyVehicless, ...action.payload]

            return {
                ...state,
                data: newProxyArray,
                loading: false,
                error: null,
            }
        case FETCH_PROXY_BID_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_BID_BUY_NOW_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_BID_BUY_NOW_VEHICLES_SUCCESS:
            const updatedBidBuyNowVehicleIds = action.payload.map((o) => o.id)
            const notUpdatedBidBuyNowVehicless = state.data.filter(
                (vehicle) => !updatedBidBuyNowVehicleIds.includes(vehicle.id),
            )
            const newBidBuyNowArray = [...notUpdatedBidBuyNowVehicless, ...action.payload]

            return {
                ...state,
                data: newBidBuyNowArray,
                loading: false,
                error: null,
            }

        case UPDATE_BIDDING_DETAILS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case UPDATE_WATCHERS_DETAILS_SUCCESS:
            let data = state.data.map((data, i) => {
                return data.id === action.payload.vehicleId
                    ? {
                          ...data,
                          biddingDetails: {
                              ...data.biddingDetails,
                              noOfWatchers: action.payload.numberOfWatchers,
                          },
                      }
                    : data
            })
            return {
                ...state,
                data,
                loading: false,
                error: null,
            }
        case UPDATE_BIDDING_DETAILS_SUCCESS:
            return {
                ...state,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.id
                        ? {
                              ...data,
                              biddingDetails: {
                                  ...data.biddingDetails,
                                  id: action.payload.id,
                                  reserveMet: action.payload.reserveMet,
                                  startingPrice: action.payload.startingPrice,
                                  currentBid: action.payload.currentBid,
                                  timeRemaining:
                                      data.biddingDetails.timeRemaining > action.payload.timeRemaining
                                          ? action.payload.timeRemaining
                                          : data.biddingDetails.timeRemaining,
                                  endDate:
                                      new Date(data.biddingDetails.endDate) - new Date(action.payload.endDate) > 0
                                          ? data.biddingDetails.endDate
                                          : action.payload.endDate,
                                  noOfBids: action.payload.noOfBids,
                                  noOfWatchers: action.payload.noOfWatchers,
                                  startDate: action.payload.startDate,
                                  userWinning: action.payload.userWinning,
                                  userMaxBid: action.payload.userMaxBid,
                                  buyNowPrice: action.payload.buyNowPrice,
                                  ended: action.payload.ended,
                                  leaseplanVehicle: action.payload.leaseplanVehicle,
                                  divisor: action.payload.divisor,
                                  provisionalStatus: action.payload.provisionalStatus,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case UPDATE_BIDDING_DETAILS_ALL_SUCCESS:
            return {
                ...state,
                data: state.data.map((b) => {
                    const a1 = action.payload.find((e) => e.id === b.id) || null
                    if (a1) {
                        return Object.assign({}, b, {
                            biddingDetails: a1,
                        })
                    }
                    return Object.assign({}, b)
                }),
            }
        case FETCH_BID_BUY_NOW_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS:
            return {
                ...state,
                data: state.data.map((data, i) => {
                    return data.id === action.payload.id
                        ? {
                              ...data,
                              biddingDetails: {
                                  ...data.biddingDetails,
                                  id: action.payload.id,
                                  timeRemaining:
                                      data.biddingDetails.timeRemaining > action.payload.timeRemaining
                                          ? action.payload.timeRemaining
                                          : data.biddingDetails.timeRemaining,
                                  endDate:
                                      new Date(data.biddingDetails.endDate) - new Date(action.payload.endDate) > 0
                                          ? data.biddingDetails.endDate
                                          : action.payload.endDate,
                              },
                          }
                        : data
                }),
                loading: false,
                error: null,
            }
        case UPDATE_EXCHANGE_START_SUCCESS:
            return {
                ...state,
                data: state.data.map((data) => {
                    if (data.id !== action.payload.id) {
                        return data
                    }
                    return {
                        ...data,
                        biddingDetails: {
                            ...data.biddingDetails,
                            exchangeStarted: true,
                        },
                    }
                }),
            }
        default:
            return state
    }
}

export default VehiclesReducer
