import {
    RESET_CRITERIA_BY_KEY,
    RESET_SEARCH,
    SEARCH_BY_KEYWORD,
    SET_ALL_CRITERIA,
    SET_BUY_NOW_CRITERIA,
    SET_CRITERIA,
    SET_EXCHANGE_CRITERIA,
    SET_RANGE_CRITERIA,
    UNSET_CRITERIA,
    UNSET_RANGE_CRITERIA,
    CLEAR_CRITERIA,
} from '../constants'

const initialState = {
    sites: [],
    sales: [],
    saleDays: [],
    makes: [],
    models: [],
    modelVariants: [],
    engineSize: {},
    transmission: [],
    doors: [],
    nama: {},
    age: {},
    bodyTypes: [],
    vehicleTypes: [],
    fuelTypes: [],
    mileage: {},
    sellers: [],
    writeOff: [],
    nonRunner: [],
    euroStatus: [],
    buyNow: false,
    eXchangeOnly: false,
    keywords: '',
    resetToggle: false,
    loading: false,
}

const CriteriaReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_CRITERIA:
        case UNSET_RANGE_CRITERIA:
            return {
                ...state,
                loading: false,
                [action.payload.key]: [],
            }

        case SET_CRITERIA:
            if (action.payload.inputType === 'radio') {
                return {
                    ...state,
                    loading: false,
                    [action.payload.key]: [action.payload.value],
                }
            }
            return {
                ...state,
                loading: false,
                [action.payload.key]: action.payload.clear ? [] : [...state[action.payload.key], action.payload.value],
            }

        case SET_ALL_CRITERIA:
            return {
                ...initialState,
                ...action.payload,
            }

        case UNSET_CRITERIA:
            return {
                ...state,
                loading: false,
                [action.payload.key]: state[action.payload.key].filter((item) => item !== action.payload.value),
            }

        case SET_RANGE_CRITERIA:
            return {
                ...state,
                loading: false,
                [action.payload.key]: action.payload.value,
            }

        case RESET_SEARCH:
            return {
                ...initialState,
                resetToggle: !state.resetToggle,
            }

        case RESET_CRITERIA_BY_KEY:
            return {
                ...state,
                loading: false,
                [action.payload]: initialState[action.payload],
            }

        case SEARCH_BY_KEYWORD:
            return {
                ...state,
                keywords: action.payload,
            }

        case SET_BUY_NOW_CRITERIA:
            return {
                ...state,
                buyNow: action.payload,
            }

        case SET_EXCHANGE_CRITERIA:
            return {
                ...state,
                eXchangeOnly: action.payload,
            }

        default:
            return state
    }
}

export default CriteriaReducer
