import React, { Component } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Collapse, Popover, PopoverBody, TabContent } from 'reactstrap'

import debounce from 'lodash/debounce'
import ldiff from 'lodash/difference'
import union from 'lodash/union'
import intersection from 'lodash/intersection'

import DesktopButton from './DesktopButton'
import MobileButton from './MobileButton'
import DefaultClearButton from './DefaultClearButton'
import TabItems from './TabItems'
import Items from './Items'
import Tabs from './Tabs'

import { lang } from '../../themeConfig'

import 'react-input-range/lib/css/index.css'
import _ from 'lodash'

// If you're creating a new filter you *must* add it here
const excludeList = {
    sales: ['models', 'modelVariants'],
    sites: ['models', 'modelVariants'],
    saleDays: ['models', 'modelVariants'],
    makes: ['modelVariants'],
    models: ['makes', 'models'],
    modelVariants: ['modelVariants', 'models', 'makes'],
    transmission: ['transmission', 'models', 'modelVariants'],
    engineSize: ['models', 'modelVariants'],
    doors: ['doors', 'models', 'modelVariants'],
    age: ['models', 'modelVariants'],
    mileage: ['models', 'modelVariants'],
    bodyTypes: ['bodyTypes', 'models', 'modelVariants'],
    vehicleTypes: ['vehicleTypes', 'models', 'modelVariants'],
    fuelTypes: ['fuelTypes', 'models', 'modelVariants'],
    sellers: ['sellers', 'models', 'modelVariants'],
    nama: ['models', 'modelVariants'],
    writeOff: ['models', 'modelVariants'],
    nonRunner: ['models', 'modelVariants'],
    euroStatus: ['models', 'modelVariants'],
}

// Confusing component name, referred to as an Accordion in Criteria/index.js and Criteria in Search.js
class Popout extends Component {
    constructor(props) {
        super(props)
        this.onSetCriteria = this.onSetCriteria.bind(this)
        this.onFinishRangeChange = this.onFinishRangeChange.bind(this)
        this.apiLoopCall = debounce(this.apiLoopCall, 500)
        this.onSetRangeCriteria = this.onSetRangeCriteria.bind(this)
        this.state = {
            activeTab: '0-0',
            loading: false,
            data: [],
            error: '',
            criteria: {},
            rangeValue: null,
            minMaxRange: {
                min: 0,
                max: 0,
            },
        }
    }

    componentDidMount() {
        const data = this.props.criteriaData[this.props.dataType]
        const criteria = this.props.criteria[this.props.dataType] ? this.props.criteria[this.props.dataType] : []
        this.onRangeChange = this.onRangeChange.bind(this)
        this.setState({
            data: data ? data.data : [],
            criteria,
            loading: data ? data.loading : [],
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.criteriaData[this.props.dataType] ? nextProps.criteriaData[this.props.dataType].data : [],
            loading: nextProps.criteriaData[this.props.dataType]
                ? nextProps.criteriaData[this.props.dataType].loading
                : false,
            criteria: nextProps.criteria[this.props.dataType] ? nextProps.criteria[this.props.dataType] : [],
            rangeValue:
                nextProps.criteria[this.props.dataType] && nextProps.criteria[this.props.dataType].id
                    ? nextProps.criteria[this.props.dataType].id
                    : null,
        })
        let runLoopCall = false

        const newProps = nextProps.criteria[this.props.dataType]
        const oldProps = this.props.criteria[this.props.dataType]

        const difference = ldiff(union(newProps, oldProps), intersection(newProps, oldProps))

        const reset = this.props.criteria.resetToggle !== nextProps.criteria.resetToggle

        if (difference.length > 0) {
            runLoopCall = true
        }
        if (
            this.props.type === 'rangeSelect' &&
            oldProps &&
            !_.isEqual(_.omitBy(oldProps, _.isNil), _.omitBy(newProps, _.isNil))
        ) {
            runLoopCall = true
        }
        if (runLoopCall) {
            return this.apiLoopCall(reset, nextProps.criteria, excludeList[this.props.dataType])
        }
    }

    apiLoopCall(reset, criteria, excludeArray) {
        const allCalls = {
            vehicles: () => this.props.getVehicles(criteria, this.props.sorting),
            modelVariants: () => this.props.getModelVariants(criteria),
            sites: () => this.props.getSites(criteria),
            makes: () => this.props.getMakes(criteria),
            sales: () => this.props.getSales(criteria),
            saleDays: () => this.props.getSaleDays(criteria),
            models: () => this.props.getModels(criteria),
            engineSize: () => this.props.getEngineSizes(criteria),
            transmission: () => this.props.getTransmissions(criteria),
            doors: () => this.props.getDoors(criteria),
            age: () => this.props.getAges(criteria),
            mileage: () => this.props.getMileages(criteria),
            bodyTypes: () => this.props.getBodyTypes(criteria),
            vehicleTypes: () => this.props.getVehicleTypes(criteria),
            fuelTypes: () => this.props.getFuelTypes(criteria),
            sellers: () => this.props.getSellers(criteria),
            nama: () => this.props.getNama(criteria),
            writeOff: () => this.props.getWriteOff(criteria),
            nonRunner: () => this.props.getNonRunner(criteria),
            euroStatus: () => this.props.getEuroStatus(criteria),
        }

        if (!reset) {
            const callToMake = Object.keys(allCalls)
                .filter((type) => excludeArray.indexOf(type) === -1)
                .reduce((obj, key) => {
                    obj[key] = allCalls[key]
                    return obj
                }, {})
            Object.keys(callToMake).map((key) => callToMake[key]())
        } else {
            const callToMake = Object.keys(allCalls)
                .filter((type) => 'modelVariants' !== type)
                .reduce((obj, key) => {
                    obj[key] = allCalls[key]
                    return obj
                }, {})
            Object.keys(callToMake).map((key) => callToMake[key]())
        }
    }

    onSetCriteria(value, key, checked, inputType) {
        this.props.handleOnSetCriteria(value, key, checked, inputType, this.props.pagination.resultsPerPage.value)
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    onSetRangeCriteria(value, type) {
        let obj
        const newValue = Object.assign(
            {},
            {
                label: value.label.substring(0, value.label.indexOf(' (')),
                value: value.value,
            },
        )

        if (type === 'min') {
            obj = {
                min: newValue,
                max: this.state.criteria.max,
            }
        }

        if (type === 'max') {
            obj = {
                min: this.state.criteria.min,
                max: newValue,
            }
        }

        this.props.handleOnSetRangeCriteria(obj, this.props.dataType, true, this.props.pagination.resultsPerPage.value)
    }

    onRangeChange(val) {
        if (this.props.dataType === 'nama') {
            return this.setState({
                minMaxRange: {
                    min: this.state.data[val.min].key,
                    max: this.state.data[val.max].key,
                },
            })
        }
        const value = this.state.data[val].id
        this.setState({ rangeValue: value })
    }

    onFinishRangeChange(value) {
        let obj

        if (this.props.dataType === 'nama') {
            obj = {
                min: this.state.data[value.min].key,
                max: this.state.data[value.max].key,
            }
        } else {
            obj = {
                id: this.state.data[value].id,
                min: this.state.data[value].min,
                max: this.state.data[value].max,
            }
        }

        this.props.handleOnSetRangeCriteria(obj, this.props.dataType, true, this.props.pagination.resultsPerPage.value)
    }

    render() {
        const isCriteriaDataLoading =
            Object.keys(this.props.criteriaData)
                .map((criteriaKey) => {
                    return this.props.criteriaData[criteriaKey] ? this.props.criteriaData[criteriaKey].loading : false
                })
                .filter((loading) => {
                    return loading === true
                }).length > 0

        const hasOptions =
            isCriteriaDataLoading ||
            (this.props.dependantDataType &&
                Object.keys(this.props.criteria[this.props.dependantDataType]).length === 0)

        // The saleDays criteria should only show if we've passed in a variable via the query string, thus setting a criteria value.
        const filterHasValue =
            (Array.isArray(this.state.criteria)
                ? this.state.criteria.length > 0
                : Object.keys(this.state.criteria || {}).length > 0) ||
            (this.props.type === 'rangeSelect' && this.state.rangeValue !== null)

        // Handle filters that should only be visible when they have a value
        if (this.props.OnlyVisibleWhenCriteriaSet && !filterHasValue) {
            return null // Hide filter
        }

        return (
            <React.Fragment>
                {this.props.criteriaType === 'accordion' ? (
                    <div>
                        <MobileButton
                            data={this.state.data}
                            dataType={this.props.dataType}
                            defaultText={this.props.defaultText}
                            minMaxRange={this.state.minMaxRange}
                            criteria={this.state.criteria}
                            onOpenCriteria={this.props.onOpenCriteria}
                            onCloseCriteria={this.props.onCloseCriteria}
                            isOpen={this.props.criteriaOpen === this.props.dataType}
                            disabled={hasOptions}
                            inputType={this.props.type}
                            jsonType={this.props.jsonType}
                        />
                        <Collapse isOpen={this.props.criteriaOpen === this.props.dataType}>
                            <Card className="ab-filter-results-card">
                                {this.props.hasTabs && (
                                    <CardHeader>
                                        <Tabs
                                            criteria={this.state.criteria}
                                            data={this.state.data}
                                            dataType={this.props.dataType}
                                            activeTab={this.state.activeTab}
                                            toggle={this.toggleTab}
                                        />
                                    </CardHeader>
                                )}
                                <CardBody>
                                    <div className="row px-3">
                                        {this.props.hasTabs ? (
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabItems
                                                    activeTab={this.state.activeTab}
                                                    criteria={this.state.criteria}
                                                    data={this.state.data}
                                                    dataType={this.props.dataType}
                                                    handleOnSetCriteria={this.onSetCriteria}
                                                    inputType={this.props.type}
                                                    offsetFinish={this.props.pagination.resultsPerPage.value}
                                                />
                                            </TabContent>
                                        ) : (
                                            <Items
                                                criteria={this.state.criteria}
                                                data={this.state.data}
                                                defaultText={this.props.defaultText}
                                                dataType={this.props.dataType}
                                                handleOnSetCriteria={this.onSetCriteria}
                                                inputType={this.props.type}
                                                jsonType={this.props.jsonType}
                                                onSetRangeCriteria={this.onSetRangeCriteria}
                                                loading={this.state.loading}
                                                offsetFinish={this.props.pagination.resultsPerPage.value}
                                            />
                                        )}
                                    </div>
                                </CardBody>
                                <CardFooter className="pt-0">
                                    {(this.state.criteria.length > 0 ||
                                        (this.props.type === 'rangeSelect' &&
                                            Object.keys(this.state.criteria).length > 0)) && (
                                        <DefaultClearButton
                                            className="link float-right"
                                            defaultText={this.props.defaultText}
                                            onClick={() =>
                                                this.props.resetCriteriaFilter(
                                                    this.props.dataType,
                                                    this.props.pagination.resultsPerPage.value,
                                                )
                                            }
                                            theme={this.props.user.theme}
                                        />
                                    )}
                                </CardFooter>
                            </Card>
                        </Collapse>
                    </div>
                ) : (
                    <div>
                        <DesktopButton
                            data={this.state.data}
                            dataType={this.props.dataType}
                            defaultText={this.props.defaultText}
                            criteria={this.state.criteria}
                            criteriaOpen={this.props.criteriaOpen}
                            onOpenCriteria={this.props.onOpenCriteria}
                            onCloseCriteria={this.props.onCloseCriteria}
                            disabled={hasOptions}
                            inputType={this.props.type}
                            jsonType={this.props.jsonType}
                            theme={this.props.user.theme}
                        />
                        <Popover
                            placement="right"
                            offset={[10, 10]}
                            isOpen={this.props.criteriaOpen === this.props.dataType}
                            target={this.props.dataType + '-popover'}
                            toggle={() =>
                                this.props.criteriaOpen === this.props.dataType
                                    ? this.props.onCloseCriteria(this.props.dataType)
                                    : this.props.onopenCriteria(this.props.dataType)
                            }
                            fade={false}
                        >
                            <PopoverBody className="card ab-filter-results-card">
                                <div className="card-header">
                                    <div className="clearfix">
                                        <h3 className="float-left">
                                            {`${lang('FILTER_BY', this.props.user.theme)} ${lang(`CATALOGUE_${this.props.dataType.toUpperCase()}_TEXT`, this.props.user.theme)}`}
                                        </h3>
                                        <Button
                                            type="button"
                                            className="close float-right"
                                            aria-label="Close"
                                            onClick={() =>
                                                this.props.onClick
                                                    ? this.props.onClick
                                                    : this.props.onCloseCriteria(this.props.dataType)
                                            }
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </Button>
                                    </div>
                                    {this.props.hasTabs && (
                                        <Tabs
                                            criteria={this.state.criteria}
                                            data={this.state.data}
                                            dataType={this.props.dataType}
                                            activeTab={this.state.activeTab}
                                            toggle={this.toggleTab}
                                        />
                                    )}
                                </div>

                                <div className="card-body">
                                    {this.props.hasTabs ? (
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabItems
                                                activeTab={this.state.activeTab}
                                                criteria={this.state.criteria}
                                                data={this.state.data}
                                                dataType={this.props.dataType}
                                                handleOnSetCriteria={this.onSetCriteria}
                                                inputType={this.props.type}
                                                offsetFinish={this.props.pagination.resultsPerPage.value}
                                            />
                                        </TabContent>
                                    ) : (
                                        <div className="row px-3">
                                            <Items
                                                criteria={this.state.criteria}
                                                data={this.state.data}
                                                defaultText={this.props.defaultText}
                                                dataType={this.props.dataType}
                                                handleOnSetCriteria={this.onSetCriteria}
                                                inputType={this.props.type}
                                                jsonType={this.props.jsonType}
                                                onSetRangeCriteria={this.onSetRangeCriteria}
                                                loading={this.state.loading}
                                                offsetFinish={this.props.pagination.resultsPerPage.value}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div>
                                    {(this.state.criteria.length > 0 ||
                                        (this.props.type === 'rangeSelect' &&
                                            Object.keys(this.state.criteria).length > 0)) && (
                                        <DefaultClearButton
                                            className="float-right link"
                                            defaultText={this.props.defaultText}
                                            onClick={() =>
                                                this.props.resetCriteriaFilter(
                                                    this.props.dataType,
                                                    this.props.pagination.resultsPerPage.value,
                                                )
                                            }
                                            theme={this.props.user.theme}
                                        />
                                    )}
                                </div>
                            </PopoverBody>
                        </Popover>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default Popout
