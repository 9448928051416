import React, { Component } from 'react'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import confirm from '../../../utilities/Confirm'
import { AB_GREEN } from '../../../constants'

import { setTitle, lang } from '../../../themeConfig'
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight'
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt'
import { FaCopy } from '@react-icons/all-files/fa/FaCopy'
import { FaEdit } from '@react-icons/all-files/fa/FaEdit'

import UserSearch from './UserSearch'

const override = css`
    display: block;
    margin: 0 auto;
`
class SavedSearches extends Component {
    constructor(props) {
        super(props)
        this.state = {
            savedSearches: [],
            loading: true,
            searchValue: '',
            userSearchLoading: false,
            userSearchResults: [],
            selectedUsername: '',
            selectedSavedSearches: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        setTitle('Save Searches', this.props.user.theme)
        if (this.props.user && this.props.user.marketing) {
            this.onLoadSavedSearches(this.props.user.marketing)
        } else {
            this.onLoadSavedSearches(this.props.user.marketing)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // window.scrollTo(0, 0)
        this.setState({
            savedSearches: nextProps.user.savedSearches,
            userSearchLoading: nextProps.user.searchUsers.loading,
            userSearchResults: nextProps.user.searchUsers.results,
            selectedSavedSearches: nextProps.user.searchUsers.selectedSearches,
        })
        if (nextProps.user.primaryVendorCode !== this.props.user.primaryVendorCode) {
            this.onLoadSavedSearches(nextProps.user.marketing)
        }
    }

    onLoadSavedSearches(isMarketing) {
        this.props.fetchSavedSearches(isMarketing)
    }
    handleOnViewSavedSearch(search) {
        this.props.handleOnLoadSearch(search, this.props.pagination.resultsPerPage.value)
    }

    onDeleteSavedSearches(id, username) {
        confirm(lang('ARE_YOU_SURE', this.props.user.theme), {
            buttonConfirm: lang('YES', this.props.user.theme),
            buttonDismiss: lang('NO', this.props.user.theme),
            description: lang('ABOUT_TO_DELETE_SAVED_SEARCH', this.props.user.theme),
            cardType: 'catalogue',
        }).then(
            () => {
                window.dataLayer && window.dataLayer.push({
                    'event': 'Delete saved search',
                })
                this.props.fetchDeleteSavedSearches(id, this.props.user.marketing, username)
                return true
            },
            () => {
                return false
            },
        )
    }

    copyToClipboard(text) {
        const url = `${this.props.global.catalogueHost}/vehicle-search?searchId=${text}`
        const dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.setAttribute('value', url)
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)
        this.props.addNotification({
            text: lang('PUBLIC_URL_COPIED', this.props.user.theme),
            type: 'success',
            delay: 2000,
        })
    }

    render() {
        const { user, history } = this.props
        const hasSavedSearches = this.state.savedSearches && this.state.savedSearches.length > 0
        if (user.loading) {
            return (
                <div className="row mx-0">
                    <div className="col px-2">
                        <div className="loading-wrapper">
                            <ScaleLoader className={override} size={10} color={AB_GREEN} loading={user.loading} />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="row mx-0">
                <div className="col-8 px-2" style={{ marginBottom: '20px' }}>
                    <p>{lang('SAVED_SEARCH_DESCRIPTION', this.props.user.theme)}</p>
                    <h3>Your Saved Searches</h3>
                    {hasSavedSearches ? (
                        <div className="ab-catelogue-vehicle-details-tabs-container saved-search__wrapper">
                            <table className="table m-0 p-0">
                                <thead>
                                    <tr>
                                        <th>{lang('LABEL_DESCRIPTION', this.props.user.theme)}</th>
                                        <th className="text-center">{lang('LABEL_LOAD', this.props.user.theme)}</th>
                                        <th className="text-center">{lang('LABEL_DELETE', this.props.user.theme)}</th>
                                        <th className="text-center">Edit</th>
                                        {user.marketing && (
                                            <th className="text-center">{lang('LABEL_URL', this.props.user.theme)}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.savedSearches.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.description}</td>
                                                <td className="text-center">
                                                    <FaChevronCircleRight
                                                        className="cursor-pointer"
                                                        color={AB_GREEN}
                                                        onClick={() => {
                                                            this.handleOnViewSavedSearch(item.search)
                                                            history.push('/vehicle-search?savedsearch=true')
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <FaTrashAlt
                                                        className="cursor-pointer"
                                                        color={AB_GREEN}
                                                        onClick={() => this.onDeleteSavedSearches(item.id)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <FaEdit
                                                        className="cursor-pointer"
                                                        color={AB_GREEN}
                                                        onClick={() => this.props.onOpenEditSearchModal(item.id)}
                                                    />
                                                </td>
                                                {user.marketing && (
                                                    <td className="text-center">
                                                        <FaCopy
                                                            title="Copy to clipboard"
                                                            className="cursor-pointer"
                                                            color={AB_GREEN}
                                                            onClick={() => this.copyToClipboard(item.id)}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="no-data-wrapper">
                            <div className="no-data">{lang('LABEL_NO_SAVED_SEARCHES', this.props.user.theme)}</div>
                        </div>
                    )}
                    {user.marketing && (
                        <>
                            <UserSearch
                                onSelection={(username, email) => {
                                    this.setState({ selectedUsername: username })
                                    this.props.fetchSavedSearchesForUsername(username)
                                }}
                            />
                            {this.state.selectedUsername.length > 0 && (
                                <>
                                    <h3 className="search-spacer">{this.state.selectedUsername}'s Saved Searches</h3>
                                    <div className="ab-catelogue-vehicle-details-tabs-container saved-search__wrapper">
                                        <table className="table m-0 p-0">
                                            <thead>
                                                <tr>
                                                    <th>{lang('LABEL_DESCRIPTION', this.props.user.theme)}</th>
                                                    <th className="text-center">
                                                        {lang('LABEL_LOAD', this.props.user.theme)}
                                                    </th>
                                                    <th className="text-center">
                                                        {lang('LABEL_DELETE', this.props.user.theme)}
                                                    </th>
                                                    <th className="text-center">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.selectedSavedSearches.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.description}</td>
                                                            <td className="text-center">
                                                                <FaChevronCircleRight
                                                                    className="cursor-pointer"
                                                                    color={AB_GREEN}
                                                                    onClick={() => {
                                                                        this.handleOnViewSavedSearch(item.search)
                                                                        history.push('/vehicle-search?savedsearch=true')
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <FaTrashAlt
                                                                    className="cursor-pointer"
                                                                    color={AB_GREEN}
                                                                    onClick={() =>
                                                                        this.onDeleteSavedSearches(
                                                                            item.id,
                                                                            this.state.selectedUsername,
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <FaEdit
                                                                    className="cursor-pointer"
                                                                    color={AB_GREEN}
                                                                    onClick={() =>
                                                                        this.props.onOpenEditSearchModal(
                                                                            item.id,
                                                                            true,
                                                                            this.state.selectedUsername,
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default SavedSearches
