import {
    FETCH_SITES,
    FETCH_SITES_SUCCESS,
    FETCH_SITES_ERROR,
    FETCH_SALES,
    FETCH_SALES_SUCCESS,
    FETCH_SALES_ERROR,
    FETCH_SALE_DAYS,
    FETCH_SALE_DAYS_SUCCESS,
    FETCH_SALE_DAYS_ERROR,
    FETCH_MAKES,
    FETCH_MAKES_SUCCESS,
    FETCH_MAKES_ERROR,
    FETCH_MODELS,
    FETCH_MODELS_SUCCESS,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_VARIANTS,
    FETCH_MODEL_VARIANTS_SUCCESS,
    FETCH_MODEL_VARIANTS_ERROR,
    FETCH_ENGINE_SIZES,
    FETCH_ENGINE_SIZES_SUCCESS,
    FETCH_ENGINE_SIZES_ERROR,
    FETCH_TRANSMISSIONS,
    FETCH_TRANSMISSIONS_SUCCESS,
    FETCH_TRANSMISSIONS_ERROR,
    FETCH_DOORS,
    FETCH_DOORS_SUCCESS,
    FETCH_DOORS_ERROR,
    FETCH_AGES,
    FETCH_AGES_SUCCESS,
    FETCH_AGES_ERROR,
    FETCH_MILEAGES,
    FETCH_MILEAGES_SUCCESS,
    FETCH_MILEAGES_ERROR,
    FETCH_BODY_TYPES,
    FETCH_BODY_TYPES_SUCCESS,
    FETCH_BODY_TYPES_ERROR,
    FETCH_VEHICLE_TYPES,
    FETCH_VEHICLE_TYPES_SUCCESS,
    FETCH_VEHICLE_TYPES_ERROR,
    FETCH_FUEL_TYPES,
    FETCH_FUEL_TYPES_SUCCESS,
    FETCH_FUEL_TYPES_ERROR,
    FETCH_NAMA,
    FETCH_NAMA_SUCCESS,
    FETCH_NAMA_ERROR,
    FETCH_SELLERS,
    FETCH_SELLERS_SUCCESS,
    FETCH_SELLERS_ERROR,
    FETCH_WRITE_OFF,
    FETCH_WRITE_OFF_SUCCESS,
    FETCH_WRITE_OFF_ERROR,
    FETCH_NON_RUNNER,
    FETCH_NON_RUNNER_SUCCESS,
    FETCH_NON_RUNNER_ERROR,
    FETCH_EURO_STATUS,
    FETCH_EURO_STATUS_SUCCESS,
    FETCH_EURO_STATUS_ERROR,
} from '../constants'

const initialState = {
    sites: {
        loading: false,
        data: [],
        error: null,
    },
    sales: {
        loading: false,
        data: [],
        error: null,
    },
    saleDays: {
        loading: false,
        data: [],
        error: null,
    },
    makes: {
        loading: false,
        data: {},
        error: null,
    },
    models: {
        loading: false,
        data: {},
        error: null,
    },
    modelVariants: {
        loading: false,
        data: [],
        error: null,
    },
    engineSize: {
        loading: false,
        data: [],
        error: null,
    },
    transmission: {
        loading: false,
        data: {},
        error: null,
    },
    doors: {
        loading: false,
        data: {},
        error: null,
    },
    nama: {
        loading: false,
        data: [],
        error: null,
    },
    age: {
        loading: false,
        data: [],
        error: null,
    },
    bodyTypes: {
        loading: false,
        data: {},
        error: null,
    },
    vehicleTypes: {
        loading: false,
        data: {},
        error: null,
    },
    fuelTypes: {
        loading: false,
        data: {},
        error: null,
    },
    mileage: {
        loading: false,
        data: [],
        error: null,
    },
    sellers: {
        loading: false,
        data: {},
        error: null,
    },
    writeOff: {
        loading: false,
        data: [],
        error: null,
    },
    nonRunner: {
        loading: false,
        data: [],
        error: null,
    },
    euroStatus: {
        loading: false,
        data: {},
        error: null,
    },
}

const CriteriaDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SITES:
            return {
                ...state,
                sites: {
                    ...state.sites,
                    loading: true,
                },
            }
        case FETCH_SITES_SUCCESS:
            return {
                ...state,
                sites: {
                    ...state.sites,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_SITES_ERROR:
            return {
                ...state,
                sites: {
                    ...state.sites,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_SALES:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: true,
                },
            }
        case FETCH_SALES_SUCCESS:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_SALES_ERROR:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: false,
                    error: action.payload.error,
                },
            }

        // Sale days
        case FETCH_SALE_DAYS:
            return {
                ...state,
                saleDays: {
                    ...state.saleDays,
                    loading: true,
                },
            }
        case FETCH_SALE_DAYS_SUCCESS:
            return {
                ...state,
                saleDays: {
                    ...state.saleDays,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_SALE_DAYS_ERROR:
            return {
                ...state,
                saleDays: {
                    ...state.saleDays,
                    loading: false,
                    error: action.payload.error,
                },
            }

        case FETCH_MAKES:
            return {
                ...state,
                makes: {
                    ...state.makes,
                    loading: true,
                },
            }
        case FETCH_MAKES_SUCCESS:
            return {
                ...state,
                makes: {
                    ...state.makes,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_MAKES_ERROR:
            return {
                ...state,
                makes: {
                    ...state.makes,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_MODELS:
            return {
                ...state,
                models: {
                    ...state.models,
                    loading: true,
                },
            }
        case FETCH_MODELS_SUCCESS:
            return {
                ...state,
                models: {
                    ...state.models,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_MODELS_ERROR:
            return {
                ...state,
                models: {
                    ...state.models,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_MODEL_VARIANTS:
            return {
                ...state,
                modelVariants: {
                    ...state.modelVariants,
                    loading: true,
                },
            }
        case FETCH_MODEL_VARIANTS_SUCCESS:
            return {
                ...state,
                modelVariants: {
                    ...state.modelVariants,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_MODEL_VARIANTS_ERROR:
            return {
                ...state,
                modelVariants: {
                    ...state.modelVariants,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_ENGINE_SIZES:
            return {
                ...state,
                engineSize: {
                    ...state.engineSize,
                    loading: true,
                },
            }
        case FETCH_ENGINE_SIZES_SUCCESS:
            return {
                ...state,
                engineSize: {
                    ...state.engineSize,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_ENGINE_SIZES_ERROR:
            return {
                ...state,
                engineSize: {
                    ...state.engineSize,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_TRANSMISSIONS:
            return {
                ...state,
                transmission: {
                    ...state.transmission,
                    loading: true,
                },
            }
        case FETCH_TRANSMISSIONS_SUCCESS:
            return {
                ...state,
                transmission: {
                    ...state.transmission,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_TRANSMISSIONS_ERROR:
            return {
                ...state,
                transmission: {
                    ...state.transmission,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_DOORS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    loading: true,
                },
            }
        case FETCH_DOORS_SUCCESS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_DOORS_ERROR:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_AGES:
            return {
                ...state,
                age: {
                    ...state.age,
                    loading: true,
                },
            }
        case FETCH_AGES_SUCCESS:
            return {
                ...state,
                age: {
                    ...state.age,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_AGES_ERROR:
            return {
                ...state,
                age: {
                    ...state.age,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_MILEAGES:
            return {
                ...state,
                mileage: {
                    ...state.mileage,
                    loading: true,
                },
            }
        case FETCH_MILEAGES_SUCCESS:
            return {
                ...state,
                mileage: {
                    ...state.mileage,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_MILEAGES_ERROR:
            return {
                ...state,
                mileage: {
                    ...state.mileage,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_BODY_TYPES:
            return {
                ...state,
                bodyTypes: {
                    ...state.bodyTypes,
                    loading: true,
                },
            }
        case FETCH_BODY_TYPES_SUCCESS:
            return {
                ...state,
                bodyTypes: {
                    ...state.bodyTypes,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_BODY_TYPES_ERROR:
            return {
                ...state,
                bodyTypes: {
                    ...state.bodyTypes,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_VEHICLE_TYPES:
            return {
                ...state,
                vehicleTypes: {
                    ...state.vehicleTypes,
                    loading: true,
                },
            }
        case FETCH_VEHICLE_TYPES_SUCCESS:
            return {
                ...state,
                vehicleTypes: {
                    ...state.vehicleTypes,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_VEHICLE_TYPES_ERROR:
            return {
                ...state,
                vehicleTypes: {
                    ...state.vehicleTypes,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_FUEL_TYPES:
            return {
                ...state,
                fuelTypes: {
                    ...state.fuelTypes,
                    loading: true,
                },
            }
        case FETCH_FUEL_TYPES_SUCCESS:
            return {
                ...state,
                fuelTypes: {
                    ...state.fuelTypes,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_FUEL_TYPES_ERROR:
            return {
                ...state,
                fuelTypes: {
                    ...state.fuelTypes,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_SELLERS:
            return {
                ...state,
                sellers: {
                    ...state.sellers,
                    loading: true,
                },
            }
        case FETCH_SELLERS_SUCCESS:
            return {
                ...state,
                sellers: {
                    ...state.sellers,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_SELLERS_ERROR:
            return {
                ...state,
                sellers: {
                    ...state.sellers,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_NAMA:
            return {
                ...state,
                nama: {
                    ...state.nama,
                    loading: true,
                },
            }
        case FETCH_NAMA_SUCCESS:
            return {
                ...state,
                nama: {
                    ...state.nama,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_NAMA_ERROR:
            return {
                ...state,
                nama: {
                    ...state.nama,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_WRITE_OFF:
            return {
                ...state,
                writeOff: {
                    ...state.writeOff,
                    loading: true,
                },
            }
        case FETCH_WRITE_OFF_SUCCESS:
            return {
                ...state,
                writeOff: {
                    ...state.writeOff,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_WRITE_OFF_ERROR:
            return {
                ...state,
                writeOff: {
                    ...state.writeOff,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_NON_RUNNER:
            return {
                ...state,
                nonRunner: {
                    ...state.nonRunner,
                    loading: true,
                },
            }
        case FETCH_NON_RUNNER_SUCCESS:
            return {
                ...state,
                nonRunner: {
                    ...state.nonRunner,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_NON_RUNNER_ERROR:
            return {
                ...state,
                nonRunner: {
                    ...state.nonRunner,
                    loading: false,
                    error: action.payload.error,
                },
            }
        case FETCH_EURO_STATUS:
            return {
                ...state,
                euroStatus: {
                    ...state.euroStatus,
                    loading: true,
                },
            }
        case FETCH_EURO_STATUS_SUCCESS:
            return {
                ...state,
                euroStatus: {
                    ...state.euroStatus,
                    loading: false,
                    data: action.payload,
                    error: null,
                },
            }
        case FETCH_EURO_STATUS_ERROR:
            return {
                ...state,
                euroStatus: {
                    ...state.euroStatus,
                    loading: false,
                    error: action.payload.error,
                },
            }
        default:
            return state
    }
}

export default CriteriaDataReducer
